import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter, SelectColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import axios from "axios";
import fileDownload from "js-file-download";

const initialTaskRelatedUploadsState = null;

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const TaskRelatedUploads = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const [taskRelatedUploads, setTaskRelatedUploads] = useState(initialTaskRelatedUploadsState);
  const [empty, setEmpty] = useState(initialEmptyState);
  const id = props.id;
  const name = props.name;
  const collection = props.collection;
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const fetchRoute = "/files/showAllTaskRelatedMedia";
  const idTable = "task-related-uploads-table";
  const downloadRoute = "files/downloadMedia";
  const massDownloadRoute = "files/downloadAllTaskMedia";

  useEffect(() => {
    const getTaskRelatedUploads = async () => {
      const fetchConfig = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${fetchRoute}/${id}/${collection}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.

            setTaskRelatedUploads(data.data);

            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getTaskRelatedUploads();
    }
  }, [appUrl, token, id, collection]);

  // Convertir unidades de almacenamiento con JS | https://stackoverflow.com/a/18650828
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // Dar formato a fechas | https://stackoverflow.com/a/43365158
  const dateToYMD = (date) => {
    return new Date(date).toISOString().slice(0, 10);
  };

  //https://www.digitalocean.com/community/tutorials/react-usememo
  const handleDownload = useCallback(
    (e, id, filename) => {
      e.preventDefault();
      // https://stackoverflow.com/a/64002765
      axios
        .get(`${appUrl}/${downloadRoute}/${id}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          fileDownload(res.data, filename);
        });
    },
    [appUrl, token]
  );

  const handleMassDownload = useCallback(
    (e, id, filename) => {
      e.preventDefault();
      // https://stackoverflow.com/a/64002765
      axios
        .get(`${appUrl}/${massDownloadRoute}/${id}/${collection}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          fileDownload(res.data, `${filename}-${collection}-files.zip`);
        });
    },
    [appUrl, token, collection]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        // sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskRelatedMedia") ? (
            row.original.id ? (
              <span title="See upload details">
                <Link
                  to={{
                    pathname: `/showTaskRelatedMedia/`,
                    state: {
                      mediaId: `${row.original.id}`,
                      taskId: `${row.original.model_id}`,
                      userId: `${row.original.user_id}`,
                      collection: `${collection}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showTaskRelatedMedia">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskRelatedMedia") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/showTaskRelatedMedia/`,
                  state: {
                    mediaId: `${row.original.id}`,
                    taskId: `${row.original.model_id}`,
                    userId: `${row.original.user_id}`,
                    collection: `${collection}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200"
              >
                <span title="See upload details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      {
        Header: "MimeType: ",
        accessor: "mime_type",
        Filter: SelectColumnFilter,
        Cell: ({ row }) => (row.original.id ? row.original.mime_type : ""),
      },
      {
        Header: "Size: ",
        accessor: "size",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) => (row.original.id ? formatBytes(row.original.size) : ""),
      },
      {
        Header: "Uploaded by: ",
        accessor: "custom_properties",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) => (row.original.id ? row.original.custom_properties.user_name : ""),
      },
      {
        Header: "Uploaded at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) => (row.original.id ? dateToYMD(row.original.created_at) : ""),
      },
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showTaskRelatedMedia") ? (
                <Link
                  to={{
                    pathname: `/showTaskRelatedMedia/`,
                    state: {
                      mediaId: `${row.original.id}`,
                      taskId: `${row.original.model_id}`,
                      userId: `${row.original.user_id}`,
                      collection: `${collection}`,
                    },
                  }}
                >
                  <span title="See media details">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l shadow hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showTaskRelatedMedia">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-l shadow hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("downloadTaskMedia") ? (
                // Frontend: https://stackoverflow.com/a/58203976
                <Link
                  to={{}}
                  onClick={(e) => {
                    handleDownload(e, row.original.id, row.original.file_name);
                  }}
                >
                  <span title="Download this media">
                    <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r shadow hover:bg-blue-700">
                      <i className="fa fa-download"></i>
                      {/* <span className="ml-1">Edit</span> */}
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - downloadTaskMedia">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                    <i className="fa fa-download"></i>
                  </button>
                </span>
              )}
            </div>
          </>
        ),
      },
    ],
    [handleDownload, collection]
  );

  return (
    <>
      <section id="TaskRelatedUploads" className="inline-flex justify-end">
        <div className="inline-flex mt-0">
          {CheckPermissionsInsideComponents("downloadTaskMedia") ? (
            <>
              {/* <Link
                to={{
                  pathname: `/AddMediaToProject/`,
                  state: {
                    taskId: `${id}`,
                  },
                }}
              >
                <span title="Upload file">
                  <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-blue-600 rounded-full shadow sm:text-base hover:bg-blue-500">
                    <i className="fa fa-file-upload"></i>
                    <span className="ml-1">New</span>
                  </button>
                </span>
              </Link> */}
              <Link
                onClick={(e) => {
                  handleMassDownload(e, id, name, collection);
                }}
                className="ml-2"
              >
                <span title={`Download all files from upload group ${collection}`}>
                  <button className="mt-3 sm:mt-0 inline-flex items-center px-2 py-1 text-sm text-white bg-green-500 rounded-md shadow sm:text-sm hover:bg-green-600">
                    <i className="fa fa-file-download"></i>
                    <span className="ml-1">Download all from: {collection}</span>
                  </button>
                </span>
              </Link>
            </>
          ) : (
            <span title="PERMISSION REQUIRED - downloadTaskMedia">
              <button className="inline-flex items-center px-2 py-1 text-sm text-white bg-gray-400 rounded-md shadow sm:text-sm hover:bg-gray-500">
                <i className="fa fa-file-upload"></i>
                <span className="ml-1">Download all from {collection}</span>
              </button>
            </span>
          )}
        </div>
      </section>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-400" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={taskRelatedUploads} />}
      </div>
    </>
  );
};

export default TaskRelatedUploads;
