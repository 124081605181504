//https://thewidlarzgroup.com/react-table-7/ Informacion principal
//https://blog.logrocket.com/building-styling-tables-react-table-v7/ //Informacion de apoyo
//https://tailwindcss-custom-forms.netlify.app/
import React from "react";
import { useAsyncDebounce } from "react-table";

export const Filter = ({ column }) => {
  return <div>{column.canFilter && column.render("Filter")}</div>;
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    //preFilteredRows: { length },
    preFilteredRows,
  },
}) => {
  // const count = preFilteredRows.length;
  return (
    <div className="inline-flex">
      <div className="">
        <input
          className="w-32 mb-1 form-input"
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          // placeholder={`search (${length}) ...`}
          // placeholder={`search ${count} items...`}
          placeholder={` Type to search:`}
        />
      </div>
    </div>
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div className="inline-flex">
      <select
        className="mb-1 form-select"
        id="custom-select"
        type="select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Select:</option>
        {options.map((option) => (
          <option className="text-gray-600" key={option + 1} value={option}>
            {/* Recortar largo de las cadenas de texto a mostrar en el select */}
            {!!option && option.length > 20 ? `${option.substr(0, 30)}...` : option}
          </option>
        ))}
      </select>
    </div>
  );
};

// Define a default UI for filtering https://www.freakyjolly.com/react-table-tutorial/
export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 400);

  return (
    <span className="">
      {/* Search:{" "} */}
      <input
        className="w-full mt-2 form-input rounded-none"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Global search: ${count} records...`}
      />
    </span>
  );
};
