import React from "react";

const DeleteUserButtonDisabled = ({ rounded }) => {
  return (
    <span title="PERMISSION REQUIRED - deleteUser">
      {rounded ? (
        <button className="inline-flex items-center px-5 py-3 font-bold text-white bg-gray-400 rounded-r shadow hover:bg-gray-500 sm:px-4 sm:py-2 sm:text-base">
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        <button className="inline-flex items-center px-5 py-3 font-bold text-white bg-gray-400 shadow hover:bg-gray-500 sm:px-4 sm:py-2 sm:text-base">
          <i className="fa fa-trash"></i>
        </button>
      )}
    </span>
  );
};

export default DeleteUserButtonDisabled;
