import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function Footer() {
  const { versionBranch, versionCommit, versionDate } = useContext(AppContext);

  return (
    // Habilitar pie de pagina pegajoso (o en este caso, hacer que el div contenedor de la pagina crezca)
    // https://stackoverflow.com/a/65599498
    <footer className="px-5 text-white bg-blue-500 border-t-4 border-white shadow-md">
      <div className="flex mr-6" title="Current git commit">
        <span className="inline-flex justify-start w-1/3 font-hairline">
          {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
          <span className="block font-hairline sm:hidden"></span>
          {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
          <span className="hidden font-hairline sm:block">branch:</span>
          <span className="ml-1 font-extrabold">{versionBranch}</span>
        </span>
        <span className="inline-flex justify-center w-1/3 ml-1">
          {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
          <span className="block font-hairline sm:hidden"></span>
          {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
          <span className="hidden font-hairline sm:block">commit hash:</span>
          <span className="ml-1 font-extrabold">
            {versionCommit.slice(0, 7)}
          </span>
        </span>
        <span className="inline-flex justify-end w-1/3 ml-1 font-hairline">
          {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
          <span className="block font-hairline sm:hidden"></span>
          {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
          <span className="hidden font-hairline sm:block">commit date:</span>
          <span className="ml-1 font-extrabold">
            {versionDate.slice(0, 10)}
          </span>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
