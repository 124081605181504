import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialRedirectBackState = false; //Redireccionar automaticamente luego de un tiempo a otra pagina - https://stackoverflow.com/a/57763836

const DeleteTaskRelatedMedia = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.taskId;
  const mediaId = props.location.state.mediaId;
  const collection = props.location.state.collection;
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const [redirectBack, setRedirectBack] = useState(initialRedirectBackState);
  const thisRoute = "/files/deleteTaskRelatedMedia";
  const history = useHistory();

  useEffect(() => {
    const deleteTaskMedia = async () => {
      const fetchConfig = {
        method: "DELETE",
        body: JSON.stringify({ media: mediaId, collection: collection }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${thisRoute}/${id}`, fetchConfig);
        const data = await response.json();

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setSuccess({
            successStatus: true,
            successMsg: data.data.message,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        setTimeout(() => setRedirectBack(true), 1500);

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: 0,
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      deleteTaskMedia();
    }
  }, [appUrl, token, id, mediaId]);

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Delete task media:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.go(-2)}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con exito la peticion*/}
      {success.successStatus && <Notification icon="fa fa-check-circle" background="bg-green-600" message={success.successMsg} />}

      {redirectBack && history.goBack()}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}
    </>
  );
};

export default DeleteTaskRelatedMedia;
