import React, { useState, useEffect, useContext } from "react";
//https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router/57619711
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";

import TaskResponsibles from "./TaskResponsibles";
import CreateCommentForm from "../../components/comments/CreateCommentForm";
import DeleteTaskButtonEnabled from "./microcomponents/DeleteTaskButtonEnabled";
import DeleteTaskButtonDisabled from "./microcomponents/DeleteTaskButtonDisabled";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import TaskRelatedSubTasks from "./TaskRelatedSubTasks";
import TaskRelatedUploads from "./TaskRelatedUploads";
import { Tabs, Tab, Panel } from "@bumaga/tabs";

const initialTaskState = {
  id: "",
  name: "",
  description: "",
  status_id: "",
  priority: "",
  created_at: "",
  estimated_finish: "",
  finished_at: "",
  user_id: "",
  project_id: "",
  project: {
    id: "",
    name: "",
  },
  activity: {
    id: "",
    name: "",
  },
  owner: {
    id: "",
    name: "",
  },
  status: {
    id: "",
    name: "",
  },
  task_collections: [],
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialTaskRelatedTags = [];

const TaskDetails = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.taskId;
  const [task, setTask] = useState(initialTaskState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const tasksRoute = "/tasks";
  const history = useHistory();

  const routeToFetchCurrentTaskTags = "/taskTags";
  const [taskRelatedTags, setTaskRelatedTags] = useState(initialTaskRelatedTags);

  useEffect(() => {
    const getTask = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${tasksRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setTask(data.data);
          setSuccess({
            successStatus: true,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTaskRelatedTags = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchCurrentTaskTags}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTaskRelatedTags(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getTask();
      getTaskRelatedTags();
    }
  }, [appUrl, token, id]);

  return (
    <>
      <div className="flex flex-col md:inline-flex md:justify-between">
        <div className="flex justify-start md:inline-flex md:items-center">
          <div className="flex items-center justify-center mb-2 mr-1 text-2xl text-gray-800 sm:mr-2">
            <i className="fas fa-sitemap"></i>
            <h2 className="ml-2 text-xl font-semibold">Task details:</h2>
          </div>
        </div>

        <div className="flex justify-end md:inline-flex">
          <div className="inline-flex">
            {/* https://stackoverflow.com/questions/61573700/react-router-and-this-props-this-props-location-state-is-undefined */}
            {CheckPermissionsInsideComponents("changeTaskRelatedUsers") ? (
              <Link
                to={{
                  pathname: `/addUserToTask/`,
                  state: {
                    taskName: `${task.name}`,
                    taskId: `${task.id}`,
                  },
                }}
              >
                <span title="Add an user responsible to this task">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-500 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-700">
                    <i className="fa fa-user-plus"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeTaskRelatedUsers">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-user-plus"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("createTask") ? (
              <Link
                to={{
                  pathname: `/createTask/`,
                  state: {
                    activityName: `${task.activity.name}`,
                    activityId: `${task.activity.id}`,
                  },
                }}
              >
                <span title="Create another task related to same activity">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-600 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-800">
                    <i className="fa fa-tasks"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - createTask">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-tasks"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("createSubtask") ? (
              <Link
                to={{
                  pathname: `/createSubTask/`,
                  state: {
                    taskName: `${task.name}`,
                    taskId: `${task.id}`,
                  },
                }}
              >
                <span title="Create a new subtask for this task">
                  <button className="inline-flex items-center px-5 py-3 mr-1 text-sm text-white bg-blue-700 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-900">
                    <i className="fa fa-list-ul"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - createSubtask">
                <button className="inline-flex items-center px-5 py-3 mr-1 text-sm text-white bg-gray-400 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-list-ul"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("changeStatusToTask") ? (
              <Link
                to={{
                  pathname: `/changeStatusToTask/`,
                  state: {
                    taskName: `${task.name}`,
                    taskId: `${task.id}`,
                  },
                }}
              >
                <span title="Change the current status of this task">
                  <button className="items-center hidden px-4 py-2 text-sm text-white bg-blue-500 rounded-l sm:inline-flex sm:text-base hover:bg-blue-700">
                    <i className="fa fa-th-list"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeStatusToTask">
                <button className="items-center hidden px-4 py-2 text-sm text-white bg-gray-400 rounded-l shadow sm:inline-flex sm:text-base hover:bg-gray-500">
                  <i className="fa fa-th-list"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("changeTagsToTask") ? (
              <Link
                to={{
                  pathname: `/changeTagsToTask/`,
                  state: {
                    taskName: `${task.name}`,
                    taskId: `${task.id}`,
                  },
                }}
              >
                <span title="Change the current tags of this task">
                  <button className="items-center hidden px-4 py-2 mr-1 text-sm text-white bg-blue-600 rounded-r sm:inline-flex sm:text-base hover:bg-blue-800">
                    <i className="fa fa-tags"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeTagsToTask">
                <button className="items-center hidden px-4 py-2 mr-1 text-sm text-white bg-gray-400 rounded-r shadow sm:inline-flex sm:text-base hover:bg-gray-500">
                  <i className="fa fa-tags"></i>
                </button>
              </span>
            )}
          </div>

          {CheckPermissionsInsideComponents("editTask") ? (
            <Link
              to={{
                pathname: `/editTask/`,
                state: {
                  taskId: `${task.id}`,
                },
              }}
            >
              <span title="Edit this task">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-orange-500 rounded-l sm:px-4 sm:py-2 sm:text-base hover:bg-orange-400">
                  <i className="fa fa-edit"></i>
                  {/* <span className="ml-1">Edit</span> */}
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - editTask">
              <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                <i className="fa fa-edit"></i>
              </button>
            </span>
          )}

          {CheckPermissionsInsideComponents("deleteTask") ? (
            <DeleteTaskButtonEnabled taskId={task.id} rounded={false} />
          ) : (
            <DeleteTaskButtonDisabled rounded={false} />
          )}

          <div className="inline-flex">
            <span title="Go back">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-600 rounded-r sm:px-4 sm:py-2 sm:text-base hover:bg-blue-500"
              >
                <i className="fas fa-arrow-left"></i>
                {/* <span className="ml-1">Back</span> */}
              </button>
            </span>
          </div>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}

      {/*Mostar datos del proyecto*/}
      {success.successStatus && (
        <div className="flex flex-col mt-3">
          <div className="grid grid-cols-2 px-4 py-2 mb-6 text-gray-800 bg-white border border-gray-200 rounded-lg shadow sm:grid-cols-6">
            <div className="py-2 font-semibold">Id:</div>
            <div className="py-2 font-normal sm:col-span-5">{task.id}</div>

            <div className="py-2 font-semibold">Name:</div>
            <div className="py-2 font-normal sm:col-span-5">{task.name}</div>

            <div className="py-2 font-semibold">Description:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.description ? (
                task.description
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Status:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.status === null ? (
                CheckPermissionsInsideComponents("changeStatusToTask") ? (
                  <>
                    <span title="Change task status">
                      <Link
                        to={{
                          pathname: `/changeStatusToTask/`,
                          state: {
                            taskId: `${task.id}`,
                            taskName: `${task.name}`,
                          },
                        }}
                      >
                        <span title="Set a status for this task" className="inline-flex ">
                          <span className="text-xl text-gray-600 ">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="ml-1 text-gray-800">
                            <span>Set status</span>
                          </span>
                        </span>
                      </Link>
                    </span>
                  </>
                ) : (
                  <span title="PERMISSION REQUIRED - changeStatusToTask" className="inline-flex">
                    <span className="text-gray-600 mr-2">
                      <i className="fa fa-ellipsis-h"></i>
                    </span>
                  </span>
                )
              ) : CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
                <>
                  <span
                    className="inline-flex mr-2"
                    title={`${task.status.description} - Clic to see other tasks on this status`}
                  >
                    <Link
                      to={{
                        pathname: `/statusRelatedElements/`,
                        state: {
                          statusName: `${task.status.name}`,
                          statusId: `${task.status_id}`,
                        },
                      }}
                    >
                      <span className="px-2 py-1 text-white bg-indigo-400 rounded-full shadow hover:bg-indigo-300">
                        {task.status.name}
                      </span>
                    </Link>
                  </span>
                  <span title="Change task status">
                    <Link
                      to={{
                        pathname: `/changeStatusToTask/`,
                        state: {
                          taskId: `${task.id}`,
                          taskName: `${task.name}`,
                        },
                      }}
                    >
                      <span title="Change current status of this task" className="md:hidden inline-flex">
                        <span className="text-xl text-gray-600">
                          <i className="fa fa-edit"></i>
                        </span>
                      </span>
                    </Link>
                  </span>
                </>
              ) : (
                <span title="PERMISSION REQUIRED - showStatusRelatedElements">
                  <span className="inline-flex items-center px-2 py-1 text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                    {task.status.name}
                  </span>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Tags:</div>
            <div className="flex flex-col py-2 font-normal sm:flex-row sm:col-span-5">
              {taskRelatedTags.length < 1 ? (
                CheckPermissionsInsideComponents("changeTagsToTask") ? (
                  <>
                    <span title="Change task tags">
                      <Link
                        to={{
                          pathname: `/changeTagsToTask/`,
                          state: {
                            taskId: `${task.id}`,
                            taskName: `${task.name}`,
                          },
                        }}
                      >
                        <span title="Set tags for this task" className="inline-flex ">
                          <span className="text-xl text-gray-600 ">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="ml-1 text-gray-800">
                            <span>Set tags</span>
                          </span>
                        </span>
                      </Link>
                    </span>
                  </>
                ) : (
                  <span title="PERMISSION REQUIRED - changeTagsToTask" className="inline-flex">
                    <span className="text-gray-600 mr-2">
                      <i className="fa fa-ellipsis-h"></i>
                    </span>
                  </span>
                )
              ) : CheckPermissionsInsideComponents("showTagRelatedElements") ? (
                //Notese que aqui la funcion retorna un array con 2 resultados:
                //1) El resultado de projectRelatedTags.map
                //2) Enlace html para el enlace de editar tags
                [
                  taskRelatedTags.map((tag, i) => {
                    const tagColors = [
                      "bg-blue-300 hover:bg-blue-400",
                      "bg-red-300 hover:bg-red-400",
                      "bg-yellow-300 hover:bg-yellow-400",
                      "bg-orange-300 hover:bg-orange-400",
                      "bg-pink-300 hover:bg-pink-400",
                      "bg-green-300 hover:bg-green-400",
                      "bg-purple-300 hover:bg-purple-400",
                      "bg-blue-400 hover:bg-blue-500",
                      "bg-red-400 hover:bg-red-500",
                      "bg-yellow-400 hover:bg-yellow-500",
                      "bg-orange-400 hover:bg-orange-500",
                      "bg-pink-400 hover:bg-pink-500",
                      "bg-green-400 hover:bg-green-500",
                      "bg-purple-400 hover:bg-purple-500",
                      "bg-blue-400 hover:bg-blue-500",
                      "bg-red-400 hover:bg-red-500",
                      "bg-yellow-500 hover:bg-yellow-600",
                      "bg-orange-500 hover:bg-orange-600",
                      "bg-pink-500 hover:bg-pink-600",
                      "bg-green-500 hover:bg-green-600",
                      "bg-purple-500 hover:bg-purple-600",
                      "bg-blue-500 hover:bg-blue-600",
                      "bg-red-500 hover:bg-red-600",
                      "bg-yellow-600 hover:bg-yellow-700",
                      "bg-orange-600 hover:bg-orange-700",
                      "bg-pink-600 hover:bg-pink-700",
                      "bg-green-600 hover:bg-green-700",
                      "bg-purple-600 hover:bg-purple-700",
                      "bg-blue-600 hover:bg-blue-700",
                      "bg-red-600 hover:bg-red-700",
                    ];

                    const backgroundTags = Math.floor(Math.random() * tagColors.length);

                    return (
                      <div key={i} className="my-1 mr-1 sm:mr-1">
                        <Link
                          key={i}
                          to={{
                            pathname: `/tagRelatedElements/`,
                            state: {
                              tagId: `${tag.id}`,
                              tagName: `${tag.name}`,
                            },
                          }}
                          className={`${tagColors[backgroundTags]} text-white shadow px-2 py-1 rounded-full`}
                        >
                          <span
                            title={
                              tag.description
                                ? tag.description
                                : "Clic to see other projects, activities, tasks and subtasks with this tag"
                            }
                          >
                            #{tag.name}
                          </span>
                        </Link>
                      </div>
                    );
                  }),
                  <span title="Change task tags">
                    <Link
                      to={{
                        pathname: `/changeTagsToTask/`,
                        state: {
                          taskId: `${task.id}`,
                          taskName: `${task.name}`,
                        },
                      }}
                    >
                      <span title="Change current tags of this task" className="md:hidden inline-flex">
                        <span className="text-xl text-gray-600 ">
                          <i className="fa fa-edit"></i>
                        </span>
                      </span>
                    </Link>
                  </span>,
                ]
              ) : (
                taskRelatedTags.map((tag, i) => {
                  return (
                    <div key={i} className="my-1 mr-1 sm:mr-1">
                      <span title="PERMISSION REQUIRED - showTagRelatedElements">
                        <span className="inline-flex items-center px-2 py-1 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                          #{tag.name}
                        </span>
                      </span>
                    </div>
                  );
                })
              )}
            </div>

            <div className="py-2 font-semibold">Priority:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.priority ? (
                task.priority
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Created at:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.created_at ? (
                task.created_at
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Estimated finish:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.estimated_finish ? (
                task.estimated_finish
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Finished at:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {task.finished_at ? (
                task.finished_at
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Created by:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {CheckPermissionsInsideComponents("showUserDetails") ? (
                <span title="See user details">
                  <Link
                    to={{
                      pathname: `/userDetails`,
                      state: {
                        userId: `${task.owner.id}`,
                      },
                    }}
                    className="border-b-2 border-gray-400 hover:bg-blue-200"
                  >
                    {task.owner.name}
                  </Link>
                </span>
              ) : (
                <span title="PERMISSION REQUIRED - showUserDetails">
                  <span className="border-b-2 border-gray-400">{task.owner.name}</span>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Belongs to activity:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {CheckPermissionsInsideComponents("showActivityDetails") ? (
                <span title="See activity details">
                  <Link
                    to={{
                      pathname: `/activityDetails`,
                      state: {
                        activityId: `${task.activity.id}`,
                      },
                    }}
                    className="border-b-2 border-gray-400 hover:bg-blue-200"
                  >
                    {task.activity.name}
                  </Link>
                </span>
              ) : (
                <span title="PERMISSION REQUIRED - showActivityDetails">
                  <span className="border-b-2 border-gray-400">{task.activity.name}</span>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Belongs to project:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {CheckPermissionsInsideComponents("showProjectDetails") ? (
                <span title="See project details">
                  <Link
                    to={{
                      pathname: `/projectDetails`,
                      state: {
                        projectId: `${task.project.id}`,
                      },
                    }}
                    className="border-b-2 border-gray-400 hover:bg-blue-200"
                  >
                    {task.project.name}
                  </Link>
                </span>
              ) : (
                <span title="PERMISSION REQUIRED - showProjectDetails">
                  <span className="border-b-2 border-gray-400">{task.project.name}</span>
                </span>
              )}
            </div>
          </div>

          {CheckPermissionsInsideComponents("showTaskRelatedUsers") ? (
            <TaskResponsibles id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-6">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fa fa-street-view"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Task responsibles:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showTaskRelatedUsers- to see this area..."}
              />
            </>
          )}

          {CheckPermissionsInsideComponents("showTaskRelatedSubtasks") ? (
            <TaskRelatedSubTasks id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-3">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fas fa-list-ul"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Task subtasks:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showTaskRelatedSubtasks- to see this area..."}
              />
            </>
          )}

          <div className="inline-flex justify-between mt-6">
            <div className="inline-flex items-center text-gray-800">
              <div className="mr-1 text-2xl sm:mr-2">
                <i className="fa fa-file-upload"></i>
              </div>
              <h2 className="text-xl font-semibold">Task uploads:</h2>
            </div>

            <div className="inline-flex mt-2">
              {CheckPermissionsInsideComponents("addMediaToTask") ? (
                <Link
                  to={{
                    pathname: `/AddMediaToTask/`,
                    state: {
                      taskId: `${id}`,
                      taskName: `${task.name}`,
                      collections: `${task.task_collections}`,
                    },
                  }}
                >
                  <span title="Upload file">
                    <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-blue-600 rounded-lg shadow sm:text-sm hover:bg-blue-500">
                      <i className="fa fa-file-upload text-lg"></i>
                      <span className="ml-2 font-medium text-lg">New</span>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - addMediaToTask">
                  <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-gray-400 rounded-lg shadow sm:text-sm hover:bg-gray-500">
                    <i className="fa fa-file-upload text-lg"></i>
                    <span className="ml-2 font-medium text-lg">New</span>
                  </button>
                </span>
              )}
            </div>
          </div>

          {CheckPermissionsInsideComponents("showTaskRelatedMedia") ? (
            task.task_collections.length > 0 ? (
              <Tabs>
                <div className="mt-3 overflow-x-auto inline-flex items-center">
                  {/* https://stackoverflow.com/a/47616385 */}

                  {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
                  {/* <span className="block sm:hidden mr-2 font-bold">
                  Collection:
                </span> */}
                  {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
                  <span className="hidden sm:inline-block mr-2 font-semibold text-lg">
                    <p className="whitespace-no-wrap">Upload groups:</p>
                  </span>

                  {task.task_collections.map((collection, i) => {
                    const tagColors = [
                      // "bg-orange-400 hover:bg-orange-500",
                      // "bg-pink-400 hover:bg-pink-500",
                      // "bg-purple-400 hover:bg-purple-500",
                      // "bg-red-400 hover:bg-red-500",
                      // "bg-orange-600 hover:bg-orange-700",
                      // "bg-pink-600 hover:bg-pink-700",
                      // "bg-purple-600 hover:bg-purple-700",
                      // "bg-red-600 hover:bg-red-700",
                      "bg-gray-500 hover:bg-gray-600",
                    ];

                    const backgroundTags = Math.floor(Math.random() * tagColors.length);
                    return (
                      <Tab>
                        <button className={`${tagColors[backgroundTags]} rounded-lg px-3 py-2 mr-1 shadow text-white font-bold`}>
                          {collection}
                        </button>
                      </Tab>
                    );
                  })}
                </div>

                {task.task_collections.map((collection, i) => {
                  return (
                    <Panel>
                      <TaskRelatedUploads id={id} name={task.name} collection={collection} />
                    </Panel>
                  );
                })}
              </Tabs>
            ) : (
              <Notification
                icon="fa fa-info-circle"
                background="bg-orange-400"
                message={`This task doesn't have uploaded files`}
              />
            )
          ) : (
            <Notification
              icon="fa fa-info-circle"
              background="bg-red-600"
              message={"You doesn't have required permission -showTaskRelatedMedia- to see this area..."}
            />
          )}

          {CheckPermissionsInsideComponents("showTaskReports") ? (
            <CreateCommentForm model="task" id={id} />
          ) : (
            <>
              <div className="inline-flex items-center mt-3">
                <i className="fa fa-comments"></i>
                <span className="ml-2 text-xl font-semibold">Progress reports:</span>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showTaskReports- to see this area..."}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TaskDetails;
