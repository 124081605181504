import React, { useState, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialRoleState = {
  name: "",
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const CreateRole = () => {
  const { appUrl, token } = useContext(AppContext);
  const [role, setRole] = useState(initialRoleState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const thisRoute = "/security/role";
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm();

  //Guardar los datos del estado role en la api
  const onSubmit = async (role) => {
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(role),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setError(initialErrorState); // Asignar el estado role inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessing({
        processingStatus: true,
        processingMsg: "Loading data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setRole(initialRoleState); // Asignar el estado role inicial para limpiar los campos del formulario
        // Indicar en el estado que la petición se realizo con éxito y devolver el mensaje de respuesta de la api
        setSuccess({
          successStatus: true,
          successMsg: data.data.message,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessing(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setError({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessing(initialProcessingState);
    }
  };

  //Guardar los datos introducidos en el formulario en el estado role
  const handleChangeOnField = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    setRole({
      ...role, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value, //Asignar en cada propiedad del estado role, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });
  };

  //Si el usuario tiene el permiso indicado por parametro, se mostrara el contenido de este componente en caso contrario, sera redireccionado a una pagina de notificacion
  return CheckPermissionsInsideComponents("createRole") ? (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Create new role:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/* Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la petición */}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con éxito la petición*/}
      {success.successStatus && <Notification icon="fa fa-check-circle" background="bg-green-600" message={success.successMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la petición*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}
      <div className="flex justify-center mt-8">
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mt-3">
              <label htmlFor="name" className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                Name:
              </label>
              <input
                className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                id="name"
                name="name"
                type="text"
                placeholder="Type an role name"
                value={role.name}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required. ",
                  },
                })}
                onChange={(event) => handleChangeOnField(event)}
              />
              {/*Error de validación*/}
              {errors.name && <div className="text-xs text-red-500">{errors.name.message}</div>}
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  ) : (
    <Notification
      icon="fa fa-info-circle"
      background="bg-red-600"
      message={"You doesn't have required permission to create new roles..."}
    />
  );
};

export default CreateRole;
