import React, { useState, useEffect, useContext } from "react";
//https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router/57619711
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import UserRelatedProjects from "./UserRelatedProjects";
import UserRelatedActivities from "./UserRelatedActivities";
import UserRelatedTasks from "./UserRelatedTasks";
import UserRelatedSubTasks from "./UserRelatedSubTasks";
import UserRelatedRoles from "./UserRelatedRoles";
import UserRelatedPermissions from "./UserRelatedPermissions";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import DeleteUserButtonEnabled from "./microcomponents/DeleteUserButtonEnabled";
import DeleteUserButtonDisabled from "./microcomponents/DeleteUserButtonDisabled";
// import UserRelatedPermissionsViaRole from "./UserRelatedPermissionsViaRole";
// import UserRelatedPermissionsViaDirectAssignment from "./UserRelatedPermissionsViaDirectAssignment";

const initialUserState = {};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const UserDetails = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.userId;
  const { loggedUser, setLoggedUser } = useContext(AppContext);
  const [user, setUser] = useState(initialUserState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const usersRoute = "/users";
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${usersRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setUser(data.data);
          setSuccess({
            successStatus: true,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getUser();
    }
  }, [appUrl, token, id]);

  return (
    <>
      <div className="flex flex-col md:inline-flex md:justify-between">
        <div className="flex justify-start md:inline-flex md:items-center">
          <div className="flex items-center justify-center mb-2 mr-1 text-2xl text-gray-800 sm:mr-2">
            <i className="fas fa-user"></i>
            <h2 className="ml-2 text-xl font-semibold">User details:</h2>
          </div>
        </div>

        <div className="flex justify-end md:inline-flex">
          <div className="inline-flex">
            {CheckPermissionsInsideComponents("changeUserRoles") ? (
              <Link
                to={{
                  pathname: `/changeUserRoles/`,
                  state: {
                    userId: `${user.id}`,
                    userName: `${user.name}`,
                  },
                }}
              >
                <span title="Change the current roles of this user">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-purple-600 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-purple-700">
                    <i className="fa fa-user-secret"></i>
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeUserRoles">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-user-secret"></i>
                </button>
              </span>
            )}
          </div>

          <div className="inline-flex">
            {CheckPermissionsInsideComponents("changeUserPermissions") ? (
              <Link
                to={{
                  pathname: `/changeUserPermissions/`,
                  state: {
                    userId: `${user.id}`,
                    userName: `${user.name}`,
                  },
                }}
              >
                <span title="Change the current permissions of this user">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-indigo-600 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-indigo-700">
                    <i className="fa fa-lock"></i>
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeUserPermissions">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-lock"></i>
                </button>
              </span>
            )}
          </div>

          <div className="inline-flex">
            {CheckPermissionsInsideComponents("changeOtherUserDetails") || loggedUser?.id === user.id ? (
              <Link
                to={{
                  pathname: `/editUser`,
                  state: {
                    userId: `${user.id}`,
                    userName: `${user.name}`,
                    userEmail: `${user.email}`,
                  },
                }}
              >
                <span title="Edit user">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-orange-500 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-orange-400">
                    <i className="fa fa-edit"></i>
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeOtherUserDetails">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-edit"></i>
                </button>
              </span>
            )}
          </div>

          <div className="inline-flex">
            {CheckPermissionsInsideComponents("changeOtherUserPasswords") || loggedUser?.id === user.id ? (
              <Link
                to={{
                  pathname: `/editUserPassword`,
                  state: {
                    userId: `${user.id}`,
                    userName: `${user.name}`,
                    userEmail: `${user.email}`,
                  },
                }}
              >
                <span title="Edit password">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-yellow-500 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-yellow-400">
                    <i className="fa fa-key"></i>
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeOtherUserPasswords">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-key"></i>
                </button>
              </span>
            )}
          </div>

          {/* <div className="inline-flex">
            {CheckPermissionsInsideComponents("deleteUser") ? (
              <DeleteUserButtonEnabled userId={user.id} rounded={false} />
            ) : (
              <DeleteUserButtonDisabled rounded={false} />
            )}
          </div> */}

          <div className="inline-flex">
            <span title="Go back">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-600 rounded-r sm:px-4 sm:py-2 sm:text-base hover:bg-blue-500"
              >
                <i className="fas fa-arrow-left"></i>
                {/* <span className="ml-1">Back</span> */}
              </button>
            </span>
          </div>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}

      {/*Mostar datos del proyecto*/}
      {success.successStatus && (
        <div className="flex flex-col mt-3">
          <div className="grid grid-cols-2 px-4 py-2 mb-6 text-gray-800 bg-white border border-gray-200 rounded-lg shadow sm:grid-cols-6">
            <div className="py-2 font-semibold">Id:</div>
            <div className="py-2 font-normal sm:col-span-5">{user.id}</div>

            <div className="py-2 font-semibold">Name:</div>
            <div className="py-2 font-normal sm:col-span-5">{user.name}</div>

            <div className="py-2 font-semibold">Email:</div>
            <div className="py-2 font-normal sm:col-span-5">{user.email}</div>

            <div className="py-2 font-semibold">Created at:</div>
            <div className="py-2 font-normal sm:col-span-5">{user.created_at}</div>

            <div className="py-2 font-semibold">Updated at:</div>
            <div className="py-2 font-normal sm:col-span-5">{user.updated_at}</div>
          </div>

          {/* {CheckPermissionsInsideComponents("showUserRelatedProjects") && <UserRelatedProjects id={id} />} */}
          <UserRelatedProjects id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedActivities") && <UserRelatedActivities id={id} />} */}
          <UserRelatedActivities id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedTasks") && <UserRelatedTasks id={id} />} */}
          <UserRelatedTasks id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedSubTasks") && <UserRelatedSubTasks id={id} />} */}
          <UserRelatedSubTasks id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedPermissions") && <UserRelatedPermissions id={id} />} */}
          <UserRelatedPermissions id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedRoles") && <UserRelatedRoles id={id} />} */}
          <UserRelatedRoles id={id} />

          {/* {CheckPermissionsInsideComponents("showUserRelatedRoles") && <UserRelatedPermissionsViaRole id={id} />} */}

          {/* {CheckPermissionsInsideComponents("showUserRelatedPermissions") && (
            <UserRelatedPermissionsViaDirectAssignment id={id} />
          )} */}
        </div>
      )}
    </>
  );
};

export default UserDetails;
