import React from "react";

const DeleteRoleButtonDisabled = () => {
  return (
    <span title="PERMISSION REQUIRED - deleteRole">
      <button className="shadow inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded-r hover:bg-gray-500">
        <span>
          <i className="fa fa-trash"></i>
        </span>
      </button>
    </span>
  );
};

export default DeleteRoleButtonDisabled;
