import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import ReactToExcel from "react-html-table-to-excel";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
// Componente inspirado en este tutorial
// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#Handling-Authorization-in-React-Apps--the-Naive-Way
// import { CheckLoggedUserPermissions } from "../../components/CheckLoggedUserPermissions";
import CreateNewProjectButtonEnabled from "./microcomponents/CreateNewProjectButtonEnabled";
import CreateNewProjectButtonDisabled from "./microcomponents/CreateNewProjectButtonDisabled";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialProjectsState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const Projects = () => {
  const { token } = useAuth();
  const { appUrl } = useContext(AppContext);
  const [projects, setProjects] = useState(initialProjectsState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [empty, setEmpty] = useState(initialEmptyState);
  const [success, setSuccess] = useState(initialSuccessState);
  const idTable = "projects-table";
  const thisRoute = "/projects";

  useEffect(() => {
    const getProjects = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setProjects(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getProjects();
    }
  }, [appUrl, token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showProjectDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/projectDetails/`,
                  state: {
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See project details">
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showProjectDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        //https://stackoverflow.com/a/56930751
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showProjectDetails") ? (
            <Link
              to={{
                pathname: `/projectDetails/`,
                state: {
                  projectName: `${row.original.name}`,
                  projectId: `${row.original.id}`,
                },
              }}
            >
              <span title="See project details">
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Stage: ",
        accessor: "stage.name",
        //https://stackoverflow.com/a/56930751
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStageRelatedProjects") ? (
            row.original.stage ? (
              <Link
                to={{
                  pathname: `/stageRelatedProjects/`,
                  state: {
                    stageName: `${row.original.stage.name}`,
                    stageId: `${row.original.stage.id}`,
                  },
                }}
              >
                <span title="See other projects on this stage">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-blue-500 rounded-full shadow sm:text-base hover:bg-blue-400">
                    {row.original.stage.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.stage ? (
            <span title="PERMISSION REQUIRED - showStageRelatedProjects">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.stage.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Status: ",
        accessor: "status.name",
        //https://stackoverflow.com/a/56930751
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            row.original.status ? (
              <Link
                to={{
                  pathname: `/statusRelatedElements/`,
                  state: {
                    statusName: `${row.original.status.name}`,
                    statusId: `${row.original.status.id}`,
                  },
                }}
              >
                <span title="See other projects, activities and tasks with this status">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                    {row.original.status.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.status ? (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showProjectDetails") ? (
                <Link
                  to={{
                    pathname: `/projectDetails/`,
                    state: {
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See project details">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showProjectDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/createActivity/`,
                    state: {
                      projectId: `${row.original.id}`,
                      projectName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Create an activity for this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fas fa-project-diagram"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Create an activity for this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded hover:bg-gray-500">
                    <i className="fa fa-project-diagram"></i>
                  </button>
                </span>
              )} */}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/addUserToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Add an user leader to this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Add an user leader to this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )} */}
              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStageToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the stage of this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fas fa-certificate"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the stage of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500">
                    <i className="fa fa-certificate"></i>
                  </button>
                </span>
              )} */}
              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the status of this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                      <i className="fas fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the status of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-r hover:bg-gray-500">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span> */}
              {/* )} */}
              {/* <Link
                to={{
                  pathname: `/changeTagsToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this project">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r hover:bg-blue-700">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link> */}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return CheckPermissionsInsideComponents("showProjectsPage") ? (
    <>
      <div className="inline-flex justify-between mb-2">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <h2 className="text-xl font-semibold">Projects list:</h2>
        </div>

        <div className="inline-flex mt-2">
          {/* <Link to={"/CreateProject"}>
            <span title="Create new project">
              <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-blue-600 rounded-l-full sm:text-base hover:bg-blue-500">
                <i className="fa fa-plus-square"></i>&nbsp;
                <span className="ml-1">New</span>
              </button>
            </span>
          </Link> */}

          {/* Componente inspirado en este tutorial */}
          {/* https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#Handling-Authorization-in-React-Apps--the-Naive-Way */}
          {/* <CheckLoggedUserPermissions
            requiredPermission="sit"
            userHasPermission={() => <CreateNewProjectButtonEnabled />}
            userDoesntHavePermission={() => <CreateNewProjectButtonDisabled />}
          /> */}

          {/* Forma de Vioscar */}
          {CheckPermissionsInsideComponents("createProject") ? (
            <CreateNewProjectButtonEnabled />
          ) : (
            <CreateNewProjectButtonDisabled />
          )}

          {CheckPermissionsInsideComponents("exportToCSV") ? (
            <span title="Export results to Excel">
              <span className="inline-flex items-center px-3 py-1 text-sm font-bold text-white bg-green-600 rounded-r-full sm:text-base hover:bg-green-500">
                <i className="fa fa-save"></i>&nbsp;
                {/*https://www.youtube.com/watch?v=ZRjzV_1VNPE*/}
                <ReactToExcel table={idTable} filename="listOfProjects" sheet="Sheet 1" buttonText="Export" />
              </span>
            </span>
          ) : (
            <span title="PERMISSION REQUIRED - exportToCSV">
              <span className="inline-flex items-center px-3 py-1 text-sm text-white bg-gray-400 rounded-r-full sm:text-base hover:bg-gray-500">
                <i className="fa fa-save"></i>
                <span className="ml-1">Export</span>
              </span>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-500" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={projects} size={7} />}
      </div>
    </>
  ) : (
    <>
      <div className="inline-flex items-center">
        <div className="mr-1 text-3xl sm:mr-2">
          <i className="fas fa-sitemap"></i>
        </div>
        <h2 className="text-xl font-semibold">Projects list:</h2>
      </div>

      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      <Notification
        icon="fa fa-info-circle"
        background="bg-orange-500"
        message={"You doesn't have required permission -showProjectsPage- to see this area..."}
      />
    </>
  );
};

export default Projects;
