import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const CheckPermissionsInsideComponents = (requiredPermission) => {
  const { loggedUserPermissions } = useContext(AppContext);

  const authorized = loggedUserPermissions.some(
    (permission) => permission.name === requiredPermission
  );
  return authorized;
  //return true;
};

export default CheckPermissionsInsideComponents;
