//https://thewidlarzgroup.com/react-table-7/ Informacion principal
//https://blog.logrocket.com/building-styling-tables-react-table-v7/ //Informacion de apoyo
import React from "react";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from "react-table";
import { Filter, SelectColumnFilter, GlobalFilter } from "./ReactTableFilter";

const ReactTableComponent = ({ id, columns, data, size }) => {
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <div className="inline-flex mr-1">
          <i className="fa fa-sort-down"></i>
        </div>
      ) : (
        <div className="inline-flex mr-1">
          <i className="fa fa-sort-up"></i>
        </div>
      )
    ) : (
      <div className="inline-flex mr-1">
        <i className="fa fa-sort"></i>
      </div>
    );
  };

  const {
    /*Tabla base*/
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,

    /*GlobalFIlter*/
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    /*Paginacion*/
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      /*Definir filtro por defecto*/
      defaultColumn: { Filter: SelectColumnFilter },

      /*Paginacion*/
      initialState: {
        pageIndex: 0,
        pageSize: size ? size : 5,
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Render Data Table UI
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="px-1 py-1 mt-5 overflow-x-auto bg-white border border-b border-gray-300">
        <table id={id} className="w-full table-auto" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="align-top" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="font-bold leading-loose text-gray-600 capitalize whitespace-no-wrap"
                    {...column.getHeaderProps()}
                  >
                    <div className="inline-flex items-center" {...column.getSortByToggleProps()}>
                      {generateSortingIndicator(column)}
                      {column.render("Header")}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-center" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="mt-1 fila" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        <div
                          style={{
                            minHeight: "50px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          {cell.render("Cell")}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr className="inline-flex items-center " {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column, key) => (
                  <td
                    key={key + 1}
                    className="font-bold leading-loose text-center text-gray-600 capitalize whitespace-no-wrap"
                    {...column.getFooterProps}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-2 text-gray-700 flex-4">
        <div className="inline-flex items-center p-1 mx-2 mb-1 bg-white border border-gray-300 rounded-l">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <i className="fa fa-fast-backward"></i>
          </button>
        </div>
        <div className="inline-flex items-center p-1 mx-2 mb-1 bg-white border border-gray-300">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <i className="fa fa-step-backward"></i>
          </button>
        </div>
        <div className="inline-flex items-center p-1 mx-2 mb-1 bg-white border border-gray-300">
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <i className="fa fa-step-forward"></i>
          </button>
        </div>
        <div className="inline-flex items-center p-1 mx-2 mb-1 bg-white border border-gray-300 rounded-r">
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <i className="fa fa-fast-forward"></i>
          </button>
        </div>
      </div>

      <div className="inline-flex items-center justify-center mb-6 text-xs text-gray-800 sm:mb-1">
        <div className="mx-2">
          Page:{" "}
          <span className="px-1 bg-white border rounded-md">
            {pageIndex + 1} of {pageOptions.length}
          </span>
        </div>
        <div className="mx-2">
          <span>Go to page: </span>
          <input
            className="w-8 pl-1 bg-white border rounded-l appearance-none"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </div>
        <div className="mx-2">
          <span>Show: </span>
          <select
            className="pl-1 bg-white border rounded-md appearance-none"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[1, 5, 10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default ReactTableComponent;
