//RESUMEN DE ARCHIVOS A CREAR/MODIFICAR PARA EL LOGIN
// App.js
// PrivateRoute.js
// src/context/auth.jsx
// src/pages/Login.js

/* React imports */
import React, { useState } from "react";
import { AppProvider } from "./context/AppContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/reusable/PrivateRoute.js";
import { AuthContext } from "./context/auth";

/* Layout imports */
import Main from "./components/layout/Main";
import Nav from "./components/layout/Nav";
import Content from "./components/layout/Content";

/* Layout disabled imports */
/*import Menu from "./components/layout/Menu";*/
import Footer from "./components/layout/Footer";

/* Auth pages imports */
import LoginForm from "./pages/login/LoginForm";

/* Project pages imports */
import Projects from "./pages/projects/Projects";
import ProjectDetails from "./pages/projects/ProjectDetails";
import CreateProject from "./pages/projects/CreateProject";
import EditProject from "./pages/projects/EditProject";
import DeleteProject from "./pages/projects/DeleteProject";

/* Activities pages imports */
import Activities from "./pages/activities/Activities";
import ActivityDetails from "./pages/activities/ActivityDetails";
import CreateActivity from "./pages/activities/CreateActivity";
import EditActivity from "./pages/activities/EditActivity";
import DeleteActivity from "./pages/activities/DeleteActivity";

/* Task pages imports */
import TaskDetails from "./pages/tasks/TaskDetails";
import CreateTask from "./pages/tasks/CreateTask";
import EditTask from "./pages/tasks/EditTask";
import DeleteTask from "./pages/tasks/DeleteTask";

/* subTask pages imports */
import SubTaskDetails from "./pages/subtasks/SubTaskDetails";
import CreateSubTask from "./pages/subtasks/CreateSubTask";
import EditSubTask from "./pages/subtasks/EditSubTask";
import DeleteSubTask from "./pages/subtasks/DeleteSubTask";

/* Stats pages imports */
import Stats from "./pages/Stats";

/* User pages imports */
import Users from "./pages/users/Users";
import UserDetails from "./pages/users/UserDetails";
import CreateUser from "./pages/users/CreateUser";
import EditUser from "./pages/users/EditUser";
import DeleteUser from "./pages/users/DeleteUser";

/* General page imports */
import General from "./pages/General";

/* Not found page import */
import PageNotFound from "./components/reusable/PageNotFound";

/* Stages page imports */
import Stages from "./pages/stages/Stages";
import CreateStage from "./pages/stages/CreateStage";
import EditStage from "./pages/stages/EditStage";
import DeleteStage from "./pages/stages/DeleteStage";
import ChangeStageToProject from "./pages/stages/ChangeStageToProject";

/* Status page imports */
import ChangeStatusToProject from "./pages/status/ChangeStatusToProject";
import ChangeStatusToActivity from "./pages/status/ChangeStatusToActivity";
import ChangeStatusToTask from "./pages/status/ChangeStatusToTask";
import ChangeStatusToSubTask from "./pages/status/ChangeStatusToSubTask";
import Status from "./pages/status/Status";
import CreateStatus from "./pages/status/CreateStatus";
import EditStatus from "./pages/status/EditStatus";
import DeleteStatus from "./pages/status/DeleteStatus";

/* Tags page imports */
import Tag from "./pages/tags/Tags";
import CreateTag from "./pages/tags/CreateTag";
import EditTag from "./pages/tags/EditTag";
import DeleteTag from "./pages/tags/DeleteTag";
import ChangeTagsToProject from "./pages/tags/ChangeTagsToProject";
import AddUserToProject from "./pages/users/AddUserToProject";
import RemoveUsersFromProject from "./pages/users/RemoveUsersFromProject";

import RemoveProjectStage from "./pages/stages/RemoveProjectStage";
import StageRelatedProjects from "./pages/stages/StageRelatedProjects";

import RemoveProjectStatus from "./pages/status/RemoveProjectStatus";
import RemoveActivityStatus from "./pages/status/RemoveActivityStatus";
import RemoveTaskStatus from "./pages/status/RemoveTaskStatus";

import StatusRelatedElements from "./pages/status/StatusRelatedElements";

import RemoveTagsFromProject from "./pages/tags/RemoveTagsFromProject";
import RemoveTagsFromActivity from "./pages/tags/RemoveTagsFromActivity";
import RemoveTagsFromTask from "./pages/tags/RemoveTagsFromTask";
import RemoveTagsFromSubTask from "./pages/tags/RemoveTagsFromSubTask";
import TagRelatedElements from "./pages/tags/TagRelatedElements";
import AddUserToActivity from "./pages/users/AddUserToActivity";
import RemoveUsersFromActivity from "./pages/users/RemoveUsersFromActivity";
import RemoveUsersFromTask from "./pages/users/RemoveUsersFromTask";
import RemoveUsersFromSubTask from "./pages/users/RemoveUsersFromSubTask";
import ChangeTagsToActivity from "./pages/tags/ChangeTagsToActivity";
import ChangeTagsToTask from "./pages/tags/ChangeTagsToTask";
import ChangeTagsToSubTask from "./pages/tags/ChangeTagsToSubTask";
import AddUserToTask from "./pages/users/AddUserToTask";
import AddUserToSubTask from "./pages/users/AddUserToSubTask";
import EditComment from "./components/comments/EditComment";
import DeleteComment from "./components/comments/DeleteComment";
import EditReply from "./components/replies/EditReply";
import DeleteReply from "./components/replies/DeleteReply";
import Unauthorized from "./components/reusable/Unauthorized";
import CreateRole from "./pages/roles/CreateRole";
import EditRole from "./pages/roles/EditRole";
import DeleteRole from "./pages/roles/DeleteRole";
import CreatePermission from "./pages/permissions/CreatePermission";
import ChangeUserRoles from "./pages/users/ChangeUserRoles";
import ChangeUserPermissions from "./pages/users/ChangeUserPermissions";
import ChangeRolePermissions from "./pages/roles/ChangeRolePermissions";
import RoleRelatedUsers from "./pages/roles/RoleRelatedUsers";
import RoleDetails from "./pages/roles/RoleDetails";
import PermissionDetails from "./pages/permissions/PermissionDetails";
import RemoveRoleFromUser from "./pages/users/RemoveRoleFromUser";
import RemovePermissionFromUser from "./pages/users/RemovePermissionFromUser";
import RemovePermissionFromRole from "./pages/roles/RemovePermissionFromRole";
import ShowProjectRelatedMedia from "./pages/media/ShowProjectRelatedMedia";
import DeleteProjectRelatedMedia from "./pages/media/DeleteProjectRelatedMedia";
import AddMediaToProject from "./pages/media/AddMediaToProject";
import CreateOnFlyEntireProject from "./pages/projects/CreateOnFlyEntireProject";
import PruebaComponent from "./pages/projects/PruebaComponent";
import EditUserPassword from "./pages/users/EditUserPassword";
import AddMediaToActivity from "./pages/media/AddMediaToActivity";
import ShowActivityRelatedMedia from "./pages/media/ShowActivityRelatedMedia";
import DeleteActivityRelatedMedia from "./pages/media/DeleteActivityRelatedMedia";
import AddMediaToTask from "./pages/media/AddMediaToTask";
import ShowTaskRelatedMedia from "./pages/media/ShowTaskRelatedMedia";
import DeleteTaskRelatedMedia from "./pages/media/DeleteTaskRelatedMedia";
import AddMediaToSubtask from "./pages/media/AddMediaToSubtask";
import ShowSubtaskRelatedMedia from "./pages/media/ShowSubtaskRelatedMedia";
import DeleteSubtaskRelatedMedia from "./pages/media/DeleteSubtaskRelatedMedia";

function App(props) {
  const existingTokens = JSON.parse(localStorage.getItem("rvgprjmgmnttoken"));
  const [token, setToken] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("rvgprjmgmnttoken", JSON.stringify(data));
    setToken(data);
  };

  function logOut() {
    localStorage.removeItem("rvgprjmgmnttoken");
    setToken(null);
  }

  return (
    <>
      {/* // PASO #4 - Agregar proveedor de contexto y decorador para rutas con login*/}
      <AuthContext.Provider value={{ logOut, token, setToken: setTokens }}>
        <AppProvider>
          <BrowserRouter>
            <Main>
              <Nav />
              {/*<Menu/>*/}
              <Content>
                <Switch>
                  <PrivateRoute path="/prueba" component={PruebaComponent} />

                  <PrivateRoute path="/createOnFlyEntireProject" component={CreateOnFlyEntireProject} />
                  <PrivateRoute path="/permissionDetails" component={PermissionDetails} />

                  <PrivateRoute path="/roleRelatedUsers" component={RoleRelatedUsers} />

                  <PrivateRoute path="/roleDetails" component={RoleDetails} />

                  <PrivateRoute path="/removeRoleFromUser" component={RemoveRoleFromUser} />

                  <PrivateRoute path="/changeUserRoles" component={ChangeUserRoles} />

                  <PrivateRoute path="/removePermissionFromUser" component={RemovePermissionFromUser} />

                  <PrivateRoute path="/changeUserPermissions" component={ChangeUserPermissions} />

                  <PrivateRoute path="/removePermissionFromRole" component={RemovePermissionFromRole} />

                  <PrivateRoute path="/changeRolePermissions" component={ChangeRolePermissions} />

                  <PrivateRoute path="/createPermission" component={CreatePermission} />
                  <PrivateRoute path="/createRole" component={CreateRole} />
                  <PrivateRoute path="/editRole" component={EditRole} />
                  <PrivateRoute path="/deleteRole" component={DeleteRole} />

                  <PrivateRoute path="/editReply" component={EditReply} />
                  <PrivateRoute path="/deleteReply" component={DeleteReply} />

                  <PrivateRoute path="/editComment" component={EditComment} />
                  <PrivateRoute path="/deleteComment" component={DeleteComment} />

                  <PrivateRoute path="/deleteTag" component={DeleteTag} />
                  <PrivateRoute path="/editTag" component={EditTag} />
                  <PrivateRoute path="/createTag" component={CreateTag} />
                  <PrivateRoute path="/tags" component={Tag} />

                  <PrivateRoute path="/deleteStatus" component={DeleteStatus} />
                  <PrivateRoute path="/editStatus" component={EditStatus} />
                  <PrivateRoute path="/createStatus" component={CreateStatus} />
                  <PrivateRoute path="/status" component={Status} />

                  <PrivateRoute path="/changeStageToProject" component={ChangeStageToProject} />
                  <PrivateRoute path="/changeStatusToProject" component={ChangeStatusToProject} />
                  <PrivateRoute path="/changeStatusToActivity" component={ChangeStatusToActivity} />
                  <PrivateRoute path="/changeStatusToTask" component={ChangeStatusToTask} />
                  <PrivateRoute path="/changeStatusToSubTask" component={ChangeStatusToSubTask} />

                  <PrivateRoute path="/deleteStage" component={DeleteStage} />
                  <PrivateRoute path="/editStage" component={EditStage} />
                  <PrivateRoute path="/createStage" component={CreateStage} />
                  <PrivateRoute path="/stages" component={Stages} />

                  <PrivateRoute path="/subtaskDetails" component={SubTaskDetails} />
                  <PrivateRoute path="/createSubTask" component={CreateSubTask} />
                  <PrivateRoute path="/editSubTask" component={EditSubTask} />
                  <PrivateRoute path="/deleteSubTask" component={DeleteSubTask} />

                  <PrivateRoute path="/taskDetails" component={TaskDetails} />
                  <PrivateRoute path="/createTask" component={CreateTask} />
                  <PrivateRoute path="/editTask" component={EditTask} />
                  <PrivateRoute path="/deleteTask" component={DeleteTask} />

                  <PrivateRoute path="/deleteActivity" component={DeleteActivity} />
                  <PrivateRoute path="/editActivity" component={EditActivity} />
                  <PrivateRoute path="/createActivity" component={CreateActivity} />
                  <PrivateRoute path="/activityDetails" component={ActivityDetails} />
                  <PrivateRoute path="/activities" component={Activities} />

                  <PrivateRoute path="/general" component={General} />

                  <PrivateRoute path="/stats" component={Stats} />

                  <PrivateRoute path="/AddMediaToProject" component={AddMediaToProject} />
                  <PrivateRoute path="/AddMediaToActivity" component={AddMediaToActivity} />
                  <PrivateRoute path="/AddMediaToTask" component={AddMediaToTask} />
                  <PrivateRoute path="/AddMediaToSubtask" component={AddMediaToSubtask} />

                  <PrivateRoute path="/deleteProjectRelatedMedia" component={DeleteProjectRelatedMedia} />
                  <PrivateRoute path="/deleteActivityRelatedMedia" component={DeleteActivityRelatedMedia} />
                  <PrivateRoute path="/deleteTaskRelatedMedia" component={DeleteTaskRelatedMedia} />
                  <PrivateRoute path="/deleteSubtaskRelatedMedia" component={DeleteSubtaskRelatedMedia} />

                  <PrivateRoute path="/showProjectRelatedMedia" component={ShowProjectRelatedMedia} />
                  <PrivateRoute path="/showActivityRelatedMedia" component={ShowActivityRelatedMedia} />
                  <PrivateRoute path="/showTaskRelatedMedia" component={ShowTaskRelatedMedia} />
                  <PrivateRoute path="/showSubtaskRelatedMedia" component={ShowSubtaskRelatedMedia} />

                  <PrivateRoute path="/deleteProject" component={DeleteProject} />
                  <PrivateRoute path="/editProject" component={EditProject} />
                  <PrivateRoute path="/createProject" component={CreateProject} />
                  <PrivateRoute path="/projectDetails" component={ProjectDetails} />
                  <PrivateRoute path="/projects" component={Projects} />

                  <PrivateRoute path="/deleteUser" component={DeleteUser} />
                  <PrivateRoute path="/editUser" component={EditUser} />
                  <PrivateRoute path="/editUserPassword" component={EditUserPassword} />
                  <PrivateRoute path="/createUser" component={CreateUser} />
                  <PrivateRoute path="/userDetails" component={UserDetails} />
                  <PrivateRoute path="/users" component={Users} />

                  <Route path="/login" component={LoginForm} />

                  <PrivateRoute path="/addUserToProject" component={AddUserToProject} />
                  <PrivateRoute path="/addUserToActivity" component={AddUserToActivity} />
                  <PrivateRoute path="/addUserToTask" component={AddUserToTask} />
                  <PrivateRoute path="/addUserToSubTask" component={AddUserToSubTask} />

                  <PrivateRoute path="/removeUsersFromProject" component={RemoveUsersFromProject} />
                  <PrivateRoute path="/removeUsersFromActivity" component={RemoveUsersFromActivity} />
                  <PrivateRoute path="/removeUsersFromTask" component={RemoveUsersFromTask} />
                  <PrivateRoute path="/removeUsersFromSubTask" component={RemoveUsersFromSubTask} />

                  <PrivateRoute path="/removeProjectStage" component={RemoveProjectStage} />
                  <PrivateRoute path="/stageRelatedProjects" component={StageRelatedProjects} />
                  <PrivateRoute path="/removeProjectStatus" component={RemoveProjectStatus} />
                  <PrivateRoute path="/removeActivityStatus" component={RemoveActivityStatus} />
                  <PrivateRoute path="/removeTaskStatus" component={RemoveTaskStatus} />

                  <PrivateRoute path="/statusRelatedElements" component={StatusRelatedElements} />

                  <PrivateRoute path="/changeTagsToProject" component={ChangeTagsToProject} />
                  <PrivateRoute path="/changeTagsToActivity" component={ChangeTagsToActivity} />
                  <PrivateRoute path="/changeTagsToTask" component={ChangeTagsToTask} />
                  <PrivateRoute path="/changeTagsToSubTask" component={ChangeTagsToSubTask} />

                  <PrivateRoute path="/removeTagsFromProject" component={RemoveTagsFromProject} />
                  <PrivateRoute path="/removeTagsFromActivity" component={RemoveTagsFromActivity} />
                  <PrivateRoute path="/removeTagsFromTask" component={RemoveTagsFromTask} />
                  <PrivateRoute path="/removeTagsFromSubTask" component={RemoveTagsFromSubTask} />

                  <PrivateRoute path="/tagRelatedElements" component={TagRelatedElements} />
                  <PrivateRoute exact path="/unauthorized" component={Unauthorized} />
                  <PrivateRoute path="/" exact component={Projects} />
                  <PrivateRoute component={PageNotFound} />
                </Switch>
              </Content>
              <Footer />
            </Main>
          </BrowserRouter>
        </AppProvider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
