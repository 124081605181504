import React, { useState, useEffect, useContext } from "react";
//https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router/57619711
import { Link, useHistory } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ProjectRelatedActivities from "./ProjectRelatedActivities";
import ProjectRelatedTasks from "./ProjectRelatedTasks";
import ProjectRelatedSubTasks from "./ProjectRelatedSubTasks";
import ProjectLeaders from "./ProjectLeaders";
import ProjectRelatedUsers from "./ProjectRelatedUsers";
import CreateCommentForm from "../../components/comments/CreateCommentForm";
// import ProjectRelatedComments from "./ProjectRelatedComments";
import DeleteProjectButtonEnabled from "./microcomponents/DeleteProjectButtonEnabled";
import DeleteProjectButtonDisabled from "./microcomponents/DeleteProjectButtonDisabled";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import ProjectRelatedUploads from "./ProjectRelatedUploads";
import { Tabs, Tab, Panel } from "@bumaga/tabs";

const initialProjectState = {
  id: "",
  user_id: "",
  stage_id: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
  deleted_at: "",
  created_at: "",
  updated_at: "",
  owner: {
    id: "",
    name: "",
  },
  stage: {
    id: "",
    name: "",
  },
  status: {
    id: "",
    name: "",
  },
  project_collections: [],
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialProjectRelatedTags = [];

const ProjectDetails = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.projectId;
  const [project, setProject] = useState(initialProjectState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const projectsRoute = "/projects";
  const history = useHistory();

  const routeToFetchCurrentProjectTags = "/projectTags";
  const [projectRelatedTags, setProjectRelatedTags] = useState(initialProjectRelatedTags);

  useEffect(() => {
    const getProject = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${projectsRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setProject(data.data);
          setSuccess({
            successStatus: true,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getProjectRelatedTags = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchCurrentProjectTags}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setProjectRelatedTags(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getProject();
      getProjectRelatedTags();
    }
  }, [appUrl, token, id]);

  return (
    <>
      <div className="flex flex-col md:inline-flex md:justify-between">
        <div className="flex justify-start md:inline-flex md:items-center">
          <div className="flex items-center justify-center mb-2 mr-1 text-2xl text-gray-800 sm:mr-2">
            <i className="fas fa-sitemap"></i>
            <h2 className="ml-2 text-xl font-semibold">Project details:</h2>
          </div>
        </div>

        <div className="flex justify-end md:inline-flex">
          <div className="inline-flex">
            {/* https://stackoverflow.com/questions/61573700/react-router-and-this-props-this-props-location-state-is-undefined */}
            {CheckPermissionsInsideComponents("changeProjectRelatedUsers") ? (
              <Link
                to={{
                  pathname: `/addUserToProject/`,
                  state: {
                    projectName: `${project.name}`,
                    projectId: `${project.id}`,
                  },
                }}
              >
                <span title="Add an user leader to this project">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-500 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-700">
                    <i className="fa fa-user-plus"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeProjectRelatedUsers">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                  <i className="fa fa-user-plus"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("createActivity") ? (
              <Link
                to={{
                  pathname: `/createActivity/`,
                  state: {
                    projectId: `${project.id}`,
                    projectName: `${project.name}`,
                  },
                }}
              >
                <span title="Create a new activity for this project">
                  <button className="inline-flex items-center px-5 py-3 mr-1 text-sm text-white bg-blue-600 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-800">
                    <i className="fa fa-project-diagram"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - createActivity">
                <button className="inline-flex items-center px-5 py-3 mr-1 text-sm text-white bg-gray-400 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-400">
                  <i className="fa fa-project-diagram"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("changeStageToProject") ? (
              <Link
                to={{
                  pathname: `/changeStageToProject/`,
                  state: {
                    projectId: `${project.id}`,
                    projectName: `${project.name}`,
                  },
                }}
              >
                <span title="Change the current stage of this project">
                  <button className="items-center hidden px-4 py-2 text-sm text-white bg-blue-400 rounded-l shadow sm:inline-flex sm:text-base hover:bg-blue-600">
                    <i className="fa fa-certificate"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeStageToProject">
                <button className="items-center hidden px-4 py-2 text-sm text-white bg-gray-400 rounded-l shadow sm:inline-flex sm:text-base hover:bg-gray-500">
                  <i className="fa fa-certificate"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("changeStatusToProject") ? (
              <Link
                to={{
                  pathname: `/changeStatusToProject/`,
                  state: {
                    projectId: `${project.id}`,
                    projectName: `${project.name}`,
                  },
                }}
              >
                <span title="Change the current status of this project">
                  <button className="items-center hidden px-4 py-2 text-sm text-white bg-blue-500 shadow sm:inline-flex sm:text-base hover:bg-blue-700">
                    <i className="fa fa-th-list"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeStatusToProject">
                <button className="items-center hidden px-4 py-2 text-sm text-white bg-gray-400 shadow sm:inline-flex sm:text-base hover:bg-gray-500">
                  <i className="fa fa-th-list"></i>
                </button>
              </span>
            )}

            {CheckPermissionsInsideComponents("changeTagsToProject") ? (
              <Link
                to={{
                  pathname: `/changeTagsToProject/`,
                  state: {
                    projectId: `${project.id}`,
                    projectName: `${project.name}`,
                  },
                }}
              >
                <span title="Change the current tags of this project">
                  <button className="items-center hidden px-4 py-2 mr-1 text-sm text-white bg-blue-600 rounded-r shadow sm:inline-flex sm:text-base hover:bg-blue-800">
                    <i className="fa fa-tags"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              </Link>
            ) : (
              <span title="PERMISSION REQUIRED - changeTagsToProject">
                <button className="items-center hidden px-4 py-2 mr-1 text-sm text-white bg-gray-400 rounded-r shadow sm:inline-flex sm:text-base hover:bg-gray-500">
                  <i className="fa fa-tags"></i>
                </button>
              </span>
            )}
          </div>

          {CheckPermissionsInsideComponents("editProject") ? (
            <Link
              to={{
                pathname: `/editProject/`,
                state: {
                  projectId: `${project.id}`,
                },
              }}
            >
              <span title="Edit this project">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-orange-500 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-orange-400">
                  <i className="fa fa-edit"></i>
                  {/* <span className="ml-1">Edit</span> */}
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - editProject">
              <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                <i className="fa fa-edit"></i>
              </button>
            </span>
          )}

          <div className="inline-flex">
            {CheckPermissionsInsideComponents("deleteProject") ? (
              <DeleteProjectButtonEnabled projectId={project.id} rounded={false} />
            ) : (
              <DeleteProjectButtonDisabled rounded={false} />
            )}
          </div>

          <div className="inline-flex">
            <span title="Go back">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-600 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-500"
              >
                <i className="fas fa-arrow-left"></i>
                {/* <span className="ml-1">Back</span> */}
              </button>
            </span>
          </div>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}

      {/*Mostar datos del proyecto*/}
      {success.successStatus && (
        <div className="flex flex-col mt-3">
          <div className="grid grid-cols-2 px-4 py-2 text-gray-800 bg-white border border-gray-200 sm:grid-cols-6">
            <div className="py-2 font-semibold">Id:</div>
            <div className="py-2 font-normal sm:col-span-5">{project.id}</div>

            <div className="py-2 font-semibold">Name:</div>
            <div className="py-2 font-normal sm:col-span-5">{project.name}</div>

            <div className="py-2 font-semibold">Description:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.description ? (
                project.description
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Stage:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.stage === null ? (
                CheckPermissionsInsideComponents("changeStageToProject") ? (
                  <>
                    <span title="Change project stage">
                      <Link
                        to={{
                          pathname: `/changeStageToProject/`,
                          state: {
                            projectId: `${project.id}`,
                            projectName: `${project.name}`,
                          },
                        }}
                      >
                        <span title="Set a stage for this project" className="inline-flex ">
                          <span className="text-xl text-gray-600 ">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="ml-1 text-gray-800">
                            <span>Set stage</span>
                          </span>
                        </span>
                      </Link>
                    </span>
                  </>
                ) : (
                  <span title="PERMISSION REQUIRED - changeStageToProject" className="inline-flex">
                    <span className="text-gray-600 mr-2">
                      <i className="fa fa-ellipsis-h"></i>
                    </span>
                  </span>
                )
              ) : CheckPermissionsInsideComponents("showStageRelatedProjects") ? (
                <>
                  <span
                    className="inline-flex mr-2"
                    title={`${project.stage.description} - Clic to see other projects on this stage`}
                  >
                    <Link
                      to={{
                        pathname: `/stageRelatedProjects/`,
                        state: {
                          stageName: `${project.stage.name}`,
                          stageId: `${project.stage_id}`,
                        },
                      }}
                    >
                      <span className="px-2 py-1 text-white bg-indigo-400 rounded-full shadow hover:bg-indigo-300">
                        {project.stage.name}
                      </span>
                    </Link>
                  </span>
                  <span title="Change project stage">
                    <Link
                      to={{
                        pathname: `/changeStageToProject/`,
                        state: {
                          projectId: `${project.id}`,
                          projectName: `${project.name}`,
                        },
                      }}
                    >
                      <span title="Change current stage of this project" className="md:hidden inline-flex">
                        <span className="text-xl text-gray-600">
                          <i className="fa fa-edit"></i>
                        </span>
                      </span>
                    </Link>
                  </span>
                </>
              ) : (
                <span title="PERMISSION REQUIRED - showStageRelatedProjects">
                  <span className="inline-flex items-center px-2 py-1 text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                    {project.stage.name}
                  </span>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Status:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.status === null ? (
                CheckPermissionsInsideComponents("changeStatusToProject") ? (
                  <>
                    <span title="Change project status">
                      <Link
                        to={{
                          pathname: `/changeStatusToProject/`,
                          state: {
                            projectId: `${project.id}`,
                            projectName: `${project.name}`,
                          },
                        }}
                      >
                        <span title="Set a status for this project" className="inline-flex ">
                          <span className="text-xl text-gray-600 ">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="ml-1 text-gray-800">
                            <span>Set status</span>
                          </span>
                        </span>
                      </Link>
                    </span>
                  </>
                ) : (
                  <span title="PERMISSION REQUIRED - changeStatusToProject" className="inline-flex">
                    <span className="text-gray-600 mr-2">
                      <i className="fa fa-ellipsis-h"></i>
                    </span>
                  </span>
                )
              ) : CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
                <>
                  <span
                    className="inline-flex mr-2"
                    title={`${project.status.description} - Clic to see other projects on this status`}
                  >
                    <Link
                      to={{
                        pathname: `/statusRelatedElements/`,
                        state: {
                          statusName: `${project.status.name}`,
                          statusId: `${project.status_id}`,
                        },
                      }}
                    >
                      <span className="px-2 py-1 text-white bg-indigo-400 rounded-full shadow hover:bg-indigo-300">
                        {project.status.name}
                      </span>
                    </Link>
                  </span>
                  <span title="Change project status">
                    <Link
                      to={{
                        pathname: `/changeStatusToProject/`,
                        state: {
                          projectId: `${project.id}`,
                          projectName: `${project.name}`,
                        },
                      }}
                    >
                      <span title="Change current status of this project" className="md:hidden inline-flex">
                        <span className="text-xl text-gray-600">
                          <i className="fa fa-edit"></i>
                        </span>
                      </span>
                    </Link>
                  </span>
                </>
              ) : (
                <span title="PERMISSION REQUIRED - showStatusRelatedElements">
                  <span className="inline-flex items-center px-2 py-1 text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                    {project.status.name}
                  </span>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Tags:</div>
            <div className="flex flex-col py-2 font-normal sm:flex-row sm:col-span-5">
              {projectRelatedTags.length < 1 ? (
                CheckPermissionsInsideComponents("changeTagsToProject") ? (
                  <>
                    <span title="Change project tags">
                      <Link
                        to={{
                          pathname: `/changeTagsToProject/`,
                          state: {
                            projectId: `${project.id}`,
                            projectName: `${project.name}`,
                          },
                        }}
                      >
                        <span title="Set tags for this project" className="inline-flex ">
                          <span className="text-xl text-gray-600 ">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="ml-1 text-gray-800">
                            <span>Set tags</span>
                          </span>
                        </span>
                      </Link>
                    </span>
                  </>
                ) : (
                  <span title="PERMISSION REQUIRED - changeTagsToProject" className="inline-flex">
                    <span className="text-gray-600 mr-2">
                      <i className="fa fa-ellipsis-h"></i>
                    </span>
                  </span>
                )
              ) : CheckPermissionsInsideComponents("showTagRelatedElements") ? (
                //Notese que aqui la funcion retorna un array con 2 resultados:
                //1) El resultado de projectRelatedTags.map
                //2) Enlace html para el enlace de editar tags
                [
                  projectRelatedTags.map((tag, i) => {
                    const tagColors = [
                      "bg-blue-300 hover:bg-blue-400",
                      "bg-red-300 hover:bg-red-400",
                      "bg-yellow-300 hover:bg-yellow-400",
                      "bg-orange-300 hover:bg-orange-400",
                      "bg-pink-300 hover:bg-pink-400",
                      "bg-green-300 hover:bg-green-400",
                      "bg-purple-300 hover:bg-purple-400",
                      "bg-blue-400 hover:bg-blue-500",
                      "bg-red-400 hover:bg-red-500",
                      "bg-yellow-400 hover:bg-yellow-500",
                      "bg-orange-400 hover:bg-orange-500",
                      "bg-pink-400 hover:bg-pink-500",
                      "bg-green-400 hover:bg-green-500",
                      "bg-purple-400 hover:bg-purple-500",
                      "bg-blue-400 hover:bg-blue-500",
                      "bg-red-400 hover:bg-red-500",
                      "bg-yellow-500 hover:bg-yellow-600",
                      "bg-orange-500 hover:bg-orange-600",
                      "bg-pink-500 hover:bg-pink-600",
                      "bg-green-500 hover:bg-green-600",
                      "bg-purple-500 hover:bg-purple-600",
                      "bg-blue-500 hover:bg-blue-600",
                      "bg-red-500 hover:bg-red-600",
                      "bg-yellow-600 hover:bg-yellow-700",
                      "bg-orange-600 hover:bg-orange-700",
                      "bg-pink-600 hover:bg-pink-700",
                      "bg-green-600 hover:bg-green-700",
                      "bg-purple-600 hover:bg-purple-700",
                      "bg-blue-600 hover:bg-blue-700",
                      "bg-red-600 hover:bg-red-700",
                    ];

                    const backgroundTags = Math.floor(Math.random() * tagColors.length);

                    return (
                      <div key={i} className="my-1 mr-1 sm:mr-1">
                        <Link
                          key={i}
                          to={{
                            pathname: `/tagRelatedElements/`,
                            state: {
                              tagId: `${tag.id}`,
                              tagName: `${tag.name}`,
                            },
                          }}
                          className={`${tagColors[backgroundTags]} text-white shadow px-2 py-1 rounded-full`}
                        >
                          <span
                            title={
                              tag.description
                                ? tag.description
                                : "Clic to see other projects, activities, tasks and subtasks with this tag"
                            }
                          >
                            #{tag.name}
                          </span>
                        </Link>
                      </div>
                    );
                  }),
                  <span title="Change project tags">
                    <Link
                      to={{
                        pathname: `/changeTagsToProject/`,
                        state: {
                          projectId: `${project.id}`,
                          projectName: `${project.name}`,
                        },
                      }}
                    >
                      <span title="Change current tags of this project" className="md:hidden inline-flex">
                        <span className="text-xl text-gray-600 ">
                          <i className="fa fa-edit"></i>
                        </span>
                      </span>
                    </Link>
                  </span>,
                ]
              ) : (
                projectRelatedTags.map((tag, i) => {
                  return (
                    <div key={i} className="my-1 mr-1 sm:mr-1">
                      <span title="PERMISSION REQUIRED - showTagRelatedElements">
                        <span className="inline-flex items-center px-2 py-1 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                          #{tag.name}
                        </span>
                      </span>
                    </div>
                  );
                })
              )}
            </div>

            <div className="py-2 font-semibold">Priority:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.priority ? (
                project.priority
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Created at:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.created_at ? (
                project.created_at
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Estimated finish:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.estimated_finish ? (
                project.estimated_finish
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Finished at:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {project.finished_at ? (
                project.finished_at
              ) : (
                <span className="text-gray-600">
                  <i className="fa fa-ellipsis-h"></i>
                </span>
              )}
            </div>

            <div className="py-2 font-semibold">Created By:</div>
            <div className="py-2 font-normal sm:col-span-5">
              {CheckPermissionsInsideComponents("showUserDetails") ? (
                <span title="See user details">
                  <Link
                    to={{
                      pathname: `/userDetails`,
                      state: {
                        userId: `${project.owner.id}`,
                      },
                    }}
                    className="border-b-2 border-gray-400 hover:bg-blue-200"
                  >
                    {project.owner.name}
                  </Link>
                </span>
              ) : (
                <span title="PERMISSION REQUIRED - showUserDetails">
                  <span className="border-b-2 border-gray-400">{project.owner.name}</span>
                </span>
              )}
            </div>
          </div>

          {CheckPermissionsInsideComponents("showProjectRelatedUsers") ? (
            <ProjectLeaders id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-6">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fa fa-street-view"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Project leaders:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectRelatedUsers- to see this area..."}
              />
            </>
          )}

          {CheckPermissionsInsideComponents("showProjectRelatedActivities") ? (
            <ProjectRelatedActivities id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-3">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-xl sm:mr-2">
                    <i className="fas fa-project-diagram"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Project activities:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectRelatedActivities- to see this area..."}
              />
            </>
          )}

          {CheckPermissionsInsideComponents("showProjectRelatedTasks") ? (
            <ProjectRelatedTasks id={id} />
          ) : (
            <>
              <section id="tasks" className="inline-flex justify-between mt-3">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fas fa-tasks"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Project tasks:</h2>
                </div>
              </section>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectRelatedTasks- to see this area..."}
              />
            </>
          )}

          {CheckPermissionsInsideComponents("showProjectRelatedSubtasks") ? (
            <ProjectRelatedSubTasks id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-3">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fas fa-list-ul"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Project subtasks:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectRelatedSubtasks- to see this area..."}
              />
            </>
          )}

          {CheckPermissionsInsideComponents("showProjectRelatedUsers") ? (
            <ProjectRelatedUsers id={id} />
          ) : (
            <>
              <div className="inline-flex justify-between mt-3">
                <div className="inline-flex items-center text-gray-800">
                  <div className="mr-1 text-2xl sm:mr-2">
                    <i className="fa fa-users"></i>
                  </div>
                  <h2 className="text-xl font-semibold">All related users:</h2>
                </div>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectRelatedUsers- to see this area..."}
              />
            </>
          )}

          <div className="inline-flex justify-between mt-6">
            <div className="inline-flex items-center text-gray-800">
              <div className="mr-1 text-2xl sm:mr-2">
                <i className="fa fa-file-upload"></i>
              </div>
              <h2 className="text-xl font-semibold">Project uploads:</h2>
            </div>

            <div className="inline-flex mt-2">
              {CheckPermissionsInsideComponents("addMediaToProject") ? (
                <Link
                  to={{
                    pathname: `/AddMediaToProject/`,
                    state: {
                      projectId: `${id}`,
                      projectName: `${project.name}`,
                      collections: `${project.project_collections}`,
                    },
                  }}
                >
                  <span title="Upload file">
                    <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-blue-600 rounded-lg shadow sm:text-sm hover:bg-blue-500">
                      <i className="fa fa-file-upload text-lg"></i>
                      <span className="ml-2 font-medium text-lg">New</span>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - addMediaToProject">
                  <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-gray-400 rounded-lg shadow sm:text-sm hover:bg-gray-500">
                    <i className="fa fa-file-upload text-lg"></i>
                    <span className="ml-2 font-medium text-lg">New</span>
                  </button>
                </span>
              )}
            </div>
          </div>

          {CheckPermissionsInsideComponents("showProjectRelatedMedia") ? (
            project.project_collections.length > 0 ? (
              <Tabs>
                <div className="mt-3 overflow-x-auto inline-flex items-center">
                  {/* https://stackoverflow.com/a/47616385 */}

                  {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
                  {/* <span className="block sm:hidden mr-2 font-bold">
                  Collection:
                </span> */}
                  {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
                  <span className="hidden sm:inline-block mr-2 font-semibold text-lg">
                    <p className="whitespace-no-wrap">Upload groups:</p>
                  </span>

                  {project.project_collections.map((collection, i) => {
                    const tagColors = [
                      // "bg-orange-400 hover:bg-orange-500",
                      // "bg-pink-400 hover:bg-pink-500",
                      // "bg-purple-400 hover:bg-purple-500",
                      // "bg-red-400 hover:bg-red-500",
                      // "bg-orange-600 hover:bg-orange-700",
                      // "bg-pink-600 hover:bg-pink-700",
                      // "bg-purple-600 hover:bg-purple-700",
                      // "bg-red-600 hover:bg-red-700",
                      "bg-gray-500 hover:bg-gray-600",
                    ];

                    const backgroundTags = Math.floor(Math.random() * tagColors.length);
                    return (
                      <Tab>
                        <button className={`${tagColors[backgroundTags]} rounded-lg px-3 py-2 mr-1 shadow text-white font-bold`}>
                          {collection}
                        </button>
                      </Tab>
                    );
                  })}
                </div>

                {project.project_collections.map((collection, i) => {
                  return (
                    <Panel>
                      <ProjectRelatedUploads id={id} name={project.name} collection={collection} />
                    </Panel>
                  );
                })}
              </Tabs>
            ) : (
              <Notification
                icon="fa fa-info-circle"
                background="bg-orange-400"
                message={`This project doesn't have uploaded files`}
              />
            )
          ) : (
            <Notification
              icon="fa fa-info-circle"
              background="bg-red-600"
              message={"You doesn't have required permission -showProjectRelatedMedia- to see this area..."}
            />
          )}

          {CheckPermissionsInsideComponents("showProjectReports") ? (
            <CreateCommentForm model="project" id={id} />
          ) : (
            <>
              <div className="inline-flex items-center mt-3">
                <i className="fa fa-comments"></i>
                <span className="ml-2 text-xl font-semibold">Progress reports:</span>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={"You doesn't have required permission -showProjectReports- to see this area..."}
              />
            </>
          )}

          {/* {CheckPermissionsInsideComponents("quasi") ? (
            <ShowProjectMediaAllCollections
              id={id}
              name={project.name}
              collections={project.project_collections}
            />
          ) : (
            <>
              <div className="inline-flex items-center mt-3">
                <i className="fa fa-comments"></i>
                <span className="ml-2 text-xl font-semibold">
                  Project uploads (TEST):
                </span>
              </div>

              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={
                  "You doesn't have required permission to see project upload list..."
                }
              />
            </>
          )} */}
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
