import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";

const initialTagState = {
  name: "",
  description: "",
};

const initialTagRelatedElements = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const TagRelatedElements = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const history = useHistory();

  //https://stackoverflow.com/questions/61573700/react-router-and-this-props-this-props-location-state-is-undefined
  const id = props.location.state.tagId;
  const name = props.location.state.tagName;

  const [processingGetTagDetails, setProcessingGetTagDetails] = useState(
    initialProcessingState
  );
  // const [emptyGetTagDetails, setEmptyGetTagDetails] = useState(
  //   initialEmptyState
  // );
  // const [errorGetTagDetails, setErrorGetTagDetails] = useState(
  //   initialErrorState
  // );
  const [tagDetails, setTagDetails] = useState(initialTagState);

  const [
    processingGetTagRelatedProjects,
    setProcessingGetTagRelatedProjects,
  ] = useState(initialProcessingState);
  const [emptyGetTagRelatedProjects, setEmptyGetTagRelatedProjects] = useState(
    initialEmptyState
  );
  const [errorGetTagRelatedProjects, setErrorGetTagRelatedProjects] = useState(
    initialErrorState
  );

  const idTableProjects = "tag-related-projects-table";
  const [tagRelatedProjects, setTagRelatedProjects] = useState(
    initialTagRelatedElements
  );

  const [
    processingGetTagRelatedActivities,
    setProcessingGetTagRelatedActivities,
  ] = useState(initialProcessingState);
  const [
    emptyGetTagRelatedActivities,
    setEmptyGetTagRelatedActivities,
  ] = useState(initialEmptyState);
  const [
    errorGetTagRelatedActivities,
    setErrorGetTagRelatedActivities,
  ] = useState(initialErrorState);

  const idTableActivities = "tag-related-activities-table";
  const [tagRelatedActivities, setTagRelatedActivities] = useState(
    initialTagRelatedElements
  );

  const [
    processingGetTagRelatedTasks,
    setProcessingGetTagRelatedTasks,
  ] = useState(initialProcessingState);
  const [emptyGetTagRelatedTasks, setEmptyGetTagRelatedTasks] = useState(
    initialEmptyState
  );
  const [errorGetTagRelatedTasks, setErrorGetTagRelatedTasks] = useState(
    initialErrorState
  );

  const idTableTasks = "tag-related-tasks-table";
  const [tagRelatedTasks, setTagRelatedTasks] = useState(
    initialTagRelatedElements
  );

  const [
    processingGetTagRelatedSubTasks,
    setProcessingGetTagRelatedSubTasks,
  ] = useState(initialProcessingState);
  const [emptyGetTagRelatedSubTasks, setEmptyGetTagRelatedSubTasks] = useState(
    initialEmptyState
  );
  const [errorGetTagRelatedSubTasks, setErrorGetTagRelatedSubTasks] = useState(
    initialErrorState
  );

  const idTableSubTasks = "tag-related-subtasks-table";
  const [tagRelatedSubTasks, setTagRelatedSubTasks] = useState(
    initialTagRelatedElements
  );

  const routeToGetTagDetails = "/tags";
  const routeToGetTagRelatedProjects = "/tagRelatedProjects";
  // const routeToGetTagRelatedActivities = "/allActivitiesWithThisStatus";
  const routeToGetTagRelatedActivities = "/tagRelatedActivities";
  const routeToGetTagRelatedTasks = "/tagRelatedTasks";
  const routeToGetTagRelatedSubTasks = "/tagRelatedSubTasks";

  useEffect(() => {
    const getTagDetails = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTagDetails({
          processingStatus: true,
          processingMsg: "Loading tag details, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToGetTagDetails}/${id}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            // setEmptyGetTagDetails({
            //   emptyStatus: true,
            //   emptyCode: response.status,
            //   emptyMsg: data.data.message,
            // });
            setProcessingGetTagDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            // setErrorGetTagDetails(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTagDetails(data.data);
            setProcessingGetTagDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            // setErrorGetTagDetails(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          // setErrorGetTagDetails({
          //   errorStatus: true,
          //   errorCode: response.status,
          //   errorMsg: data.error,
          // });
          setProcessingGetTagDetails(initialProcessingState);
        }

        if (response.status === 500) {
          // setErrorGetTagDetails({
          //   errorStatus: true,
          //   errorCode: response.status,
          //   errorMsg: "Internal Server Error",
          // });
          setProcessingGetTagDetails(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        // setErrorGetTagDetails({
        //   errorStatus: true,
        //   errorCode: "000",
        //   errorMsg: e,
        // });
        setProcessingGetTagDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTagRelatedProjects = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTagRelatedProjects({
          processingStatus: true,
          processingMsg: "Loading status related projects, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToGetTagRelatedProjects}/${id}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetTagRelatedProjects({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetTagRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedProjects(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTagRelatedProjects(data.data);
            setProcessingGetTagRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedProjects(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setErrorGetTagRelatedProjects({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetTagRelatedProjects(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetTagRelatedProjects({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetTagRelatedProjects(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetTagRelatedProjects({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetTagRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTagRelatedActivities = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTagRelatedActivities({
          processingStatus: true,
          processingMsg: "Loading status related activities, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToGetTagRelatedActivities}/${id}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetTagRelatedActivities({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetTagRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedActivities(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTagRelatedActivities(data.data);
            setProcessingGetTagRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedActivities(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setErrorGetTagRelatedActivities({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetTagRelatedActivities(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetTagRelatedActivities({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetTagRelatedActivities(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetTagRelatedActivities({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetTagRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTagRelatedTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTagRelatedTasks({
          processingStatus: true,
          processingMsg: "Loading status related tasks, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToGetTagRelatedTasks}/${id}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetTagRelatedTasks({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetTagRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedTasks(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTagRelatedTasks(data.data);
            setProcessingGetTagRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedTasks(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setErrorGetTagRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetTagRelatedTasks(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetTagRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetTagRelatedTasks(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetTagRelatedTasks({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetTagRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTagRelatedSubTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTagRelatedSubTasks({
          processingStatus: true,
          processingMsg: "Loading status related subtasks, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToGetTagRelatedSubTasks}/${id}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetTagRelatedSubTasks({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetTagRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedSubTasks(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTagRelatedSubTasks(data.data);
            setProcessingGetTagRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetTagRelatedSubTasks(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setErrorGetTagRelatedSubTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetTagRelatedSubTasks(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetTagRelatedSubTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetTagRelatedSubTasks(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetTagRelatedSubTasks({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetTagRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getTagDetails();
      getTagRelatedProjects();
      getTagRelatedActivities();
      getTagRelatedTasks();
      getTagRelatedSubTasks();
    }
  }, [appUrl, token, id]);

  const columnsProjects = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/projectDetails`,
                state: {
                  projectId: `${row.original.id}`,
                  projectName: `${row.original.name}`,
                },
              }}
              className="px-2 font-semibold text-white bg-blue-400 shadow"
            >
              {row.original.id}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/projectDetails`,
                state: {
                  projectId: `${row.original.id}`,
                  projectName: `${row.original.name}`,
                },
              }}
              className="border-b-2 border-gray-400 hover:bg-blue-200"
            >
              {row.original.name}
            </Link>
          ) : (
            ""
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Stage: ",
        accessor: "stage.name",
        Cell: ({ row }) =>
          row.original.stage ? (
            <Link
              to={{
                pathname: `/stageRelatedProjects`,
                state: {
                  stageId: `${row.original.stage.id}`,
                  stageName: `${row.original.stage.name}`,
                },
              }}
              className="px-2 text-white bg-blue-400 rounded-full shadow"
            >
              {row.original.stage.name}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <Link
              to={{
                pathname: `/statusRelatedElements`,
                state: {
                  statusId: `${row.original.status.id}`,
                  statusName: `${row.original.status.name}`,
                },
              }}
              className="px-2 text-white bg-teal-400 rounded-full shadow"
            >
              {row.original.status.name}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Tags: ",
        accessor: "tags.name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.tags.map((tag, i) =>
            tag.name === name ? (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 font-medium text-white bg-indigo-400 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            ) : (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 text-white bg-indigo-300 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            )
          ),
      },
      // {
      //   Header: "Priority: ",
      //   accessor: "priority",
      // },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              <Link
                to={{
                  pathname: `/projectDetails/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See project details">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </Link>

              {/* <Link
                to={{
                  pathname: `/createActivity/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Create an activity for this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                    <i className="fas fa-project-diagram"></i>
                  </button>
                </span>
              </Link> */}

              {/* <Link
                to={{
                  pathname: `/addUserToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Add an user leader to this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              </Link> */}

              <Link
                to={{
                  pathname: `/changeStageToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the stage of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                    <i className="fas fa-certificate"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeStatusToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the status of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800">
                    <i className="fas fa-th-list"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeTagsToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this project">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r hover:bg-blue-700">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link>
            </div>
          </>
        ),
      },
    ],
    [name]
  );

  const columnsActivities = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/activityDetails`,
                state: {
                  activityId: `${row.original.id}`,
                  activityName: `${row.original.name}`,
                },
              }}
              className="px-2 font-semibold text-white bg-blue-400 shadow"
            >
              {row.original.id}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/activityDetails`,
                state: {
                  activityId: `${row.original.id}`,
                  activityName: `${row.original.name}`,
                },
              }}
              className="border-b-2 border-gray-400 hover:bg-blue-200"
            >
              {row.original.name}
            </Link>
          ) : (
            ""
          ),
      },

      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <Link
              to={{
                pathname: `/statusRelatedElements`,
                state: {
                  statusId: `${row.original.status.id}`,
                  statusName: `${row.original.status.name}`,
                },
              }}
              className="px-2 text-white bg-teal-400 rounded-full shadow"
            >
              {row.original.status.name}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Tags: ",
        accessor: "tags.name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.tags.map((tag, i) =>
            tag.name === name ? (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 font-medium text-white bg-indigo-400 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            ) : (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 text-white bg-indigo-300 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            )
          ),
      },
      // {
      //   Header: "Priority: ",
      //   accessor: "priority",
      // },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              <Link
                to={{
                  pathname: `/activityDetails/`,
                  state: {
                    activityId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See activity details">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeStatusToActivity/`,
                  state: {
                    activityName: `${row.original.name}`,
                    activityId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the status of this activity">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                    <i className="fas fa-th-list"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeTagsToActivity/`,
                  state: {
                    activityName: `${row.original.name}`,
                    activityId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this activity">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link>
            </div>
          </>
        ),
      },
    ],
    [name]
  );

  const columnsTasks = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/taskDetails`,
                state: {
                  taskId: `${row.original.id}`,
                  taskName: `${row.original.name}`,
                },
              }}
              className="px-2 font-semibold text-white bg-blue-400 shadow"
            >
              {row.original.id}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/taskDetails`,
                state: {
                  taskId: `${row.original.id}`,
                  taskName: `${row.original.name}`,
                },
              }}
              className="border-b-2 border-gray-400 hover:bg-blue-200"
            >
              {row.original.name}
            </Link>
          ) : (
            ""
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <Link
              to={{
                pathname: `/statusRelatedElements`,
                state: {
                  statusId: `${row.original.status.id}`,
                  statusName: `${row.original.status.name}`,
                },
              }}
              className="px-2 text-white bg-teal-400 rounded-full shadow"
            >
              {row.original.status.name}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Tags: ",
        accessor: "tags.name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.tags.map((tag, i) =>
            tag.name === name ? (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 font-medium text-white bg-indigo-400 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            ) : (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 text-white bg-indigo-300 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            )
          ),
      },
      // {
      //   Header: "Priority: ",
      //   accessor: "priority",
      // },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              <Link
                to={{
                  pathname: `/taskDetails/`,
                  state: {
                    taskName: `${row.original.name}`,
                    taskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See task details">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeStatusToTask/`,
                  state: {
                    taskName: `${row.original.name}`,
                    taskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the status of this task">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                    <i className="fas fa-th-list"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeTagsToTask/`,
                  state: {
                    taskName: `${row.original.name}`,
                    taskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this task">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link>
            </div>
          </>
        ),
      },
    ],
    [name]
  );

  const columnsSubTasks = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/subtaskDetails`,
                state: {
                  subtaskId: `${row.original.id}`,
                  subtaskName: `${row.original.name}`,
                },
              }}
              className="px-2 font-semibold text-white bg-blue-400 shadow"
            >
              {row.original.id}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.id ? (
            <Link
              to={{
                pathname: `/subtaskDetails`,
                state: {
                  subtaskId: `${row.original.id}`,
                  subtaskName: `${row.original.name}`,
                },
              }}
              className="border-b-2 border-gray-400 hover:bg-blue-200"
            >
              {row.original.name}
            </Link>
          ) : (
            ""
          ),
      },
      /*
        {
          Header: "Description: ",
          accessor: "description",
        },
        */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <Link
              to={{
                pathname: `/statusRelatedElements`,
                state: {
                  statusId: `${row.original.status.id}`,
                  statusName: `${row.original.status.name}`,
                },
              }}
              className="px-2 text-white bg-teal-400 rounded-full shadow"
            >
              {row.original.status.name}
            </Link>
          ) : (
            ""
          ),
      },
      {
        Header: "Tags: ",
        accessor: "tags.name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          row.original.tags.map((tag, i) =>
            tag.name === name ? (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 font-medium text-white bg-indigo-400 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            ) : (
              <div key={i} className="inline-flex my-1 mr-1 sm:mr-1">
                <Link
                  key={i}
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${tag.name}`,
                      tagId: `${tag.id}`,
                    },
                  }}
                  className="px-2 text-white bg-indigo-300 rounded-full shadow"
                >
                  #{tag.name}
                </Link>
              </div>
            )
          ),
      },
      // {
      //   Header: "Priority: ",
      //   accessor: "priority",
      // },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
        {
          Header: "Created by: ",
          accessor: "owner.name",
        },
        {
          Header: " Updated at: ",
          accessor: "updated_at",
          sortType: "datetime",
        },
        {
          Header: " Deleted at: ",
          accessor: "deleted_at",
        },
        */
      /*Idea para colocar botones de accion sacada de aqui:
        Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
        para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              <Link
                to={{
                  pathname: `/subtaskDetails/`,
                  state: {
                    subtaskName: `${row.original.name}`,
                    subtaskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See subtask details">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeStatusToSubTask/`,
                  state: {
                    subtaskName: `${row.original.name}`,
                    subtaskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the status of this subtask">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                    <i className="fas fa-th-list"></i>
                  </button>
                </span>
              </Link>

              <Link
                to={{
                  pathname: `/changeTagsToSubTask/`,
                  state: {
                    subtaskName: `${row.original.name}`,
                    subtaskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this subtask">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link>
            </div>
          </>
        ),
      },
    ],
    [name]
  );

  return (
    <>
      <div className="flex">
        <div className="inline-flex items-center justify-start w-1/3">
          <div className="mr-1 text-3xl">
            <i className="fa fa-tags"></i>
          </div>

          <h2 className="ml-1 text-2xl font-semibold">Tag:</h2>
        </div>

        <div className="inline-flex items-center justify-center w-1/3 my-5 sm:my-0">
          {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
          {/* <div className="mt-1 mr-1 text-xl font-semibold text-gray-700">Tag:</div> */}
          <div className="hidden px-3 mt-2 mb-2 text-lg text-white bg-indigo-500 rounded-full shadow sm:block">
            #{name}
          </div>
          {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
          {/* <div className="mt-1 mr-1 text-xl font-semibold text-gray-700">Tag:</div> */}
          <div className="block px-2 mt-2 mb-2 text-base text-white bg-indigo-500 rounded-full shadow sm:hidden">
            #{name}
          </div>
        </div>

        <div className="inline-flex items-center justify-end w-1/3">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      <div className="flex flex-col">
        {processingGetTagDetails.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetTagDetails.processingMsg}
          />
        )}

        {tagDetails.description && (
          <div className="flex justify-start mb-2 sm:my-5">
            <span className="mr-1 text-xl font-medium text-gray-700">
              <span className="mr-1 text-lg font-bold text-gray-700">
                Description:
              </span>
              <span className="mr-1 text-lg text-gray-700 font-base">
                {tagDetails.description}
              </span>
            </span>
          </div>
        )}

        <div className="inline-flex items-center mt-3 sm:mt-0">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Tag related projects:</h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processingGetTagRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetTagRelatedProjects.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetTagRelatedProjects.processingMsg}
          />
        )}

        {/*Indicar estado relacionado a errorGetTagRelatedProjects, mostrando mensaje que se ha producido errorGetTagRelatedProjects en la peticion*/}
        {errorGetTagRelatedProjects.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetTagRelatedProjects.errorCode} ${errorGetTagRelatedProjects.errorMsg}`}
          />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetTagRelatedProjects.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetTagRelatedProjects.emptyCode} ${emptyGetTagRelatedProjects.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent
            id={idTableProjects}
            columns={columnsProjects}
            data={tagRelatedProjects}
          />
        )}

        {/*Indicar estado relacionado a processingGetTagRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetTagRelatedActivities.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetTagRelatedActivities.processingMsg}
          />
        )}

        {/*Indicar estado relacionado a errorGetTagRelatedProjects, mostrando mensaje que se ha producido errorGetTagRelatedProjects en la peticion*/}
        {errorGetTagRelatedActivities.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetTagRelatedActivities.errorCode} ${errorGetTagRelatedActivities.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-project-diagram"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Tag related activities:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetTagRelatedActivities.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetTagRelatedActivities.emptyCode} ${emptyGetTagRelatedActivities.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent
            id={idTableActivities}
            columns={columnsActivities}
            data={tagRelatedActivities}
          />
        )}

        {/*Indicar estado relacionado a processingGetTagRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetTagRelatedTasks.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetTagRelatedTasks.processingMsg}
          />
        )}

        {/*Indicar estado relacionado a errorGetTagRelatedProjects, mostrando mensaje que se ha producido errorGetTagRelatedProjects en la peticion*/}
        {errorGetTagRelatedTasks.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetTagRelatedTasks.errorCode} ${errorGetTagRelatedTasks.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-tasks"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Tag related tasks:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetTagRelatedTasks.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetTagRelatedTasks.emptyCode} ${emptyGetTagRelatedTasks.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent
            id={idTableTasks}
            columns={columnsTasks}
            data={tagRelatedTasks}
          />
        )}

        {/*Indicar estado relacionado a processingGetTagRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetTagRelatedSubTasks.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetTagRelatedSubTasks.processingMsg}
          />
        )}

        {/*Indicar estado relacionado a errorGetTagRelatedProjects, mostrando mensaje que se ha producido errorGetTagRelatedProjects en la peticion*/}
        {errorGetTagRelatedSubTasks.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetTagRelatedSubTasks.errorCode} ${errorGetTagRelatedSubTasks.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-list-ul"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Tag related subtasks:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetTagRelatedSubTasks.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetTagRelatedSubTasks.emptyCode} ${emptyGetTagRelatedSubTasks.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent
            id={idTableSubTasks}
            columns={columnsSubTasks}
            data={tagRelatedSubTasks}
          />
        )}
      </div>
    </>
  );
};

export default TagRelatedElements;
