import React from "react";

//https://www.smashingmagazine.com/2020/02/tailwindcss-react-project/

function Content(props) {
  return (
    // Habilitar pie de pagina pegajoso (o en este caso, hacer que el div contenedor de la pagina crezca)
    // https://stackoverflow.com/a/65599498
    <div className="m-5 flex flex-col wrap flex-grow">{props.children}</div>
  );
}

export default Content;
