//https://thewidlarzgroup.com/react-table-7/ Informacion principal
//https://blog.logrocket.com/building-styling-tables-react-table-v7/ //Informacion de apoyo
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialUserRelatedSubTasks = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const UserRelatedSubTasks = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const [userSubTasks, setUserSubTasks] = useState(initialUserRelatedSubTasks);
  const [empty, setEmpty] = useState(initialEmptyState);
  const id = props.id;
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const fetchRoute = "/userSubTasks";
  const idTable = "user-related-subtasks-table";

  useEffect(() => {
    const getUserSubTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${fetchRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setUserSubTasks(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getUserSubTasks();
    }
  }, [appUrl, token, id]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ID: ",
        accessor: "id",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showSubtaskDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/subTaskDetails`,
                  state: {
                    subTaskId: `${row.original.id}`,
                  },
                }}
                className="px-2 font-semibold text-white bg-blue-400 shadow"
              >
                {row.original.id}
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showSubtaskDetails">
              <span className="px-2 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: " Name: ",
        accessor: "name",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showSubtaskDetails") ? (
            <Link
              to={{
                pathname: `/subTaskDetails`,
                state: {
                  subTaskId: `${row.original.id}`,
                },
              }}
              className="border-b-2 border-gray-400 hover:bg-blue-200"
            >
              {row.original.name}
            </Link>
          ) : (
            row.original.name
          ),
      },
      {
        Header: " Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            row.original.status ? (
              <Link
                to={{
                  pathname: `/statusRelatedElements/`,
                  state: {
                    statusName: `${row.original.status.name}`,
                    statusId: `${row.original.status.id}`,
                  },
                }}
              >
                <span title="See other projects, activities, tasks and subtasks with this status">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                    {row.original.status.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.status ? (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: "Created at: ",
        accessor: "created_at",
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
      },

      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showSubtaskDetails") ? (
                <Link
                  to={{
                    pathname: `/subTaskDetails/`,
                    state: {
                      subTaskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See subtask details">
                    <button className="inline-flex items-center px-4 py-2 mr-1 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showSubtaskDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="inline-flex justify-between mt-6">
        <div className="inline-flex items-center text-gray-800">
          <div className="mr-1 text-2xl sm:mr-2">
            <i className="fas fa-tasks"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">User related subtasks:</h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-400" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={userSubTasks} />}
      </div>
    </>
  );
};

export default UserRelatedSubTasks;
