import React from "react";

const DeleteStatusButtonDisabled = () => {
  return (
    <span title="PERMISSION REQUIRED - deleteStatus">
      <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded-r shadow hover:bg-gray-500">
        <span>
          <i className="fa fa-trash"></i>
        </span>
      </button>
    </span>
  );
};

export default DeleteStatusButtonDisabled;
