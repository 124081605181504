import React from "react";

const CreateNewProjectButtonDisabled = () => {
  return (
    <span title="PERMISSION REQUIRED - createProject">
      <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-gray-400 rounded-l-full sm:text-base hover:bg-gray-500">
        <i className="fa fa-plus-square"></i>&nbsp;
        <span className="ml-1">New</span>
      </button>
    </span>
  );
};

export default CreateNewProjectButtonDisabled;
