import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth";

// PASO #2 - Crear decorador para aplicar login obligatorio en las rutas que lo implementen

// https://medium.com/better-programming/building-basic-react-authentication-e20a574d5e71
// aplicar esta misma logica pero basado en componentes de tipo clase:
// https://medium.com/javascript-in-plain-english/how-to-set-up-protected-routes-in-your-react-application-a3254deda380

// The first thing we want to set up is a new route component we’ll call
// PrivateRoute. This decorator will be used by any route that needs to be
// behind authentication. Simple enough, let’s make a new file in the `src`
// directory called PrivateRoute.js.

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   return <Route {...rest} render={(props) => <Component {...props} />} />;
// };

// You’ll notice we haven’t added any authentication logic yet. We are merely
// rendering the route that is passed in, just like a public route. We have
// changed the API slightly though. We’re using the Render Props style for
// the route here instead. This will make more sense later when we add logic
// for authentication — for now, assume it does the same thing as using the
// Component props of the public routes.

// Now, if you look in that render props, its obvious we should have some
// authentication inside it. But we don’t have that set up quite yet. We
// aren’t using Redux, but we may want to have authentication data throughout
// our app. To avoid prop drilling, we are going to use the Context API.
// We’ll have another blog entry on the extent of the Context API, but for
// now let’s assume that data we place into it can be removed anywhere in
// the react tree. Behind the scenes, this is what Redux uses.

// First things first, we are going to create a new context. I’ve created a
// folder called context in the src directory. Inside that, I'll create a
// new file called auth.js.

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
