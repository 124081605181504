import React from "react";

const DeleteProjectMediaButtonDisabled = ({ rounded }) => {
  return (
    <span title="PERMISSION REQUIRED - deleteProjectRelatedMedia">
      {rounded ? (
        <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
          <i className="fa fa-trash"></i>
        </button>
      )}
    </span>
  );
};

export default DeleteProjectMediaButtonDisabled;
