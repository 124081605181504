import React, { useState, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Notification from "../../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import axios from "axios";

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const AddMediaToProject = (props) => {
  const id = props.location.state.projectId;
  const projectName = props.location.state.projectName;
  const projectCollections = props.location.state.collections;
  const { appUrl, token } = useContext(AppContext);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const thisRoute = "/files/addMediaToProject";
  const history = useHistory();

  //Estado para almacenar valor de la accion a realizar (agregar a coleccion existente / nueva)
  const [action, setAction] = useState(null);
  //Estado para almacenar valor de la coleccion seleccionada
  const [selected, setSelected] = useState("");
  //Estado para almacenar los archivos a subir
  const [archivos, setArchivos] = useState(null); //https://www.youtube.com/watch?v=nCggM2MXbT4

  //Funcion auxiliar para convertir en array elementos de un string (que vienen separados por comas)
  const convertStringValuesToArray = (string) => {
    const convertedToArray = string.split(",");
    return convertedToArray;
  };

  //Almacenar en una lista las colecciones recibidas en formato de array
  const list = convertStringValuesToArray(projectCollections).map(function (
    //https://medium.com/how-to-react/react-select-dropdown-tutorial-using-react-select-51664ab8b6f3 | https://stackoverflow.com/questions/47672117/react-select-how-to-show-iterate-through-data-from-api-call-in-option-instea
    item
  ) {
    return { value: item, label: item };
  });

  //Estado para almacenar todas las colecciones de un proyecto (basicamente el contenido del prop projectCollections, pero en formato de array y usarlo para provocar reactividad en las opciones del select)
  const [listState, SetListState] = useState(list);

  const subirArchivos = (e) => {
    setArchivos(e);
  };

  const handleChangeOnSelectField = (event) => {
    // https://stackoverflow.com/questions/60519123/passing-value-to-state-using-react-select
    setSelected(event.value);
  };

  const handleChangeOnInputField = (event) => {
    setSelected(event.target.value);
  };

  const handleChangeOnRadioField = (event) => {
    setAction(event.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Agregar coleccion recien creada en el estado que contiene las opciones del select
    SetListState((listState) => [
      // https://stackoverflow.com/a/54677026
      ...listState,
      { value: selected, label: selected },
    ]);

    const f = new FormData();

    //Agregar todos los archivos seleccionados al FormData
    for (let index = 0; index < archivos.length; index++) {
      f.append("media[]", archivos[index]); //https://stackoverflow.com/a/14908250
    }

    //Agregar el valor de la coleccion seleccionada al FormData
    f.append("collection", selected);

    setError(initialErrorState); // Asignar el estado user inicial para limpiar los campos del formulario
    // Indicar que se ha empezado a procesar la solicitud
    setProcessing({
      processingStatus: true,
      processingMsg: "Loading data, please wait...",
    });

    await axios
      .post(`${appUrl}${thisRoute}/${id}`, f, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSuccess({
          successStatus: true,
          successMsg: response.data.message,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      })
      .catch((error) => {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(error.response.data).forEach((key) => {
          error_list.push(error.response.data[key]);
          // console.log(error[key]);
        });

        // //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: error.response.status,
          errorMsg: error_list,
        });

        setProcessing(initialProcessingState);
      });
  };

  return CheckPermissionsInsideComponents("addMediaToProject") ? (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-2xl sm:mr-2">
            <i className="fa fa-file-upload"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Upload media to project:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con exito la peticion*/}
      {success.successStatus && <Notification icon="fa fa-check-circle" background="bg-green-600" message={success.successMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}
      <div className="mt-8">
        <div className="flex justify-center w-full">
          <div className="grid grid-cols-2 px-4 py-0 text-gray-800  border border-gray-200 rounded-lg shadow sm:grid-cols-6">
            <div className="py-2 font-semibold">Project:</div>
            <div className="ml-1 py-2 font-normal sm:col-span-5">{projectName}</div>
          </div>
        </div>

        <div className="flex justify-center">
          <form className="justify-center mt-8 w-full max-w-lg" enctype="multipart/form-data" onSubmit={(e) => onSubmit(e)}>
            {/* https://stackabuse.com/javascript-check-if-array-contains-a-value-element/ */}
            {list.some((collection) => collection.value === "") ? (
              <>
                <div className="bg-white p-2 rounded-lg border-2 mt-2">
                  <input type="file" name="media" onChange={(e) => subirArchivos(e.target.files)} multiple />
                </div>

                <input
                  className="placeholder-black block w-full px-3 py-2 mt-3 leading-tight text-gray-800 border border-gray-400 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                  placeholder="Type the name of the new group."
                  type="text"
                  id="collection"
                  name="collection"
                  onChange={(event) => handleChangeOnInputField(event)}
                />
              </>
            ) : (
              <>
                <div className="flex justify-center">
                  <span className="text-base font-semibold">
                    Where you want to upload this files? {/* {console.log(list.some((collection) => collection.value === ""))} */}
                  </span>
                </div>

                <div className="flex justify-around mt-8">
                  <div>
                    <input
                      type="radio"
                      id="select"
                      name="action"
                      value="select"
                      onChange={(event) => handleChangeOnRadioField(event)}
                    />
                    <label for="select" className="ml-2">
                      Existing group
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="add"
                      name="action"
                      value="add"
                      onChange={(event) => handleChangeOnRadioField(event)}
                    />
                    <label for="add" className="ml-2">
                      New group
                    </label>
                  </div>
                </div>

                <div className="bg-white p-2 rounded-lg border-2 mt-2">
                  <input type="file" name="media" onChange={(e) => subirArchivos(e.target.files)} multiple />
                </div>

                {action === "select" && (
                  <Select
                    isSearchable
                    components={makeAnimated()}
                    noOptionsMessage={() => "No items to select..."}
                    placeholder="Select an group"
                    id="collection"
                    name="collection"
                    options={listState}
                    onChange={(event) => handleChangeOnSelectField(event)}
                    className="mt-3"
                  />
                  // {/* https://stackoverflow.com/a/54570639 */}
                  // {/* <select
                  //   name="selectedCollection"
                  //   className="select-form"
                  //   value={selected}
                  //   onChange={(event) => setSelected(event.target.value)}
                  // >
                  //   {convertStringValuesToArray(projectCollections).map(
                  //     (collection, i) => {
                  //       return (
                  //         <option key={i} value={collection}>
                  //           {collection}
                  //         </option>
                  //       );
                  //     }
                  //   )}
                  // </select> */}
                )}

                {action === "add" && (
                  <input
                    className="placeholder-black block w-full px-3 py-2 mt-3 leading-tight text-gray-800 border border-gray-400 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                    placeholder="Type the name of the new group."
                    type="text"
                    id="collection"
                    name="collection"
                    onChange={(event) => handleChangeOnInputField(event)}
                  />
                )}

                {/* https://stackoverflow.com/a/49784386 */}
                {/* {(() => {
            switch (action) {
              case 1:
                return (
                  <Select
                    isSearchable
                    components={makeAnimated()}
                    noOptionsMessage={() => "No items to select..."}
                    placeholder="Select an collection"
                    id="collection"
                    name="collection"
                    options={list}
                    onChange={(event) => handleChangeOnSelectField(event)}
                    className="mt-3"
                  />
                );

              case 2:
                return (
                  <input
                    className="block w-full px-4 py-3 mt-1 leading-tight text-gray-800 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                    placeholder="Please type the name of gruop what you want upload this files"
                    type="text"
                    id="collection"
                    name="collection"
                    onChange={(event) => handleChangeOnInputField(event)}
                  />
                );

              default:
                return null;
            }
          })()} */}
              </>
            )}

            <div className="flex justify-center mt-6">
              {!archivos ? (
                <button
                  className="px-4 py-2 font-bold text-center text-white bg-gray-500 rounded shadow hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                  type="submit"
                  disabled={true}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="px-4 py-2 font-bold text-center text-white bg-green-500 rounded shadow hover:bg-green-400 focus:shadow-outline focus:outline-none"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  ) : (
    <Notification
      icon="fa fa-info-circle"
      background="bg-red-600"
      message={"You doesn't have required permission to -addMediaToProject- to see this area..."}
    />
  );
};

export default AddMediaToProject;
