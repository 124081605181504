import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import { v4 as uuidv4 } from "uuid";

const initialProjectState = {
  stage_id: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
  activities: [],
};

const initialActivityState = {
  uuid: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
};

const initialTaskState = {
  uuid: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
};

const initialSubTaskState = {
  uuid: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
};

const initialAllActivitiesState = [];
const initialAllTasksState = [];
const initialAllSubTasksState = [];

const initialStageInputState = [];
const initialStatusInputState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const CreateOnFlyEntireProject = () => {
  const { appUrl, token, loggedUser } = useContext(AppContext);
  // Estado para almacenar la informacion a de todo el proyecto (info que ira a la API)
  const [entireObject, setEntireObject] = useState("");
  // Estados para almacenar unicamente los detalles de PATS
  const [project, setProject] = useState(initialProjectState);
  const [activity, setActivity] = useState(initialActivityState);
  const [task, setTask] = useState(initialTaskState);
  const [subtask, setSubTask] = useState(initialSubTaskState);
  // Estado para almacenar la coleccion de PATS
  const [allActivities, setAllActivities] = useState(initialAllActivitiesState);
  const [allTasks, setAllTasks] = useState(initialAllTasksState);
  const [allSubTasks, setAllSubTasks] = useState(initialAllSubTasksState);
  // Estados para almacenar selecciones de elementos (uuid al cual pertenecera el registro a crear)
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedSubTask, setSelectedSubTask] = useState(null);
  // Estados para almacenar si mostrar o no formularios
  const [showProjectForm, setShowProjectForm] = useState(true);
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [showSubTaskForm, setShowSubTaskForm] = useState(false);
  // Estados para indicar el id de datos relacionados
  const [statusInput, setStatusInput] = useState(initialStatusInputState);
  const [stageInput, setStageInput] = useState(initialStageInputState);
  // Estados para indicar estado de operaciones en curso
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  // Constantes para rutas
  const thisRoute = "/OnFlyDynamicProjectCreation";
  const history = useHistory();

  useEffect(() => {
    const getStage = async () => {
      const routeToFetchStages = "/stages";
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToFetchStages}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al usuario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStageInput(data.data);
            /*setSuccess({
              successStatus: true,
            });*/
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatus = async () => {
      const routeToFetchStatus = "/states";
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToFetchStatus}`,
          fetchConfig
        );
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al usuario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusInput(data.data);
            /*setSuccess({
              successStatus: true,
            });*/
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getStage();
      getStatus();
    }
  }, [appUrl, token]);

  //Guardar los datos del estado project en la api
  const onSubmit = async (entireObject, event) => {
    event.preventDefault();
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(entireObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setError(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessing({
        processingStatus: true,
        processingMsg: "Loading data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setProject(initialProjectState); // Asignar el estado project inicial para limpiar los campos del formulario
        // Indicar en el estado que la petición se realizo con éxito y devolver el mensaje de respuesta de la api
        setSuccess({
          successStatus: true,
          successMsg: data.data.message,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessing(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setError({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessing(initialProcessingState);
    }
  };

  const project_stage_id = useRef();
  const project_status_id = useRef();
  const project_priority = useRef();
  const project_name = useRef();
  const project_description = useRef();
  const project_estimated_finish = useRef();
  const project_finished_at = useRef();

  const activity_stage_id = useRef();
  const activity_status_id = useRef();
  const activity_priority = useRef();
  const activity_name = useRef();
  const activity_description = useRef();
  const activity_estimated_finish = useRef();
  const activity_finished_at = useRef();

  const clearProjectForm = (event, formName) => {
    //https://www.freecodecamp.org/news/how-to-clear-input-values-of-dynamic-form-in-react/
    event.preventDefault();
    project_stage_id.current.value = "";
    project_status_id.current.value = "";
    project_priority.current.value = "";
    project_name.current.value = "";
    project_description.current.value = "";
    project_estimated_finish.current.value = "";
    project_finished_at.current.value = "";

    // document.forms["projectForm"].reset();
    // Array.from(document.forms[formName].querySelectorAll("input")).forEach(
    //   (input) => (input.value = "")
    // );
    // Array.from(document.forms[formName].querySelectorAll("textarea")).forEach(
    //   (input) => (input.value = "")
    // );
    // Array.from(document.forms[formName].querySelectorAll("select")).forEach(
    //   (input) => (input.value = "")
    // );
    // document.forms["projectForm"].stage_id.value = "";
  };

  const clearActivityForm = (event, formName) => {
    event.preventDefault();
    activity_stage_id.current.value = "";
    activity_status_id.current.value = "";
    activity_priority.current.value = "";
    activity_name.current.value = "";
    activity_description.current.value = "";
    activity_estimated_finish.current.value = "";
    activity_finished_at.current.value = "";
  };

  //Guardar los datos introducidos en el formulario en el estado project
  const handleInputChangesFormProject = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    setProject({
      ...project, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value, //Asignar en cada propiedad del estado project, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });
    // console.log(`${event.target.name} ${event.target.value}`);
    // console.log(project);
  };

  const handleInputChangesFormActivity = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    const uuid = uuidv4();
    setActivity({
      ...activity, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value,
      uuid: uuid, //Asignar en cada propiedad del estado project, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });

    // console.log(`${event.target.name} ${event.target.value}`);
    // console.log(activity);
  };

  const handleInputChangesFormTask = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    const uuid = uuidv4();
    setTask({
      ...task, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value,
      uuid: uuid, //Asignar en cada propiedad del estado project, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });

    // console.log(`${event.target.name} ${event.target.value}`);
    // console.log(activity);
  };

  const handleInputChangesFormSubTask = (event) => {
    const { name, value } = event.target;
    const uuid = uuidv4();

    setSubTask({
      ...subtask,
      [name]: value,
      uuid: uuid,
    });
  };

  const createProject = () => {
    setEntireObject(project);

    setShowProjectForm(false);
    setShowActivityForm(true);
  };

  const addActivityToTemporalActivitiesArray = (event) => {
    event.preventDefault();

    ////////////////// AGREGAR ACTIVIDADES EN 2 PASOS PRIMERO A UN ARRAY //////////////////

    //Este codigo mete cada actividad en un array para luego en otro metodo (mergeActivitiesWithProject)
    //agregar este array como una propiedad del estado para el proyecto

    // setAllActivities((allActivities) => [...allActivities, activity]); //https://stackoverflow.com/a/54677026

    // const activities = allActivities; //renombrar el arreglo allActivities a activities
    // const entireObject = { ...project, activities };

    // setEntireObject(entireObject);

    ////////////////// AGREGAR ACTIVIDADES EN 1 PASO DIRECTO AL PROYECTO //////////////////

    const temporalProject = { ...project };

    temporalProject.activities.push(activity);

    setProject(temporalProject);

    ///////////////////////////////////

    setShowProjectForm(false);
  };

  const addTaskToTemporalTasksArray = (event) => {
    event.preventDefault();

    setAllTasks((allTasks) => [...allTasks, task]); //https://stackoverflow.com/a/54677026
  };

  const addSubTaskToTemporalSubTasksArray = (event) => {
    event.preventDefault();

    setAllSubTasks((allSubTasks) => [...allSubTasks, subtask]); //https://stackoverflow.com/a/54677026
  };

  const mergeActivitiesWithProject = (event) => {
    event.preventDefault();

    const activities = allActivities;
    const entireObject = { ...project, activities };

    setEntireObject(entireObject);

    setShowProjectForm(false);
  };

  const mergeTasksWithActivity = (event) => {
    event.preventDefault();

    //Dejo este codigo por motivos historicos. NO SE ACTUALIZA EL ESTADO DIRECTAMENTE (como aqui)
    //si bien se veran las tareas de cada actividad en la variable, esta forma de hacerlo no dispara
    //el render en la vista, en ves de este enfoque, mas bien se CREA UNA COPIA DEL ESTADO, se modifica
    //dicha copia y luego se sustituye el estado original por la copia actual. Eso si dispara el render.
    const newEntireObject = { ...entireObject };

    let thisActivity = newEntireObject.activities.find(
      (activity) => activity.uuid === selectedActivity
    );

    thisActivity.tasks = allTasks;

    setEntireObject(newEntireObject);

    setAllTasks(initialAllTasksState);
  };

  const mergeSubTasksWithTask = (event) => {
    event.preventDefault();

    const newEntireObject = { ...entireObject };

    newEntireObject.activities.forEach((activity) => {
      if (activity?.tasks) {
        activity.tasks.find((task) => {
          return task.uuid === selectedTask
            ? (task.subtasks = allSubTasks)
            : (task.subtasks = null);
        });
      }
    });

    setEntireObject(newEntireObject);

    setAllSubTasks(initialAllSubTasksState);
  };

  const handleProjectSelection = (event) => {
    event.stopPropagation();

    setShowActivityForm(true);
    setShowTaskForm(false);
    setShowSubTaskForm(false);
  };

  const handleActivitySelection = (uuid, name, event) => {
    setSelectedActivity(uuid, name);

    setShowActivityForm(false);
    setShowTaskForm(true);
  };

  const handleTaskSelection = (uuid, name, event) => {
    setSelectedTask(uuid, name);

    setShowTaskForm(false);
    setShowSubTaskForm(true);
  };

  const handleSubTaskSelection = (uuid, name, event) => {
    setSelectedSubTask(uuid, name);
  };

  //Si el usuario tiene el permiso indicado por parametro, se mostrara el contenido de este componente en caso contrario, sera redireccionado a una pagina de notificacion
  return CheckPermissionsInsideComponents("quasi") ? (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">
              CreateOnFlyEntireProject:
            </h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/* Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la petición */}
      {processing.processingStatus && (
        <Notification
          background="bg-teal-400"
          message={processing.processingMsg}
        />
      )}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con éxito la petición*/}
      {success.successStatus && (
        <Notification
          icon="fa fa-check-circle"
          background="bg-green-600"
          message={success.successMsg}
        />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la petición*/}
      {error.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${error.errorCode} ${error.errorMsg}`}
        />
      )}
      <div className="inline-flex justify-between">
        <div className="w-1/2 p-5 mt-8 ml-5 bg-gray-100">
          {showProjectForm && (
            <div className="flex justify-center mt-8">
              <form
                id="projectForm"
                className="w-full max-w-lg"
                onSubmit={(e) => e.preventDefault()}
              >
                <h1 className="flex justify-center mb-5 font-semibold">
                  Project form
                </h1>
                <div className="flex flex-wrap -mx-3">
                  <div className="flex justify-between w-full px-1">
                    <div className="mx-2">
                      <label
                        htmlFor="status_id"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Stage:
                      </label>
                      <select
                        className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        name="stage_id"
                        id="stage_id"
                        value={project.stage_id}
                        onChange={(event) =>
                          handleInputChangesFormProject(event)
                        }
                        ref={project_stage_id}
                      >
                        <option defaultValue value="">
                          Select...
                        </option>
                        {stageInput.map((stage) => (
                          <option key={stage.id} value={stage.id}>
                            {stage.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mx-2">
                      <label
                        htmlFor="status_id"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Status:
                      </label>
                      <select
                        className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        name="status_id"
                        id="status_id"
                        value={project.status_id}
                        onChange={(event) =>
                          handleInputChangesFormProject(event)
                        }
                        ref={project_status_id}
                      >
                        <option defaultValue value="">
                          Select...
                        </option>
                        {statusInput.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-32 mx-2">
                      <label
                        htmlFor="priority"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Priority:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        id="priority"
                        name="priority"
                        type="number"
                        placeholder=""
                        value={project.priority}
                        onChange={(event) =>
                          handleInputChangesFormProject(event)
                        }
                        ref={project_priority}
                      />
                    </div>
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Name:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type an project name"
                      value={project.name}
                      onChange={(event) => handleInputChangesFormProject(event)}
                      ref={project_name}
                    />
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Description:
                    </label>
                    <textarea
                      rows="8"
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="description"
                      name="description"
                      placeholder="Project description"
                      value={project.description}
                      onChange={(event) => handleInputChangesFormProject(event)}
                      ref={project_description}
                    />
                  </div>

                  <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
                    <div className="text-left md:text-center">
                      <label
                        htmlFor="estimated_finish"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Estimated finish:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="estimated_finish"
                        name="estimated_finish"
                        type="date"
                        value={project.estimated_finish}
                        onChange={(event) =>
                          handleInputChangesFormProject(event)
                        }
                        ref={project_estimated_finish}
                      />
                    </div>

                    <div className="text-left md:text-center">
                      <label
                        htmlFor="finished_at"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Finished at:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="finished_at"
                        name="finished_at"
                        type="date"
                        value={project.finished_at}
                        onChange={(event) =>
                          handleInputChangesFormProject(event)
                        }
                        ref={project_finished_at}
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-6 md:mb-0">
                    <input
                      id="user_id"
                      name="user_id"
                      type="hidden"
                      value={loggedUser ? loggedUser.id : ""}
                      onChange={(event) => handleInputChangesFormProject(event)}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    onClick={(event) => createProject(entireObject, event)}
                  >
                    Confirm project
                  </button>
                  <button
                    className="ml-2 px-4 py-2 font-bold text-center text-white bg-gray-500 rounded shadow hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                    onClick={(event) => clearProjectForm(event, "projectForm")}
                  >
                    Clear form
                  </button>
                </div>
              </form>
            </div>
          )}
          {showActivityForm && (
            <div className="flex justify-center mt-8">
              <form
                id="ActivityForm"
                className="w-full max-w-lg"
                onSubmit={onSubmit}
              >
                <h1 className="flex justify-center mb-5 font-semibold">
                  Activity form
                </h1>
                <div className="flex flex-wrap -mx-3">
                  {/* <div className="w-full px-3 mt-3">
                  <label
                    htmlFor="uuid"
                    className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                  >
                    uuid:
                  </label>
                  <input
                    className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                    id="uuid"
                    name="uuid"
                    type="text"
                    disables
                    value={uuidv4()}
                  />
                </div> */}
                  <div className="flex items-center justify-between w-full px-1">
                    <div className="mx-2">
                      <label
                        htmlFor="status_id"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Status:
                      </label>
                      <select
                        className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        name="status_id"
                        id="status_id"
                        value={activity.status_id}
                        onChange={(event) =>
                          handleInputChangesFormActivity(event)
                        }
                        ref={activity_status_id}
                      >
                        <option defaultValue value="">
                          Select...
                        </option>
                        {statusInput.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-32 mx-2">
                      <label
                        htmlFor="priority"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Priority:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        id="priority"
                        name="priority"
                        type="number"
                        placeholder=""
                        value={activity.priority}
                        onChange={(event) =>
                          handleInputChangesFormActivity(event)
                        }
                        ref={activity_priority}
                      />
                    </div>
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Name:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type an project name"
                      value={activity.name}
                      onChange={(event) =>
                        handleInputChangesFormActivity(event)
                      }
                      ref={activity_name}
                    />
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Description:
                    </label>
                    <textarea
                      rows="8"
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="description"
                      name="description"
                      placeholder="Project description"
                      value={activity.description}
                      onChange={(event) =>
                        handleInputChangesFormActivity(event)
                      }
                      ref={activity_description}
                    />
                  </div>

                  <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
                    <div className="text-left md:text-center">
                      <label
                        htmlFor="estimated_finish"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Estimated finish:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="estimated_finish"
                        name="estimated_finish"
                        type="date"
                        value={activity.estimated_finish}
                        onChange={(event) =>
                          handleInputChangesFormActivity(event)
                        }
                        ref={activity_estimated_finish}
                      />
                    </div>

                    <div className="text-left md:text-center">
                      <label
                        htmlFor="finished_at"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Finished at:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="finished_at"
                        name="finished_at"
                        type="date"
                        value={activity.finished_at}
                        onChange={(event) =>
                          handleInputChangesFormActivity(event)
                        }
                        ref={activity_finished_at}
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-6 md:mb-0">
                    <input
                      id="user_id"
                      name="user_id"
                      type="hidden"
                      value={loggedUser ? loggedUser.id : ""}
                      onChange={(event) =>
                        handleInputChangesFormActivity(event)
                      }
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) =>
                      addActivityToTemporalActivitiesArray(event)
                    }
                  >
                    Añadir actividad al proyecto
                  </button>
                  <button
                    className="ml-2 px-4 py-2 font-bold text-center text-white bg-gray-500 rounded shadow hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                    onClick={(event) =>
                      clearActivityForm(event, "activityForm")
                    }
                  >
                    Clear form
                  </button>
                </div>

                {/* <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) => mergeActivitiesWithProject(event)}
                  >
                    Agregar arreglo actividades al objeto project
                  </button>
                </div> */}
              </form>
            </div>
          )}
          {showTaskForm && (
            <div className="flex justify-center mt-8">
              <form className="w-full max-w-lg" onSubmit={onSubmit}>
                <h1 className="flex justify-center mb-5 font-semibold">
                  Task form
                </h1>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="activity_uuid"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Belongs to activity:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="activity_uuid"
                      name="activity_uuid"
                      type="text"
                      disabled
                      value={selectedActivity}
                    />
                  </div>
                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="uuid"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      uuid:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="uuid"
                      name="uuid"
                      type="text"
                      disabled
                      value={uuidv4()}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center justify-between w-full px-1">
                    <div className="mx-2">
                      <label
                        htmlFor="status_id"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Status:
                      </label>
                      <select
                        className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        name="status_id"
                        id="status_id"
                        value={task.status_id}
                        onChange={(event) => handleInputChangesFormTask(event)}
                      >
                        <option defaultValue value="">
                          Select...
                        </option>
                        {statusInput.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-32 mx-2">
                      <label
                        htmlFor="priority"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Priority:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        id="priority"
                        name="priority"
                        type="number"
                        placeholder=""
                        value={task.priority}
                        onChange={(event) => handleInputChangesFormTask(event)}
                      />
                    </div>
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Name:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type an project name"
                      value={task.name}
                      onChange={(event) => handleInputChangesFormTask(event)}
                    />
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Description:
                    </label>
                    <textarea
                      rows="8"
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="description"
                      name="description"
                      placeholder="Project description"
                      value={task.description}
                      onChange={(event) => handleInputChangesFormTask(event)}
                    />
                  </div>

                  <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
                    <div className="text-left md:text-center">
                      <label
                        htmlFor="estimated_finish"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Estimated finish:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="estimated_finish"
                        name="estimated_finish"
                        type="date"
                        value={task.estimated_finish}
                        onChange={(event) => handleInputChangesFormTask(event)}
                      />
                    </div>

                    <div className="text-left md:text-center">
                      <label
                        htmlFor="finished_at"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Finished at:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="finished_at"
                        name="finished_at"
                        type="date"
                        value={task.finished_at}
                        onChange={(event) => handleInputChangesFormTask(event)}
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-6 md:mb-0">
                    <input
                      id="user_id"
                      name="user_id"
                      type="hidden"
                      value={loggedUser ? loggedUser.id : ""}
                      onChange={(event) => handleInputChangesFormTask(event)}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) => addTaskToTemporalTasksArray(event)}
                  >
                    Añadir tarea al arreglo de tareas
                  </button>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) => mergeTasksWithActivity(event)}
                  >
                    Agregar arreglo tareas a la actividad seleccionada (del
                    objeto global)
                  </button>
                </div>
              </form>
            </div>
          )}

          {showSubTaskForm && (
            <div className="flex justify-center mt-8">
              <form className="w-full max-w-lg" onSubmit={onSubmit}>
                <h1 className="flex justify-center mb-5 font-semibold">
                  SubTask form
                </h1>
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="activity_uuid"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Belongs to task:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="activity_uuid"
                      name="activity_uuid"
                      type="text"
                      disabled
                      value={selectedTask}
                    />
                  </div>
                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="uuid"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      uuid:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="uuid"
                      name="uuid"
                      type="text"
                      disabled
                      value={uuidv4()}
                      readOnly
                    />
                  </div>
                  <div className="flex items-center justify-between w-full px-1">
                    <div className="mx-2">
                      <label
                        htmlFor="status_id"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Status:
                      </label>
                      <select
                        className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        name="status_id"
                        id="status_id"
                        value={subtask.status_id}
                        onChange={(event) =>
                          handleInputChangesFormSubTask(event)
                        }
                      >
                        <option defaultValue value="">
                          Select...
                        </option>
                        {statusInput.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-32 mx-2">
                      <label
                        htmlFor="priority"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Priority:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                        id="priority"
                        name="priority"
                        type="number"
                        placeholder=""
                        value={subtask.priority}
                        onChange={(event) =>
                          handleInputChangesFormSubTask(event)
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Name:
                    </label>
                    <input
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Type an project name"
                      value={subtask.name}
                      onChange={(event) => handleInputChangesFormSubTask(event)}
                    />
                  </div>

                  <div className="w-full px-3 mt-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                    >
                      Description:
                    </label>
                    <textarea
                      rows="8"
                      className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                      id="description"
                      name="description"
                      placeholder="Project description"
                      value={subtask.description}
                      onChange={(event) => handleInputChangesFormSubTask(event)}
                    />
                  </div>

                  <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
                    <div className="text-left md:text-center">
                      <label
                        htmlFor="estimated_finish"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Estimated finish:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="estimated_finish"
                        name="estimated_finish"
                        type="date"
                        value={subtask.estimated_finish}
                        onChange={(event) =>
                          handleInputChangesFormSubTask(event)
                        }
                      />
                    </div>

                    <div className="text-left md:text-center">
                      <label
                        htmlFor="finished_at"
                        className="text-xs font-bold tracking-wide text-gray-700 uppercase"
                      >
                        Finished at:
                      </label>
                      <input
                        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                        id="finished_at"
                        name="finished_at"
                        type="date"
                        value={subtask.finished_at}
                        onChange={(event) =>
                          handleInputChangesFormSubTask(event)
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 mb-6 md:mb-0">
                    <input
                      id="user_id"
                      name="user_id"
                      type="hidden"
                      value={loggedUser ? loggedUser.id : ""}
                      onChange={(event) => handleInputChangesFormSubTask(event)}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) =>
                      addSubTaskToTemporalSubTasksArray(event)
                    }
                  >
                    Añadir subtarea al arreglo de subtareas
                  </button>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
                    type="button"
                    onClick={(event) => mergeSubTasksWithTask(event)}
                  >
                    Agregar arreglo subtareas a la tarea seleccionada (del
                    objeto global)
                  </button>
                </div>
              </form>
            </div>
          )}

          <div className="flex justify-center mt-8 ">
            <button
              className="px-4 py-2 ml-4 font-bold text-center text-white bg-green-500 rounded shadow hover:bg-green-400 focus:shadow-outline focus:outline-none"
              onClick={(event) => onSubmit(entireObject, event)}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="w-1/2 p-5 mt-8 ml-5 bg-gray-200">
          <div className="text-3xl font-bold">Project details:</div>
          <div
            className="text-3xl font-bold"
            onClick={(event) => handleProjectSelection(event)}
          >
            {entireObject?.name}
          </div>

          <div className="mt-8 text-2xl font-bold underline">
            Project activities:
          </div>
          <div>
            {entireObject?.activities?.map((activity, index) => (
              <div key={index}>
                <span
                  className="ml-3 text-2xl font-bold"
                  onClick={(event) =>
                    handleActivitySelection(
                      activity.uuid,
                      activity.name,
                      activity.description,
                      event
                    )
                  }
                >
                  {activity.uuid} - {activity.name}
                </span>
                {activity.tasks && (
                  <div className="ml-6 text-xl font-medium underline">
                    Activity tasks:
                  </div>
                )}
                {activity?.tasks?.map((task, index) => (
                  <div key={index}>
                    <span
                      className="ml-8 text-xl font-medium"
                      onClick={(event) =>
                        handleTaskSelection(
                          task.uuid,
                          task.name,
                          task.description,
                          event
                        )
                      }
                    >
                      {task.uuid} - {task.name}
                    </span>
                    {task.subtasks && (
                      <div className="ml-12 text-xl font-medium underline">
                        Task subtasks:
                      </div>
                    )}
                    {task?.subtasks?.map((subtask, index) => (
                      <div key={index}>
                        <span
                          className="ml-12 text-xl font-medium"
                          onClick={(event) =>
                            handleSubTaskSelection(
                              subtask.uuid,
                              subtask.name,
                              subtask.description,
                              event
                            )
                          }
                        >
                          {subtask.uuid} - {subtask.name}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="mt-10 font-bold">Selected activity:</div>
          <div>{selectedActivity}</div>
          <div className="mt-10 font-bold">Selected task:</div>
          <div>{selectedTask}</div>
        </div>
      </div>
      <div className="mt-8 bg-yellow-200">
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Project:</span>{" "}
            {JSON.stringify(project)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Latest activity:</span>{" "}
            {JSON.stringify(activity)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Selected activity:</span>{" "}
            {JSON.stringify(selectedActivity)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">All activities:</span>{" "}
            {JSON.stringify(allActivities)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Latest task:</span>{" "}
            {JSON.stringify(task)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">All tasks:</span>{" "}
            {JSON.stringify(allTasks)}
          </div>
        </div>

        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Latest subtask:</span>{" "}
            {JSON.stringify(subtask)}
          </div>
        </div>
        <div className="flex justify-start">
          <div>
            <span className="text-red-700">All subtasks:</span>{" "}
            {JSON.stringify(allSubTasks)}
          </div>
        </div>

        <div className="flex justify-start">
          <div>
            <span className="text-red-700">Entire Object:</span>{" "}
            {JSON.stringify(entireObject)}
          </div>
        </div>
      </div>
    </>
  ) : (
    <Notification
      icon="fa fa-info-circle"
      background="bg-red-600"
      message={"You doesn't have required permission to create new projects..."}
    />
  );
};

export default CreateOnFlyEntireProject;
