import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import RemoveTaskResponsibleButtonEnabled from "./microcomponents/RemoveTaskResponsibleButtonEnabled";
import RemoveTaskResponsibleButtonDisabled from "./microcomponents/RemoveTaskResponsibleButtonDisabled";

const initialUsersState = [];

const initialTaskResponsiblesState = [];

const initialAuxiliaryPropForForceRefreshState = 1;

const initialSelectedState = {
  task: "",
  users: "",
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const AddUserToTask = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const history = useHistory();

  //https://stackoverflow.com/questions/61573700/react-router-and-this-props-this-props-location-state-is-undefined
  const id = props.location.state.taskId;
  const name = props.location.state.taskName;

  /** Estados para metodo de obtener todos los usuarios */
  const [processingGetUsers, setProcessingGetUsers] = useState(initialProcessingState);
  const [errorGetUsers, setErrorGetUsers] = useState(initialErrorState);
  const [users, setUsers] = useState(initialUsersState);

  /** Estados para metodo de obtener todos los usuarios lideres de proyecto*/
  const [processingGetTaskResponsibles, setProcessingGetTaskResponsibles] = useState(initialProcessingState);
  const [errorFetchingTaskResponsibles, setErrorFetchingTaskResponsibles] = useState(initialErrorState);
  const [taskResponsibles, setTaskResponsibles] = useState(initialTaskResponsiblesState);

  /** Estado para establecer notificacion si no hay datos para mostrar */
  const [empty, setEmpty] = useState(initialEmptyState);

  /** Estado para guardar el lider de proyecto seleccionado por el usuario */
  const [selectedOption, setSelectedOption] = useState(initialSelectedState);

  /** Estado para metodo de guardar los datos en la api */
  const [processingOnSubmit, setProcessingOnSubmit] = useState(initialProcessingState);
  const [errorOnSubmit, setErrorOnSubmit] = useState(initialErrorState);
  const [successOnSubmit, setSuccessOnSubmit] = useState(initialSuccessState);

  /** Estado para forzar el re-renderizado del componente que muestra lideres de proyectos
   * luego de realizar el guardado de datos en la api, y asi evitar que el usuario tenga que
   * refrescar manualmente la pagina. Cambiar el valor de este prop luego de guardar el/los
   * usuarios, hara que useEffect detecte ese cambio y fuerce al componente a que se renderice
   * nuevamente, esto es asi debido a la naturaleza de react.
   * Idea para forzar el renderizado de un componente sacada de aqui: https://medium.com/@heyamberwilkie/force-refreshing-a-react-child-component-the-easy-way-6cdbb9e6d99c */
  const [auxiliaryPropForForceRefresh, setAuxiliaryPropForForceRefresh] = useState(initialAuxiliaryPropForForceRefreshState);

  /** Rutas necesarias para las operaciones de este componente */
  const routeToFetchUsers = "/users";
  const routeToFetchCurrentUsers = "/taskMasters";
  const idTable = "task-masters-table";
  const thisRoute = "/staff-assignment/addUsersToTask";

  useEffect(() => {
    const getUsers = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetUsers({
          processingStatus: true,
          processingMsg: "Loading all users, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchUsers}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setProcessingGetUsers(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetUsers(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setUsers(data.data);
            setProcessingGetUsers(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetUsers(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetUsers({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetUsers(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetUsers({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetUsers(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetUsers({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetUsers(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getTaskResponsibles = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetTaskResponsibles({
          processingStatus: true,
          processingMsg: "Loading task responsibles, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchCurrentUsers}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetTaskResponsibles(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorFetchingTaskResponsibles(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTaskResponsibles(data.data);
            setProcessingGetTaskResponsibles(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorFetchingTaskResponsibles(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorFetchingTaskResponsibles({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetTaskResponsibles(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorFetchingTaskResponsibles({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetTaskResponsibles(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorFetchingTaskResponsibles({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetTaskResponsibles(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if (appUrl !== null && token !== null) {
      getUsers();
      getTaskResponsibles();
    }
  }, [appUrl, token, id, auxiliaryPropForForceRefresh]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(selectedOption),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setErrorOnSubmit(initialErrorState);
      setEmpty(initialEmptyState);
      // Indicar que se ha empezado a procesar la solicitud
      setProcessingOnSubmit({
        processingStatus: true,
        processingMsg: "Saving data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setAuxiliaryPropForForceRefresh(auxiliaryPropForForceRefresh + 1);
        // Indicar en el estado que la peticion se realizo con exito y devolver el mensaje de respuesta de la api
        setSuccessOnSubmit({
          successStatus: true,
          successMsg: data.data.message,
        });
        setProcessingOnSubmit(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setErrorOnSubmit(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setErrorOnSubmit({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessingOnSubmit(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setErrorOnSubmit({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessingOnSubmit(initialProcessingState);
    }
  };

  //https://medium.com/how-to-react/react-select-dropdown-tutorial-using-react-select-51664ab8b6f3
  //https://stackoverflow.com/questions/47672117/react-select-how-to-show-iterate-through-data-from-api-call-in-option-instea
  const options = users.map(function (user) {
    return { value: user.id, label: user.name };
  });

  // https://stackoverflow.com/questions/60519123/passing-value-to-state-using-react-select
  const handleChangeOnField = (event) => {
    setSelectedOption({
      task: id,
      users: event ? event.map((user) => user.value) : [],
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: " ID: ",
        accessor: "id",
        //sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showUserDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/userDetails`,
                  state: {
                    userId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See user details">
                  <span className="px-2 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">{row.original.id}</span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showUserDetails">
              <span className="px-2 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: " Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showUserDetails") ? (
            <Link
              to={{
                pathname: `/userDetails`,
                state: {
                  userId: `${row.original.id}`,
                },
              }}
            >
              <span title="See user details">
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      {
        Header: " Email: ",
        accessor: "email",
        Filter: DefaultColumnFilter,
      },
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showUserDetails") ? (
                <Link
                  to={{
                    pathname: `/userDetails`,
                    state: {
                      userId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See user details">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showUserDetails">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded-l shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-search"></i>
                    </span>
                  </button>
                </span>
              )}

              <div className="inline-flex">
                {CheckPermissionsInsideComponents("changeTaskRelatedUsers") ? (
                  <RemoveTaskResponsibleButtonEnabled taskId={id} userId={row.original.id} rounded={true} />
                ) : (
                  <RemoveTaskResponsibleButtonDisabled rounded={true} />
                )}
              </div>
            </div>
          </>
        ),
      },
    ],
    [id]
  );

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-user-plus"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Add task responsible:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>
      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingGetUsers.processingStatus && (
        <Notification background="bg-teal-400" message={processingGetUsers.processingMsg} />
      )}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con exito la peticion*/}
      {successOnSubmit.successStatus && (
        <Notification icon="fa fa-check-circle" background="bg-green-600" message={successOnSubmit.successMsg} />
      )}

      {processingOnSubmit.processingStatus && (
        <Notification background="bg-teal-400" message={processingOnSubmit.processingMsg} />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorGetUsers.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorGetUsers.errorCode} ${errorGetUsers.errorMsg}`}
        />
      )}

      <div className="flex justify-center mt-8 sm:mt-2">
        <form className="w-full max-w-lg" onSubmit={onSubmit}>
          <div className="text-center">
            <span className="font-semibold">Task name: </span>
            {name}
          </div>
          <input type="hidden" name="project" id="project" readOnly value={selectedOption.task} />
          <Select
            isMulti
            isSearchable
            autoFocus
            components={makeAnimated()}
            noOptionsMessage={() => "No items to select..."}
            placeholder="Select an user or multiple users"
            id="users"
            name="users"
            options={options}
            onChange={(event) => handleChangeOnField(event)}
            className="mt-3"
          />
          <input type="hidden" readOnly value={selectedOption.users} />
          {/* <div>{JSON.stringify(selectedOption)}</div> */}

          <div className="flex justify-center mt-5">
            {selectedOption.users === "" ? (
              <button
                className="px-4 py-2 font-bold text-center text-white bg-gray-500 rounded shadow hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                type="submit"
                disabled={true}
              >
                Submit
              </button>
            ) : (
              <button
                className="px-4 py-2 font-bold text-center text-white bg-green-500 rounded shadow hover:bg-green-400 focus:shadow-outline focus:outline-none"
                type="submit"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>

      <div className="inline-flex items-center mt-8 sm:mt-2">
        <div className="mr-1 text-3xl sm:mr-2">
          <i className="fa fa-street-view"></i>
        </div>
        <div className="">
          <h2 className="text-base font-semibold sm:text-xl">Actual task responsibles:</h2>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingGetTaskResponsibles.processingStatus && (
        <Notification background="bg-teal-400" message={`${processingGetTaskResponsibles.processingMsg}`} />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorFetchingTaskResponsibles.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorFetchingTaskResponsibles.errorCode} ${errorFetchingTaskResponsibles.errorMsg}`}
        />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorOnSubmit.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorOnSubmit.errorCode} ${errorOnSubmit.errorMsg}`}
        />
      )}

      {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
      {empty.emptyStatus ? (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
      ) : (
        <ReactTableComponent id={idTable} columns={columns} data={taskResponsibles} refresh={auxiliaryPropForForceRefresh} />
      )}
    </>
  );
};

export default AddUserToTask;
