import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialStatusState = {
  name: "",
  description: "",
};

const initialStatusRelatedElements = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const StatusRelatedElements = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const history = useHistory();

  //https://stackoverflow.com/questions/61573700/react-router-and-this-props-this-props-location-state-is-undefined
  const id = props.location.state.statusId;
  const name = props.location.state.statusName;

  const [processingGetStatusDetails, setProcessingGetStatusDetails] = useState(initialProcessingState);
  // const [emptyGetStatusDetails, setEmptyGetStatusDetails] = useState(
  //   initialEmptyState
  // );
  // const [errorGetStatusDetails, setErrorGetStatusDetails] = useState(
  //   initialErrorState
  // );
  const [statusDetails, setStatusDetails] = useState(initialStatusState);

  const [processingGetStatusRelatedProjects, setProcessingGetStatusRelatedProjects] = useState(initialProcessingState);
  const [emptyGetStatusRelatedProjects, setEmptyGetStatusRelatedProjects] = useState(initialEmptyState);
  const [errorGetStatusRelatedProjects, setErrorGetStatusRelatedProjects] = useState(initialErrorState);

  const idTableProjects = "status-related-projects-table";
  const [statusRelatedProjects, setStatusRelatedProjects] = useState(initialStatusRelatedElements);

  const [processingGetStatusRelatedActivities, setProcessingGetStatusRelatedActivities] = useState(initialProcessingState);
  const [emptyGetStatusRelatedActivities, setEmptyGetStatusRelatedActivities] = useState(initialEmptyState);
  const [errorGetStatusRelatedActivities, setErrorGetStatusRelatedActivities] = useState(initialErrorState);

  const idTableActivities = "status-related-activities-table";
  const [statusRelatedActivities, setStatusRelatedActivities] = useState(initialStatusRelatedElements);

  const [processingGetStatusRelatedTasks, setProcessingGetStatusRelatedTasks] = useState(initialProcessingState);
  const [emptyGetStatusRelatedTasks, setEmptyGetStatusRelatedTasks] = useState(initialEmptyState);
  const [errorGetStatusRelatedTasks, setErrorGetStatusRelatedTasks] = useState(initialErrorState);

  const idTableTasks = "status-related-tasks-table";
  const [statusRelatedTasks, setStatusRelatedTasks] = useState(initialStatusRelatedElements);

  const [processingGetStatusRelatedSubTasks, setProcessingGetStatusRelatedSubTasks] = useState(initialProcessingState);
  const [emptyGetStatusRelatedSubTasks, setEmptyGetStatusRelatedSubTasks] = useState(initialEmptyState);
  const [errorGetStatusRelatedSubTasks, setErrorGetStatusRelatedSubTasks] = useState(initialErrorState);

  const idTableSubTasks = "status-related-subtasks-table";
  const [statusRelatedSubTasks, setStatusRelatedSubTasks] = useState(initialStatusRelatedElements);

  const routeToGetStatusDetails = "/states";
  const routeToGetStatusRelatedProjects = "/statusRelatedProjects";
  // const routeToGetStatusRelatedActivities = "/allActivitiesWithThisStatus";
  const routeToGetStatusRelatedActivities = "/statusRelatedActivities";
  const routeToGetStatusRelatedTasks = "/statusRelatedTasks";
  const routeToGetStatusRelatedSubTasks = "/statusRelatedSubTasks";

  useEffect(() => {
    const getStatusDetails = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatusDetails({
          processingStatus: true,
          processingMsg: "Loading tag details, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetStatusDetails}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            // setEmptyGetTagDetails({
            //   emptyStatus: true,
            //   emptyCode: response.status,
            //   emptyMsg: data.data.message,
            // });
            setProcessingGetStatusDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            // setErrorGetTagDetails(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusDetails(data.data);
            setProcessingGetStatusDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            // setErrorGetTagDetails(initialErrorState); // Limpiar el estado de errorGetTagRelatedProjects anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          // setErrorGetTagDetails({
          //   errorStatus: true,
          //   errorCode: response.status,
          //   errorMsg: data.error,
          // });
          setProcessingGetStatusDetails(initialProcessingState);
        }

        if (response.status === 500) {
          // setErrorGetTagDetails({
          //   errorStatus: true,
          //   errorCode: response.status,
          //   errorMsg: "Internal Server Error",
          // });
          setProcessingGetStatusDetails(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        // setErrorGetTagDetails({
        //   errorStatus: true,
        //   errorCode: "000",
        //   errorMsg: e,
        // });
        setProcessingGetStatusDetails(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatusRelatedProjects = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatusRelatedProjects({
          processingStatus: true,
          processingMsg: "Loading status related projects, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetStatusRelatedProjects}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetStatusRelatedProjects({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetStatusRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedProjects(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusRelatedProjects(data.data);
            setProcessingGetStatusRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedProjects(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetStatusRelatedProjects({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetStatusRelatedProjects(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetStatusRelatedProjects({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetStatusRelatedProjects(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetStatusRelatedProjects({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetStatusRelatedProjects(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatusRelatedActivities = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatusRelatedActivities({
          processingStatus: true,
          processingMsg: "Loading status related activities, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetStatusRelatedActivities}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetStatusRelatedActivities({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetStatusRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedActivities(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusRelatedActivities(data.data);
            setProcessingGetStatusRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedActivities(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetStatusRelatedActivities({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetStatusRelatedActivities(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetStatusRelatedActivities({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetStatusRelatedActivities(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetStatusRelatedActivities({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetStatusRelatedActivities(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatusRelatedTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatusRelatedTasks({
          processingStatus: true,
          processingMsg: "Loading status related tasks, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetStatusRelatedTasks}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetStatusRelatedTasks({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetStatusRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedTasks(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusRelatedTasks(data.data);
            setProcessingGetStatusRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedTasks(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetStatusRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetStatusRelatedTasks(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetStatusRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetStatusRelatedTasks(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetStatusRelatedTasks({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetStatusRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatusRelatedSubTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatusRelatedSubTasks({
          processingStatus: true,
          processingMsg: "Loading status related subtasks, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetStatusRelatedSubTasks}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetStatusRelatedSubTasks({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetStatusRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedSubTasks(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusRelatedSubTasks(data.data);
            setProcessingGetStatusRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatusRelatedSubTasks(initialErrorState); // Limpiar el estado de errorGetStatusRelatedProjects anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetStatusRelatedSubTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetStatusRelatedSubTasks(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetStatusRelatedSubTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetStatusRelatedSubTasks(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetStatusRelatedSubTasks({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetStatusRelatedSubTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getStatusDetails();
      getStatusRelatedProjects();
      getStatusRelatedActivities();
      getStatusRelatedTasks();
      getStatusRelatedSubTasks();
    }
  }, [appUrl, token, id]);

  const columnsProjects = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showProjectDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/projectDetails/`,
                  state: {
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See project details">
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showProjectDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showProjectDetails") ? (
            <Link
              to={{
                pathname: `/projectDetails/`,
                state: {
                  projectName: `${row.original.name}`,
                  projectId: `${row.original.id}`,
                },
              }}
            >
              <span title="See project details">
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Stage: ",
        accessor: "stage.name",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStageRelatedProjects") ? (
            row.original.stage ? (
              <Link
                to={{
                  pathname: `/stageRelatedProjects/`,
                  state: {
                    stageName: `${row.original.stage.name}`,
                    stageId: `${row.original.stage.id}`,
                  },
                }}
              >
                <span title="See other projects on this stage">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-blue-500 rounded-full shadow sm:text-base hover:bg-blue-400">
                    {row.original.stage.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.stage ? (
            <span title="PERMISSION REQUIRED - showStageRelatedProjects">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.stage.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <span title="Current project status">
              <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showProjectDetails") ? (
                <Link
                  to={{
                    pathname: `/projectDetails/`,
                    state: {
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See project details">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showProjectDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/createActivity/`,
                    state: {
                      projectId: `${row.original.id}`,
                      projectName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Create an activity for this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fas fa-project-diagram"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Create an activity for this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500">
                    <i className="fa fa-project-diagram"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/addUserToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Add an user leader to this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Add an user leader to this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStageToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the stage of this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fas fa-certificate"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the stage of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500">
                    <i className="fa fa-certificate"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToProject/`,
                    state: {
                      projectName: `${row.original.name}`,
                      projectId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the status of this project">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                      <i className="fas fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the status of this project">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-r hover:bg-gray-500">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span>
              )} */}

              {/* <Link
                to={{
                  pathname: `/changeTagsToProject/`,
                  state: {
                    projectName: `${row.original.name}`,
                    projectId: `${row.original.id}`,
                  },
                }}
              >
                <span title="Change the tags of this project">
                  <button className="inline-flex items-center px-3 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r hover:bg-blue-700">
                    <i className="fas fa-tags"></i>
                  </button>
                </span>
              </Link> */}
            </div>
          </>
        ),
      },
    ],
    []
  );

  const columnsActivities = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showActivityDetails") ? (
            row.original.id ? (
              <span title="See activity details">
                <Link
                  to={{
                    pathname: `/activityDetails`,
                    state: {
                      activityId: `${row.original.id}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showActivityDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showActivityDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/activityDetails`,
                  state: {
                    activityId: `${row.original.id}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200"
              >
                <span title="See activity details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <span title="Current activity status">
              <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showActivityDetails") ? (
                <Link
                  to={{
                    pathname: `/activityDetails/`,
                    state: {
                      activityId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See activity details">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded shadow hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showActivityDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded shadow hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/createTask/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Create a task for this activity">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 shadow hover:bg-blue-700">
                      <i className="fas fa-tasks"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Create a task for this activity">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <i className="fas fa-tasks"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/addUserToActivity/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Assign an user manager to this activity">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-600 shadow hover:bg-blue-800">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Assign an user manager to this activity">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToActivity/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Change the current status of this activity">
                    <button className="inline-flex items-center px-4 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r hover:bg-blue-700">
                      <i className="fa fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the current status of this activity">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-r shadow hover:bg-gray-500">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span>
              )} */}
            </div>
          </>
        ),
      },
    ],
    []
  );

  const columnsTasks = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskDetails") ? (
            row.original.id ? (
              <span title="See task details">
                <Link
                  to={{
                    pathname: `/taskDetails`,
                    state: {
                      taskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showTaskDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/taskDetails`,
                  state: {
                    taskId: `${row.original.id}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200 "
              >
                <span title="See task details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <span title="Current task status">
              <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showTaskDetails") ? (
                <Link
                  to={{
                    pathname: `/taskDetails/`,
                    state: {
                      taskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See task details">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showTaskDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded shadow hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/addUserToTask/`,
                    state: {
                      taskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Assign an user responsible of this task">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Assign an user responsible of this task">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToTask/`,
                    state: {
                      taskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the status of this task">
                    <button className="inline-flex items-center px-4 py-2 mr-1 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                      <i className="fa fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the status of this task">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-r shadow hover:bg-gray-500">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span>
              )} */}
            </div>
          </>
        ),
      },
    ],
    []
  );

  const columnsSubTasks = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showSubtaskDetails") ? (
            row.original.id ? (
              <span title="See subtask details">
                <Link
                  to={{
                    pathname: `/subtaskDetails`,
                    state: {
                      subtaskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showSubtaskDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showSubtaskDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/subtaskDetails`,
                  state: {
                    subtaskId: `${row.original.id}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200 "
              >
                <span title="See task details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      /*
            {
              Header: "Description: ",
              accessor: "description",
            },
            */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          row.original.status ? (
            <span title="Current task status">
              <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
            {
              Header: "Created by: ",
              accessor: "owner.name",
            },
            {
              Header: " Updated at: ",
              accessor: "updated_at",
              sortType: "datetime",
            },
            {
              Header: " Deleted at: ",
              accessor: "deleted_at",
            },
            */
      /*Idea para colocar botones de accion sacada de aqui:
            Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
            para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showSubtaskDetails") ? (
                <Link
                  to={{
                    pathname: `/subtaskDetails/`,
                    state: {
                      subtaskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See subtask details">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showSubtaskDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded shadow hover:bg-gray-500">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {/* {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/addUserToSubTask/`,
                    state: {
                      subtaskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Assign an user responsible of this subtask">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Assign an user responsible of this subtask">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("quasi") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToSubTask/`,
                    state: {
                      subtaskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Change the status of this subtask">
                    <button className="inline-flex items-center px-4 py-2 mr-1 font-bold text-white bg-blue-600 rounded-r hover:bg-blue-800">
                      <i className="fa fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - Change the status of this subtask">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-r shadow hover:bg-gray-500">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span>
              )} */}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="flex">
        <div className="inline-flex items-center justify-start w-1/3">
          <div className="mr-1 text-3xl">
            <i className="fa fa-th-list"></i>
          </div>

          <h2 className="ml-1 text-2xl font-semibold">Status:</h2>
        </div>

        <div className="inline-flex items-center justify-center w-1/3 my-5 sm:my-0">
          {/* Ocultar en vista movil / mostrar en vistas sm en adelante */}
          {/* <div className="mt-1 mr-1 text-xl font-semibold text-gray-700">Tag:</div> */}
          <div className="hidden px-3 mt-2 mb-2 text-lg text-white bg-blue-400 rounded-full shadow sm:block">#{name}</div>
          {/* Ocultar en vistas sm en adelante / mostrar en vista movil */}
          {/* <div className="mt-1 mr-1 text-xl font-semibold text-gray-700">Tag:</div> */}
          <div className="block px-2 mt-2 mb-2 text-base text-white bg-blue-400 rounded-full shadow sm:hidden">#{name}</div>
        </div>

        <div className="inline-flex items-center justify-end w-1/3">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      <div className="flex flex-col">
        {processingGetStatusDetails.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetStatusDetails.processingMsg} />
        )}

        {statusDetails.description && (
          <div className="flex justify-start mb-2 sm:my-5">
            <span className="mr-1 text-xl font-medium text-gray-700">
              <span className="mr-1 text-lg font-bold text-gray-700">Description:</span>
              <span className="mr-1 text-lg text-gray-700 font-base">{statusDetails.description}</span>
            </span>
          </div>
        )}

        <div className="inline-flex items-center mt-3 sm:mt-0">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Status related projects:</h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processingGetStatusRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetStatusRelatedProjects.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetStatusRelatedProjects.processingMsg} />
        )}

        {/*Indicar estado relacionado a errorGetStatusRelatedProjects, mostrando mensaje que se ha producido errorGetStatusRelatedProjects en la peticion*/}
        {errorGetStatusRelatedProjects.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetStatusRelatedProjects.errorCode} ${errorGetStatusRelatedProjects.errorMsg}`}
          />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetStatusRelatedProjects.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetStatusRelatedProjects.emptyCode} ${emptyGetStatusRelatedProjects.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent id={idTableProjects} columns={columnsProjects} data={statusRelatedProjects} />
        )}

        {/*Indicar estado relacionado a processingGetStatusRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetStatusRelatedActivities.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetStatusRelatedActivities.processingMsg} />
        )}

        {/*Indicar estado relacionado a errorGetStatusRelatedProjects, mostrando mensaje que se ha producido errorGetStatusRelatedProjects en la peticion*/}
        {errorGetStatusRelatedActivities.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetStatusRelatedActivities.errorCode} ${errorGetStatusRelatedActivities.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center mt-3 sm:mt-0">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-project-diagram"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Status related activities:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetStatusRelatedActivities.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetStatusRelatedActivities.emptyCode} ${emptyGetStatusRelatedActivities.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent id={idTableActivities} columns={columnsActivities} data={statusRelatedActivities} />
        )}

        {/*Indicar estado relacionado a processingGetStatusRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetStatusRelatedTasks.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetStatusRelatedTasks.processingMsg} />
        )}

        {/*Indicar estado relacionado a errorGetStatusRelatedProjects, mostrando mensaje que se ha producido errorGetStatusRelatedProjects en la peticion*/}
        {errorGetStatusRelatedTasks.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetStatusRelatedTasks.errorCode} ${errorGetStatusRelatedTasks.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center mt-3 sm:mt-0">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-tasks"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Status related tasks:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetStatusRelatedTasks.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetStatusRelatedTasks.emptyCode} ${emptyGetStatusRelatedTasks.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent id={idTableTasks} columns={columnsTasks} data={statusRelatedTasks} />
        )}

        {/*Indicar estado relacionado a processingGetStatusRelatedProjects, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetStatusRelatedSubTasks.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetStatusRelatedSubTasks.processingMsg} />
        )}

        {/*Indicar estado relacionado a errorGetStatusRelatedProjects, mostrando mensaje que se ha producido errorGetStatusRelatedProjects en la peticion*/}
        {errorGetStatusRelatedSubTasks.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetStatusRelatedSubTasks.errorCode} ${errorGetStatusRelatedSubTasks.errorMsg}`}
          />
        )}

        <div className="inline-flex items-center mt-3 sm:mt-0">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fa fa-list-ul"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Status related subtasks:</h2>
          </div>
        </div>

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
        {emptyGetStatusRelatedSubTasks.emptyStatus ? (
          <Notification
            background="bg-orange-400"
            message={`${emptyGetStatusRelatedSubTasks.emptyCode} ${emptyGetStatusRelatedSubTasks.emptyMsg}`}
          />
        ) : (
          <ReactTableComponent id={idTableSubTasks} columns={columnsSubTasks} data={statusRelatedSubTasks} />
        )}
      </div>
    </>
  );
};

export default StatusRelatedElements;
