import React, { useState, useEffect, useContext, useCallback } from "react";
//https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router/57619711
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import axios from "axios";
import fileDownload from "js-file-download";
import DeleteActivityMediaButtonEnabled from "./microcomponents/DeleteActivityMediaButtonEnabled";
import DeleteActivityMediaButtonDisabled from "./microcomponents/DeleteActivityMediaButtonDisabled";

const initialMediaState = {
  id: "",
  model_type: "",
  model_id: "",
  uuid: "",
  collection_name: "",
  name: "",
  file_name: "",
  mime_type: "",
  disk: "",
  conversions_disk: "",
  size: "",
  manipulations: [],
  custom_properties: [],
  generated_conversions: [],
  responsive_images: [],
  order_column: "",
  created_at: "",
  updated_at: "",
  model: {
    id: "",
    user_id: "",
    status_id: "",
    priority: "",
    name: "",
    description: "",
    estimated_finish: "",
    finished_at: "",
    deleted_at: "",
    created_at: "",
    updated_at: "",
  },
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img
const acceptedMimeListForImages = [
  "image/apng",
  "image/avif",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "image/bmp",
  "image/x-icon",
  "image/tiff",
];

//https://help.encoding.com/knowledge-base/article/correct-mime-types-for-serving-video-files/
//https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery
const acceptedMimeListForVideos = [
  "video/x-flv",
  "video/mp4",
  "application/x-mpegURL",
  "video/MP2T",
  "video/3gpp",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/ogg",
  "video/webm",
];
//https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery#checking_whether_the_browser_supports_the_supplied_formats
//https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Containers#browser_compatibility
const acceptedMimeListForAudios = [
  "audio/3gpp",
  "audio/aac",
  "audio/flac",
  "audio/mpeg",
  "audio/mp3",
  "audio/mp4",
  "audio/ogg",
  "audio/wav",
  "audio/webm",
];

const ShowActivityRelatedMedia = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.activityId;
  const mediaId = props.location.state.mediaId;
  const collection = props.location.state.collection;
  const [media, setMedia] = useState(initialMediaState);
  const [processingGetMedia, setProcessingGetMedia] = useState(initialProcessingState);
  const [errorGetMedia, setErrorGetMedia] = useState(initialErrorState);
  const [successGetMedia, setSuccessGetMedia] = useState(initialSuccessState);
  const fetchMediaRoute = "files/showActivityRelatedMedia";
  const downloadRoute = "files/downloadMedia";
  const history = useHistory();

  useEffect(() => {
    const getMedia = async () => {
      const fetchConfig = {
        method: "POST",
        body: JSON.stringify({ media: mediaId, collection: collection }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetMedia({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}/${fetchMediaRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setMedia(data.data);
          setSuccessGetMedia({
            successStatus: true,
          });
          setProcessingGetMedia(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setErrorGetMedia(initialErrorState); // Limpiar el estado de errorGetMedia anterior
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetMedia({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.errorGetMedia,
          });
          setProcessingGetMedia(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetMedia({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetMedia(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetMedia({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetMedia(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getMedia();
    }
  }, [appUrl, token, id, mediaId, collection]);

  const dateToYMD = (date) => {
    return new Date(date).toISOString().slice(0, 10);
  };

  //https://www.digitalocean.com/community/tutorials/react-usememo
  const handleDownload = useCallback(
    (e, id, filename) => {
      e.preventDefault();
      // https://stackoverflow.com/a/64002765
      axios
        .get(`${appUrl}/${downloadRoute}/${id}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          fileDownload(res.data, filename);
        });
    },
    [appUrl, token]
  );

  return (
    <>
      <div className="flex flex-col md:inline-flex md:justify-between">
        <div className="flex justify-start md:inline-flex md:items-center">
          <div className="flex items-center justify-center mb-2 mr-1 text-2xl text-gray-800 sm:mr-2">
            <i className="fas fa-file-upload"></i>
            <h2 className="ml-2 text-xl font-semibold">Media details:</h2>
          </div>
        </div>

        <div className="flex justify-end md:inline-flex">
          {CheckPermissionsInsideComponents("downloadActivityMedia") ? (
            <Link
              to={{}}
              onClick={(e) => {
                handleDownload(e, media.id, media.file_name);
              }}
            >
              <span title="Download this media">
                <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-green-500 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-green-400">
                  <i className="fa fa-download"></i>
                  {/* <span className="ml-1">Edit</span> */}
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - downloadActivityMedia">
              <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 rounded-l shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                <i className="fa fa-download"></i>
              </button>
            </span>
          )}

          <div className="inline-flex">
            {CheckPermissionsInsideComponents("deleteActivityRelatedMedia") ? (
              <DeleteActivityMediaButtonEnabled activityId={id} mediaId={media.id} collection={collection} rounded={false} />
            ) : (
              <DeleteActivityMediaButtonDisabled rounded={false} />
            )}
          </div>

          {/* <div className="inline-flex">
            {CheckPermissionsInsideComponents("quasi") ? (
              <DeleteProjectButtonEnabled
                activityId={project.id}
                rounded={false}
              />
            ) : (
              <DeleteProjectButtonDisabled rounded={false} />
            )}
          </div> */}

          <div className="inline-flex">
            <span title="Go back">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-5 py-3 text-sm text-white bg-blue-600 rounded-r shadow sm:px-4 sm:py-2 sm:text-base hover:bg-blue-500"
              >
                <i className="fas fa-arrow-left"></i>
                {/* <span className="ml-1">Back</span> */}
              </button>
            </span>
          </div>
        </div>
      </div>

      {/*Indicar estado relacionado a processingGetMedia, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingGetMedia.processingStatus && (
        <Notification background="bg-teal-400" message={processingGetMedia.processingMsg} />
      )}

      {/*Indicar estado relacionado a errorGetMedia, mostrando mensaje que se ha producido errorGetMedia en la peticion*/}
      {errorGetMedia.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorGetMedia.errorCode} ${errorGetMedia.errorMsg}`}
        />
      )}

      {/*Mostar datos del proyecto*/}
      {successGetMedia.successStatus && (
        <div className="flex flex-col mt-3">
          <div className="grid grid-cols-2 px-4 py-2 text-gray-800 bg-white border border-gray-200 rounded-lg shadow sm:grid-cols-6">
            <div className="py-2 font-semibold">Filename:</div>
            <div className="py-2 font-normal sm:col-span-5">{media.file_name}</div>
            <div className="py-2 font-semibold">Uploaded date:</div>
            <div className="py-2 font-normal sm:col-span-5">{dateToYMD(media.created_at)}</div>
            <div className="py-2 font-semibold">Uploaded by:</div>
            <div className="py-2 font-normal sm:col-span-5">{media.custom_properties.user_name}</div>
            <div className="py-2 font-semibold">Belongs to activity:</div>
            <div className="py-2 font-normal sm:col-span-5">{media.custom_properties.activity_name}</div>
          </div>

          <div className="flex mt-6 justify-center">
            {acceptedMimeListForImages.includes(media.mime_type) && (
              <img
                src={`${media.url}`}
                alt={`${media.name}`}
                className="shadow-lg rounded w-11/12 h-auto align-middle border-none"
              />
            )}

            {acceptedMimeListForVideos.includes(media.mime_type) && (
              <video controls className="w-11/12 sm:w-2/3">
                <source src={`${media.url}`} type={`${media.mime_type}`} />
              </video>
            )}

            {acceptedMimeListForAudios.includes(media.mime_type) && (
              <audio controls className="w-11/12 sm:w-2/3" src={`${media.url}`}></audio>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShowActivityRelatedMedia;
