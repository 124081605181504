import React from "react";
import { useHistory } from "react-router-dom";

const DeleteTagButtonEnabled = ({ tagName, tagId }) => {
  const history = useHistory();

  const yeah = () =>
    //Redireccionar indicando un estado
    //https://stackoverflow.com/questions/44121069/how-to-pass-params-with-history-push-link-redirect-in-react-router-v4
    history.push({
      pathname: "/deleteTag/",
      state: { tagName: { tagName }, tagId: { tagId } },
    });

  /* Boton con confirmacion
    https://gist.github.com/primaryobjects/aacf6fa49823afb2f6ff065790a5b402#gistcomment-2749826 */
  return (
    <span title="Delete tag">
      <button
        className="inline-flex items-center px-4 py-2 font-bold text-white bg-red-600 rounded-r shadow hover:bg-red-500"
        onClick={() =>
          window.confirm("Are you sure you wish to delete this item?") && yeah()
        }
      >
        <i className="fa fa-trash"></i>
      </button>
    </span>
  );
};

export default DeleteTagButtonEnabled;
