import React from "react";
import { Link } from "react-router-dom";

const ShowPermissionButtonEnabled = ({ permissionName, permissionId }) => {
  return (
    <Link
      to={{
        pathname: `/permissionDetails/`,
        state: {
          permissionName: permissionName,
          permissionId: permissionId,
        },
      }}
    >
      <span title="See permission details">
        <button className="shadow inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-400">
          <i className="fas fa-search"></i>
        </button>
      </span>
    </Link>
  );
};

export default ShowPermissionButtonEnabled;
