import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import RemoveActivityStatusButtonEnabled from "./microcomponents/RemoveActivityStatusButtonEnabled";
import RemoveActivityStatusButtonDisabled from "./microcomponents/RemoveActivityStatusButtonDisabled";

const initialStatusState = [];

const initialAuxiliaryPropForForceRefreshState = 1;

const initialSelectedState = {
  activity: "",
  stage: "",
};

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const ChangeStatusToActivity = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const history = useHistory();

  const id = props.location.state.activityId;

  const [processingGetStatus, setProcessingGetStatus] = useState(initialProcessingState);
  const [errorGetStatus, setErrorGetStatus] = useState(initialErrorState);
  const [status, setStatus] = useState(initialStatusState);

  const [processingCurrentActivityStatus, setProcessingCurrentActivityStatus] = useState(initialProcessingState);
  const [errorGetCurrentActivityStatus, setErrorGetCurrentActivityStatus] = useState(initialErrorState);
  const [currentActivityStatus, setCurrentActivityStatus] = useState(initialStatusState);
  const [emptyCurrentActivityStatus, setEmptyCurrentActivityStatus] = useState(initialEmptyState);

  const [selectedOption, setSelectedOption] = useState(initialSelectedState);

  const [processingOnSubmit, setProcessingOnSubmit] = useState(initialProcessingState);
  const [errorOnSubmit, setErrorOnSubmit] = useState(initialErrorState);
  const [successOnSubmit, setSuccessOnSubmit] = useState(initialSuccessState);

  /** Estado para forzar el re-renderizado del componente que muestra lideres de proyectos
   * luego de realizar el guardado de datos en la api, y asi evitar que el usuario tenga que
   * refrescar manualmente la pagina. Cambiar el valor de este prop luego de guardar el/los
   * usuarios, hara que useEffect detecte ese cambio y fuerce al componente a que se renderice
   * nuevamente, esto es asi debido a la naturaleza de react.
   * Idea para forzar el renderizado de un componente sacada de aqui: https://medium.com/@heyamberwilkie/force-refreshing-a-react-child-component-the-easy-way-6cdbb9e6d99c */
  const [auxiliaryPropForForceRefresh, setAuxiliaryPropForForceRefresh] = useState(initialAuxiliaryPropForForceRefreshState);

  const routeToFetchStatus = "/states";
  const routeToGetCurrentActivityStatus = "/activityStatus";
  const routeToSaveStatus = "/states-assignment/changeActivityState";
  const idTable = "current-activity-status-table";

  useEffect(() => {
    const getStatus = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetStatus({
          processingStatus: true,
          processingMsg: "Loading all status, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchStatus}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setProcessingGetStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatus(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatus(data.data);
            setProcessingGetStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetStatus(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetStatus({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetStatus(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetStatus({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetStatus(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetStatus({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getCurrentActivityStatus = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingCurrentActivityStatus({
          processingStatus: true,
          processingMsg: "Loading current activity status, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetCurrentActivityStatus}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyCurrentActivityStatus({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingCurrentActivityStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetCurrentActivityStatus(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setCurrentActivityStatus(data.data);
            setProcessingCurrentActivityStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetCurrentActivityStatus(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetCurrentActivityStatus({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingCurrentActivityStatus(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetCurrentActivityStatus({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingCurrentActivityStatus(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetCurrentActivityStatus({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingCurrentActivityStatus(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getStatus();
      getCurrentActivityStatus();
    }
  }, [appUrl, token, id, auxiliaryPropForForceRefresh]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(selectedOption),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setErrorOnSubmit(initialErrorState); // Asignar el estado user inicial para limpiar los campos del formulario
      setEmptyCurrentActivityStatus(initialEmptyState);
      // Indicar que se ha empezado a procesar la solicitud
      setProcessingOnSubmit({
        processingStatus: true,
        processingMsg: "Saving data, please wait...",
      });

      const response = await fetch(`${appUrl}${routeToSaveStatus}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setAuxiliaryPropForForceRefresh(auxiliaryPropForForceRefresh + 1);
        // Indicar en el estado que la peticion se realizo con exito y devolver el mensaje de respuesta de la api
        setSuccessOnSubmit({
          successStatus: true,
          successMsg: data.data.message,
        });
        setProcessingOnSubmit(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setErrorOnSubmit(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setErrorOnSubmit({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessingOnSubmit(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setErrorOnSubmit({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessingOnSubmit(initialProcessingState);
    }
  };

  //https://medium.com/how-to-react/react-select-dropdown-tutorial-using-react-select-51664ab8b6f3
  //https://stackoverflow.com/questions/47672117/react-select-how-to-show-iterate-through-data-from-api-call-in-option-instea
  const options = status.map(function (item) {
    return { value: item.id, label: item.name };
  });

  // https://stackoverflow.com/questions/60519123/passing-value-to-state-using-react-select
  const handleChangeOnField = (event) => {
    setSelectedOption({
      activity: id,
      state: event.value,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            row.original.id ? (
              <span title="See other projects, activities and tasks with this status">
                <Link
                  to={{
                    pathname: `/statusRelatedElements/`,
                    state: {
                      statusId: `${row.original.id}`,
                      statusName: `${row.original.name}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            <Link
              to={{
                pathname: `/statusRelatedElements/`,
                state: {
                  statusName: `${row.original.name}`,
                  statusId: `${row.original.id}`,
                },
              }}
            >
              <span title="See other projects, activities and tasks with this status">
                <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                  {row.original.name}
                </span>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.name}
              </span>
            </span>
          ),
      },

      {
        Header: "Description: ",
        accessor: "description",
        Filter: DefaultColumnFilter,
        disableFilters: true,
        disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
      },

      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
                <Link
                  to={{
                    pathname: `/statusRelatedElements`,
                    state: {
                      statusId: `${row.original.id}`,
                      statusName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="See other projects, activities and tasks with this status">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600 shadow">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showStatusRelatedElements">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-l hover:bg-gray-500 shadow">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              <div className="inline-flex">
                {CheckPermissionsInsideComponents("changeStatusToActivity") ? (
                  <RemoveActivityStatusButtonEnabled activityId={id} statusId={row.original.id} rounded={true} />
                ) : (
                  <RemoveActivityStatusButtonDisabled rounded={true} />
                )}
              </div>
            </div>
          </>
        ),
      },
    ],
    [id]
  );

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-th-list"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Change status:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>
      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingGetStatus.processingStatus && (
        <Notification background="bg-teal-400" message={processingGetStatus.processingMsg} />
      )}
      {/*Indicar estado relacionado a successOnSubmit, mostrando mensaje que se ha procesado con exito la peticion*/}
      {successOnSubmit.successStatus && (
        <Notification icon="fa fa-check-circle" background="bg-green-600" message={successOnSubmit.successMsg} />
      )}

      {processingOnSubmit.processingStatus && (
        <Notification background="bg-teal-400" message={processingOnSubmit.processingMsg} />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorGetStatus.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorGetStatus.errorCode} ${errorGetStatus.errorMsg}`}
        />
      )}

      <div className="flex justify-center mt-8 sm:mt-2">
        <form className="w-full max-w-lg" onSubmit={onSubmit}>
          <div className="text-center">
            <span className="font-semibold">Activity name: </span>
            {props.location.state.activityName}
          </div>
          <input type="hidden" name="activity" id="activity" readOnly value={selectedOption.activity} />
          <Select
            isSearchable
            autoFocus
            components={makeAnimated()}
            noOptionsMessage={() => "No items to select..."}
            placeholder="Select an status"
            id="status"
            name="status"
            options={options}
            onChange={(event) => handleChangeOnField(event)}
            className="mt-3"
          />
          <input type="hidden" readOnly value={selectedOption.stage} />
          {/* <div>{JSON.stringify(selectedOption)}</div> */}

          <div className="flex justify-center mt-5">
            {selectedOption.stage === "" ? (
              <button
                className="px-4 py-2 font-bold text-center text-white bg-gray-500 rounded shadow hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                type="submit"
                disabled={true}
              >
                Submit
              </button>
            ) : (
              <button
                className="px-4 py-2 font-bold text-center text-white bg-green-500 rounded shadow hover:bg-green-400 focus:shadow-outline focus:outline-none"
                type="submit"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>

      <div className="inline-flex items-center mt-8 sm:mt-2">
        <div className="mr-1 text-3xl sm:mr-2">
          <i className="fa fa-th-list"></i>
        </div>
        <div className="">
          <h2 className="text-base font-semibold sm:text-xl">Actual status:</h2>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingCurrentActivityStatus.processingStatus && (
        <Notification background="bg-teal-400" message={`${processingCurrentActivityStatus.processingMsg}`} />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorGetCurrentActivityStatus.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorGetCurrentActivityStatus.errorCode} ${errorGetCurrentActivityStatus.errorMsg}`}
        />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
      {errorOnSubmit.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorOnSubmit.errorCode} ${errorOnSubmit.errorMsg}`}
        />
      )}

      {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar, en caso que si haya datos, mostrarlos en el componente correspondiente*/}
      {emptyCurrentActivityStatus.emptyStatus ? (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${emptyCurrentActivityStatus.emptyCode} ${emptyCurrentActivityStatus.emptyMsg}`}
        />
      ) : (
        <ReactTableComponent id={idTable} columns={columns} data={currentActivityStatus} refresh={auxiliaryPropForForceRefresh} />
      )}
    </>
  );
};

export default ChangeStatusToActivity;
