import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const ReplyCard = ({ reply, owner }) => {
  const { loggedUser } = useContext(AppContext);
  return (
    <>
      <div className="p-2 mt-2 ml-10 mr-10 border-t border-gray-200 border-t-1 sm:ml-15 sm:mr-15">
        <h1 className="text-base italic font-medium text-gray-700 sm:text-lg">{reply.reply}</h1>

        <div className="flex flex-col mt-3">
          <div className="flex flex-row justify-start -mb-4">
            <Link
              to={{
                pathname: `/userDetails`,
                state: {
                  userId: `${owner.id}`,
                },
              }}
              className="italic text-gray-600 border-b-2 border-gray-300 hover:bg-blue-200"
            >
              {owner.name}
            </Link>
          </div>

          <div className="flex flex-row justify-between">
            <h1 className="pt-4 text-sm italic text-gray-600 align-bottom">{reply.created_at}</h1>

            <div className="inline-flex">
              {CheckPermissionsInsideComponents("editOtherUserComments") || reply?.user_id === loggedUser.id ? (
                <Link
                  to={{
                    pathname: `/editReply`,
                    state: {
                      replyId: `${reply.id}`,
                      replyContent: `${reply.reply}`,
                    },
                  }}
                >
                  <span title="Edit this reply">
                    <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-orange-400 rounded-l-full shadow sm:text-base hover:bg-orange-500">
                      <i className="fa fa-edit"></i>
                      {/* <span className="ml-1">Edit</span> */}
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - editOtherUserComments">
                  <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 rounded-l-full shadow sm:text-base hover:bg-gray-500">
                    <i className="fa fa-edit"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("deleteOtherUserComments") || reply?.user_id === loggedUser.id ? (
                <Link
                  to={{
                    pathname: `/deleteReply`,
                    state: {
                      replyId: `${reply.id}`,
                    },
                  }}
                >
                  <span title="Delete this reply">
                    <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-red-400 rounded-r-full shadow sm:text-base hover:bg-red-500">
                      <i className="fa fa-trash"></i>
                      {/* <span className="ml-1">Delete</span> */}
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - deleteOtherUserComments">
                  <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 rounded-r-full shadow sm:text-base hover:bg-gray-500">
                    <i className="fa fa-trash"></i>
                    {/* <span className="ml-1">Delete</span> */}
                  </button>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyCard;
