import React from "react";
import { Link } from "react-router-dom";

const ShowRoleButtonEnabled = ({ roleName, roleId }) => {
  return (
    <Link
      to={{
        pathname: `/roleDetails/`,
        state: {
          roleId: roleId,
          roleName: roleName,
        },
      }}
    >
      <span title="See role details">
        <button className="shadow inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded-l hover:bg-blue-400">
          <i className="fas fa-search"></i>
        </button>
      </span>
    </Link>
  );
};

export default ShowRoleButtonEnabled;
