import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import ReplyCard from "../replies/ReplyCard";
import { AppContext } from "../../context/AppContext";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: null,
};

const CommentCard = ({ comment, user, setAuxiliaryPropForForceRefresh, auxiliaryPropForForceRefresh }) => {
  const { appUrl, token, loggedUser } = useContext(AppContext);
  const [showOrHideCommentRepliesListState, setShowOrHideCommentRepliesListState] = useState(false);

  //Esta funcion intercambia el estado entre verdadero/falso para mostrar o no la lista de comentarios
  const toggleCommentRepliesList = (e) => {
    //event capturing and event bubbling - stop propagation
    //Hacer que se pueda usar un evento onclick en un div que esta dentro de otro evento onclick
    //https://stackoverflow.com/a/17862666
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    setShowOrHideCommentRepliesListState((showOrHideCommentRepliesListState) => !showOrHideCommentRepliesListState);
  };
  const [showOrHideReplyFormState, setShowOrHideReplyFormState] = useState(false);

  const toggleShowOrHideReplyForm = () => {
    setErrorCreateReply(false);
    setShowOrHideCommentRepliesListState(false);
    setShowOrHideReplyFormState((showOrHideReplyFormState) => !showOrHideReplyFormState);
  };

  const [replyInput, setReplyInput] = useState("");

  const [errorCreateReply, setErrorCreateReply] = useState(initialErrorState);

  const thisRoute = `/replies/reply-comment`;

  const onSubmitCreateReply = async (e) => {
    e.preventDefault();
    const fetchConfig = {
      method: "POST",
      //Funciona de forma estatica y produce {"project":"52","commentInput":"mi comentario"}
      //body: JSON.stringify({ project: id, commentInput: commentInput }),

      //Funciona de forma dinamica y produce {"project":"52","commentInput":"mi comentario"}
      body: JSON.stringify({ comment_id: comment.id, reply: replyInput }), //[model] caracteristica ES6: https://stackoverflow.com/questions/21708652/use-a-variable-in-json-stringify#comment77637292_21708682
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setErrorCreateReply(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud

      // console.log(model);
      // console.log(id);
      // console.log(commentInput);
      // console.log(thisRoute);
      // return;

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);

      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      // Evaluar si hay errores
      if (!data.errors) {
        setAuxiliaryPropForForceRefresh(auxiliaryPropForForceRefresh + 1);

        setErrorCreateReply(initialErrorState); // Limpiar el estado de errorCreateReply anterior
        // const timer = setTimeout(() => {
        //   setShowOrHideCommentFormState(false);
        //   setCommentInput("");
        // }, 2000);
        setShowOrHideReplyFormState(false);
        setReplyInput("");
        toggleCommentRepliesList(true);
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors.reply).forEach(function (errorMsg) {
          error_list.push(data.errors.reply[errorMsg]);
        });

        //Guardar lista de errores en el estado errorCreateReply
        setErrorCreateReply({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setErrorCreateReply({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
    }
  };

  return (
    <>
      <div onClick={(e) => toggleCommentRepliesList(e)} className="p-2 mt-2 border-t border-gray-200 border-b-1">
        <h1 className="text-base italic font-semibold text-gray-600 sm:text-lg">{comment.comment}</h1>

        <div className="flex flex-col mt-3">
          <div className="flex flex-row justify-start -mb-4">
            <Link
              to={{
                pathname: `/userDetails`,
                state: {
                  userId: `${user.id}`,
                },
              }}
              className="italic text-gray-600 border-b-2 border-gray-300 hover:bg-blue-200"
            >
              {user.name}
            </Link>
          </div>

          <div className="flex flex-row justify-between">
            <h1 className="pt-4 text-sm italic text-gray-600 align-bottom">{comment.created_at}</h1>

            <div className="inline-flex">
              {CheckPermissionsInsideComponents("editOtherUserComments") || comment?.user?.id === loggedUser.id ? (
                <Link
                  to={{
                    pathname: `/editComment`,
                    state: {
                      commentId: `${comment.id}`,
                      commentContent: `${comment.comment}`,
                    },
                  }}
                >
                  <span title="Edit this comment">
                    <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-orange-500 rounded-l-full shadow sm:text-base hover:bg-orange-400">
                      <i className="fa fa-edit"></i>
                      {/* <span className="ml-1">Edit</span> */}
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - editOtherUserComments">
                  <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 rounded-l-full shadow sm:text-base hover:bg-gray-500">
                    <i className="fa fa-edit"></i>
                    {/* <span className="ml-1">Edit</span> */}
                  </button>
                </span>
              )}
              {CheckPermissionsInsideComponents("deleteOtherUserComments") || comment?.user?.id === loggedUser.id ? (
                <Link
                  to={{
                    pathname: `/deleteComment`,
                    state: {
                      commentId: `${comment.id}`,
                    },
                  }}
                >
                  <span title="Delete this comment">
                    <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-red-500 shadow sm:text-base hover:bg-red-400">
                      <i className="fa fa-trash"></i>
                      {/* <span className="ml-1">Delete</span> */}
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - deleteOtherUserComments">
                  <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 shadow sm:text-base hover:bg-gray-500">
                    <i className="fa fa-trash"></i>
                    {/* <span className="ml-1">Delete</span> */}
                  </button>
                </span>
              )}

              {comment.replies.length > 0 ? (
                <>
                  {CheckPermissionsInsideComponents("createComment") ? (
                    <span title="Show/hide reply form">
                      <button
                        onClick={toggleShowOrHideReplyForm}
                        className="inline-flex items-center px-3 py-2 text-sm text-white bg-blue-500 shadow sm:text-base hover:bg-blue-400"
                      >
                        <i className="fa fa-comment-alt"></i>
                      </button>
                    </span>
                  ) : (
                    <span title="Show/hide reply form">
                      <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 shadow sm:text-base hover:bg-gray-500">
                        <i className="fa fa-comment-alt"></i>
                      </button>
                    </span>
                  )}

                  <span title="Show/hide replies">
                    <button
                      onClick={toggleCommentRepliesList}
                      className="inline-flex items-center px-3 py-2 text-sm text-white bg-green-500 rounded-r-full shadow sm:text-base hover:bg-green-400"
                    >
                      <i className="fa fa-chevron-down"></i>
                    </button>
                  </span>
                </>
              ) : CheckPermissionsInsideComponents("createComment") ? (
                <span title="Show/hide reply form">
                  <button
                    onClick={toggleShowOrHideReplyForm}
                    className="inline-flex items-center px-3 py-2 text-sm text-white bg-blue-500 rounded-r-full shadow sm:text-base hover:bg-blue-400"
                  >
                    <i className="fa fa-comment-alt"></i>
                  </button>
                </span>
              ) : (
                <span title="Show/hide reply form">
                  <button className="inline-flex items-center px-3 py-2 text-sm text-white bg-gray-400 rounded-r-full shadow sm:text-base hover:bg-gray-500">
                    <i className="fa fa-comment-alt"></i>
                  </button>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*Indicar estado relacionado a errorCreateComment, mostrando mensaje que se ha producido errorCreateComment en la petición*/}
      {errorCreateReply.errorStatus && showOrHideReplyFormState && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-orange-600"
          message={`${errorCreateReply.errorCode} ${errorCreateReply.errorMsg}`}
        />
      )}

      {showOrHideReplyFormState && (
        <div className="grid grid-cols-1 py-2 ml-10 mr-10 text-gray-800 sm:mr-15 sm:ml-15">
          <div className="flex justify-center">
            <h2 className="font-medium text-gray-600">Create a new reply:</h2>
          </div>
          <form className="flex flex-col mt-1">
            <textarea
              className="mt-3 resize-y form-textarea"
              rows="3"
              placeholder="Type your reply"
              onChange={(e) => setReplyInput(e.target.value)}
              value={replyInput}
            />

            <button
              onClick={onSubmitCreateReply}
              className="self-center w-auto px-4 py-2 mt-2 text-sm text-white bg-teal-400 rounded shadow-sm sm:text-base hover:bg-teal-500"
            >
              Submit
            </button>
          </form>
        </div>
      )}

      {showOrHideCommentRepliesListState &&
        comment.replies.map((reply, i) => {
          return <ReplyCard key={i} reply={reply} owner={reply.owner} showOrHideReplyFormState={showOrHideReplyFormState} />;
        })}
    </>
  );
};

export default CommentCard;
