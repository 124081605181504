import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import EditPermissionButtonEnabled from "./microcomponents/EditPermissionButtonEnabled";
import EditPermissionButtonDisabled from "./microcomponents/EditPermissionButtonDisabled";
import DeletePermissionButtonEnabled from "./microcomponents/DeletePermissionButtonEnabled";
import DeletePermissionButtonDisabled from "./microcomponents/DeletePermissionButtonDisabled";
import ShowPermissionButtonDisabled from "./microcomponents/ShowPermissionButtonDisabled";
import ShowPermissionButtonEnabled from "./microcomponents/ShowPermissionButtonEnabled";

const initiaPermissionsState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const Permissions = () => {
  const { appUrl, token } = useContext(AppContext);
  const [permission, setPermission] = useState(initiaPermissionsState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [empty, setEmpty] = useState(initialEmptyState);
  const [success, setSuccess] = useState(initialSuccessState);
  const idTable = "permissions-table";
  const thisRoute = "/security/permissions";

  useEffect(() => {
    const getPermissions = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setPermission(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getPermissions();
    }
  }, [appUrl, token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showPermissionDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/permissionDetails`,
                  state: {
                    permissionId: `${row.original.id}`,
                    permissionName: `${row.original.name}`,
                  },
                }}
              >
                <span title="See permission details">
                  <span className="px-2 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">{row.original.id}</span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showPermissionDetails">
              <span className="px-2 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showPermissionDetails") ? (
            <Link
              to={{
                pathname: `/permissionDetails`,
                state: {
                  permissionId: `${row.original.id}`,
                  permissionName: `${row.original.name}`,
                },
              }}
            >
              <span title="See permission details">
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <div className="inline-flex mt-1 mb-1 mr-1 rounded shadow">
            {CheckPermissionsInsideComponents("showPermissionDetails") ? (
              <ShowPermissionButtonEnabled permissionName={row.original.name} permissionId={row.original.id} />
            ) : (
              <ShowPermissionButtonDisabled />
            )}

            {/* {CheckPermissionsInsideComponents("editPermissionDetails") ? (
              <EditPermissionButtonEnabled permissionName={row.original.name} permissionId={row.original.id} />
            ) : (
              <EditPermissionButtonDisabled />
            )} */}

            {/* {CheckPermissionsInsideComponents("deletePermission") ? (
              <DeletePermissionButtonEnabled permissionName={row.original.name} permissionId={row.original.id} />
            ) : (
              <DeletePermissionButtonDisabled />
            )} */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-lock"></i>
          </div>
          <h2 className="text-xl font-semibold">Permissions list:</h2>
        </div>

        <div className="inline-flex mt-2">
          {/* {CheckPermissionsInsideComponents("createPermission") ? (
            <Link to={"/CreatePermission"}>
              <span title="Create new permission">
                <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-blue-600 rounded-full shadow sm:text-base hover:bg-blue-500">
                  <i className="fa fa-lock"></i>
                  <span className="ml-1">New</span>
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - createPermission">
              <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-gray-400 rounded-full shadow sm:text-base hover:bg-gray-500">
                <i className="fa fa-lock"></i>
                <span className="ml-1">New</span>
              </button>
            </span>
          )} */}
        </div>
      </div>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-500" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={permission} />}
      </div>
    </>
  );
};

export default Permissions;
