import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../reusable/Notification";

const initialDeleteCommentProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialDeleteCommentErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialDeleteCommentSuccessState = {
  successStatus: false,
  successMsg: null,
};

const DeleteReply = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const id = props.location.state.replyId;
  const [processingDeleteComment, setProcessingDeleteComment] = useState(
    initialDeleteCommentProcessingState
  );
  const [errorDeleteComment, setErrorDeleteComment] = useState(
    initialDeleteCommentErrorState
  );
  const [successDeleteComment, setSuccessDeleteComment] = useState(
    initialDeleteCommentSuccessState
  );
  const routeToDeleteComment = "/replies";
  const history = useHistory();

  useEffect(() => {
    const deleteComment = async () => {
      const fetchConfig = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingDeleteComment({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(
          `${appUrl}${routeToDeleteComment}/${id}`,
          fetchConfig
        );
        const data = await response.json();

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.reply) {
            setSuccessDeleteComment({
              successStatus: true,
              successMsg: data.data.message,
            });
            setProcessingDeleteComment(initialDeleteCommentProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorDeleteComment(initialDeleteCommentErrorState); // Limpiar el estado de errorDeleteComment anterior
          } else {
            setProcessingDeleteComment(initialDeleteCommentProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorDeleteComment({
              errorStatus: true,
              errorCode: response.status,
              errorMsg: data.data.message,
            });
          }
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setErrorDeleteComment({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.errorDeleteComment,
          });
          setProcessingDeleteComment(initialDeleteCommentProcessingState);
        }

        if (response.status === 500) {
          setErrorDeleteComment({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingDeleteComment(initialDeleteCommentProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorDeleteComment({
          errorStatus: true,
          errorCode: 0,
          errorMsg: e,
        });
        setProcessingDeleteComment(initialDeleteCommentProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      deleteComment();
    }
  }, [appUrl, token, id]);

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">
              Delete reply:
            </h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.go(-1)} //https://reactrouter.com/web/api/history | go(n) - (function) Moves the pointer in the history stack by n entries
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/*Indicar estado relacionado a processingDeleteComment, mostrando mensaje mientras que se esta procesando la peticion*/}
      {processingDeleteComment.processingStatus && (
        <Notification
          background="bg-teal-400"
          message={processingDeleteComment.processingMsg}
        />
      )}

      {/*Indicar estado relacionado a successDeleteComment, mostrando mensaje que se ha procesado con exito la peticion*/}
      {successDeleteComment.successStatus && (
        <Notification
          icon="fa fa-check-circle"
          background="bg-green-600"
          message={successDeleteComment.successMsg}
        />
      )}

      {/*Indicar estado relacionado a errorDeleteComment, mostrando mensaje que se ha producido errorDeleteComment en la peticion*/}
      {errorDeleteComment.errorStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorDeleteComment.errorCode} ${errorDeleteComment.errorMsg}`}
        />
      )}
    </>
  );
};

export default DeleteReply;
