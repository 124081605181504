import React from "react";
import Notification from "../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../components/reusable/CheckPermissionsInsideComponents";
import Permissions from "./permissions/Permissions";
import Roles from "./roles/Roles";
import Stages from "./stages/Stages";
import Status from "./status/Status";
import Tags from "./tags/Tags";
import Users from "./users/Users";

function General() {
  return (
    <>
      {CheckPermissionsInsideComponents("showUsersList") ? (
        <Users />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-users"></i>
            </div>
            <h2 className="text-xl font-semibold">Users list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showUsersList- to see this area..."}
          />
        </>
      )}

      {CheckPermissionsInsideComponents("showRolesList") ? (
        <Roles />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-user-secret"></i>
            </div>
            <h2 className="text-xl font-semibold">Roles list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showRolesList- to see this area..."}
          />
        </>
      )}

      {CheckPermissionsInsideComponents("showPermissionsList") ? (
        <Permissions />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-lock"></i>
            </div>
            <h2 className="text-xl font-semibold">Permissions list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showPermissionsList- to see this area..."}
          />
        </>
      )}

      {CheckPermissionsInsideComponents("showStagesList") ? (
        <Stages />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-certificate"></i>
            </div>
            <h2 className="text-xl font-semibold">Stages list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showStagesList- to see this area..."}
          />
        </>
      )}

      {CheckPermissionsInsideComponents("showStatusList") ? (
        <Status />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-th-list"></i>
            </div>
            <h2 className="text-xl font-semibold">Status list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showStatusList- to see this area..."}
          />
        </>
      )}

      {CheckPermissionsInsideComponents("showTagsList") ? (
        <Tags />
      ) : (
        <>
          <div className="inline-flex items-center">
            <div className="mr-1 text-3xl sm:mr-2">
              <i className="fa fa-tags"></i>
            </div>
            <h2 className="text-xl font-semibold">Tags list:</h2>
          </div>

          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-500"
            message={"You doesn't have required permission -showTagsList- to see this area..."}
          />
        </>
      )}
    </>
  );
}

export default General;
