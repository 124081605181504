import React from "react";
import { useHistory } from "react-router-dom";
import RoleRelatedPermissions from "./RoleRelatedPermissions";
import RoleRelatedUsers from "./RoleRelatedUsers";

const RoleDetails = (props) => {
  const history = useHistory();
  const id = props.location.state.roleId;
  const name = props.location.state.roleName;
  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-lock"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">
              Assigned permissions:
            </h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      <div className="flex justify-center my-5 sm:my-0">
        <span className="mr-1 text-xl font-medium text-gray-700">Role:</span>
        <span title="Role details">
          <span className="px-3 text-xl text-white bg-blue-400 rounded-full shadow">
            {name}
          </span>
        </span>
      </div>

      <RoleRelatedPermissions id={id} name={name} />
      <RoleRelatedUsers id={id} name={name} />
    </>
  );
};
export default RoleDetails;
