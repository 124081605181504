import { createContext, useContext } from "react";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// PASO #3 - Crear custom hook para consumir el contexto de de autenticacion

// Here we create our next context, as well as a hook for using this context
// called useAuth. We'll come back to this later. As of now, no logic is
// done — it will pull whatever data it finds in the AuthContext. To use our
// new context, we will need to add a provider to react. We'll add this
// provider in the App.js file. While we're doing that, let's change our
// Admin route to use the new PrivateRoute component.
