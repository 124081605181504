import React from "react";

const RemoveSubTaskStatusButtonDisabled = ({ rounded }) => {
  return (
    <span title="PERMISSION REQUIRED - changeStatusToSubtask">
      {rounded ? (
        <button className="inline-flex items-center text-white bg-gray-400 rounded-r shadow px-4 py-2 text-base hover:bg-gray-500 mr-1">
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        <button className="inline-flex items-center text-white bg-gray-400 shadow px-4 py-2 text-base hover:bg-gray-500 mr-1">
          <i className="fa fa-trash"></i>
        </button>
      )}
    </span>
  );
};

export default RemoveSubTaskStatusButtonDisabled;
