//https://thewidlarzgroup.com/react-table-7/ Informacion principal
//https://blog.logrocket.com/building-styling-tables-react-table-v7/ //Informacion de apoyo
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import DeleteUserButtonEnabled from "./microcomponents/DeleteUserButtonEnabled";
import DeleteUserButtonDisabled from "./microcomponents/DeleteUserButtonDisabled";

const initialUsersState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const Users = () => {
  const { appUrl, token } = useContext(AppContext);
  const { loggedUser, setLoggedUser } = useContext(AppContext);
  const [users, setUsers] = useState(initialUsersState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const idTable = "users-table";
  const thisRoute = "/users";

  useEffect(() => {
    const getUsers = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setUsers(data.data);
          setSuccess({
            successStatus: true,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if (appUrl !== null && token !== null) {
      getUsers();
    }
  }, [appUrl, token]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ID: ",
        accessor: "id",
        //sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showUserDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/userDetails`,
                  state: {
                    userId: `${row.original.id}`,
                    userName: `${row.original.name}`,
                  },
                }}
              >
                <span title="See user details">
                  <span className="px-2 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">{row.original.id}</span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showUserDetails">
              <span className="px-2 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: " Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showUserDetails") ? (
            <Link
              to={{
                pathname: `/userDetails/`,
                state: {
                  userId: `${row.original.id}`,
                },
              }}
            >
              <span title="See user details">
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      {
        Header: " Email: ",
        accessor: "email",
        Filter: DefaultColumnFilter,
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: " Verified at: ",
        accessor: "email_verified_at",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            {/*Prueba para hacer click en este boton y mostrar su id en consola: https://stackoverflow.com/questions/61312195/how-to-customize-a-column-in-react-table-7*/}
            {/*<button onClick={() => console.log(row.original.id)}>Button</button>*/}
            {/*Fin de prueba para hacer click en este boton y mostrar su id en consola*/}
            <div className="inline-flex mt-1 mb-1 mr-1 rounded shadow">
              {CheckPermissionsInsideComponents("showUserDetails") ? (
                <Link
                  to={{
                    pathname: `/userDetails/`,
                    state: {
                      userId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See user details">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded-l shadow hover:bg-blue-400">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showUserDetails">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded-l shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-search"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeUserRoles") ? (
                <Link
                  to={{
                    pathname: `/changeUserRoles/`,
                    state: {
                      userId: `${row.original.id}`,
                      userName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Change the current roles of this user">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-600 shadow hover:bg-blue-700">
                      <i className="fa fa-user-secret"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeUserRoles">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-user-secret"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeUserPermissions") ? (
                <Link
                  to={{
                    pathname: `/changeUserPermissions/`,
                    state: {
                      userId: `${row.original.id}`,
                      userName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Change the current permissions of this user">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 shadow hover:bg-blue-400">
                      <i className="fa fa-lock"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeUserPermissions">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-lock"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeOtherUserDetails") || loggedUser?.id === row.original.id ? (
                <Link
                  to={{
                    pathname: `/editUser/`,
                    state: {
                      userId: `${row.original.id}`,
                      userName: `${row.original.name}`,
                      userEmail: `${row.original.email}`,
                    },
                  }}
                >
                  <span title="Edit user">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-orange-500 shadow hover:bg-orange-400">
                      <i className="fa fa-edit"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeOtherUserDetails">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeOtherUserPasswords") || loggedUser?.id === row.original.id ? (
                <Link
                  to={{
                    pathname: `/editUserPassword`,
                    state: {
                      userId: `${row.original.id}`,
                      userName: `${row.original.name}`,
                      userEmail: `${row.original.email}`,
                    },
                  }}
                >
                  <span title="Edit password">
                    <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-yellow-500 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-yellow-400">
                      <i className="fa fa-key"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeOtherUserPasswords">
                  <button className="inline-flex items-center px-5 py-3 text-sm text-white bg-gray-400 shadow sm:px-4 sm:py-2 sm:text-base hover:bg-gray-500">
                    <i className="fa fa-key"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("deleteUser") ? (
                <DeleteUserButtonEnabled rounded={true} userId={row.original.id} />
              ) : (
                <DeleteUserButtonDisabled rounded={true} />
              )}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-users"></i>
          </div>
          <h2 className="text-xl font-semibold">Users list:</h2>
        </div>

        <div className="inline-flex mt-2">
          {CheckPermissionsInsideComponents("createUser") ? (
            <Link to={"/CreateUser"}>
              <span title="Create new user">
                <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-blue-600 rounded-full shadow sm:text-base hover:bg-blue-500">
                  <i className="fa fa-user-plus"></i>
                  <span className="ml-1">New</span>
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - Create new user">
              <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-gray-400 rounded-full shadow sm:text-base hover:bg-gray-500">
                <i className="fa fa-user-plus"></i>
                <span className="ml-1">New</span>
              </button>
            </span>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={users} />}
      </div>
    </>
  );
};

export default Users;
