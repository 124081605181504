import React from "react";

const ShowPermissionButtonDisabled = () => {
  return (
    <span title="PERMISSION REQUIRED - showPermissionDetails">
      <button className="shadow inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded hover:bg-gray-500">
        <span>
          <i className="fa fa-search"></i>
        </span>
      </button>
    </span>
  );
};

export default ShowPermissionButtonDisabled;
