import React from "react";

const Notification = (props) => {
  const { background, message, icon } = props;

  return icon ? (
    <div
      className={`${background} shadow flex items-center text-white text-sm font-semibold px-4 py-3 mb-5 rounded-md mt-2 sm:mt-4 border-t-4 border-teal rounded-b my-2`}
      role="alert"
    >
      <i className={`${icon}`}></i>&nbsp;
      <p>{message}</p>
    </div>
  ) : (
    <div
      className={`${background} shadow flex items-center text-white text-sm font-semibold px-4 py-3 mb-5 rounded-md mt-2 sm:mt-4 border-t-4 border-teal rounded-b my-2`}
      role="alert"
    >
      <i className="fas fa-cog fa-spin"></i>&nbsp;
      <p>{message}</p>
    </div>
  );
};

export default Notification;
