import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialActivitiesState = null;

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const ProjectRelatedActivities = (props) => {
  const { appUrl, token } = useContext(AppContext);
  const [projectRelatedActivities, setProjectRelatedActivities] = useState(initialActivitiesState);
  const [empty, setEmpty] = useState(initialEmptyState);
  const id = props.id;
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const fetchRoute = "/projectActivities";
  const idTable = "project-related-activities-table";

  useEffect(() => {
    const getProjectRelatedActivities = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${fetchRoute}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setProjectRelatedActivities(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getProjectRelatedActivities();
    }
  }, [appUrl, token, id]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        // sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showActivityDetails") ? (
            row.original.id ? (
              <span title="See activity details">
                <Link
                  to={{
                    pathname: `/activityDetails`,
                    state: {
                      activityId: `${row.original.id}`,
                    },
                  }}
                >
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </Link>
              </span>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showActivityDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showActivityDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/activityDetails`,
                  state: {
                    activityId: `${row.original.id}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200"
              >
                <span title="See activity details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            row.original.status ? (
              <Link
                to={{
                  pathname: `/statusRelatedElements/`,
                  state: {
                    statusName: `${row.original.status.name}`,
                    statusId: `${row.original.status.id}`,
                  },
                }}
              >
                <span title="See other projects, activities and tasks with this status">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                    {row.original.status.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.status ? (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-400 rounded-full shadow sm:text-base hover:bg-gray-500">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showActivityDetails") ? (
                <Link
                  to={{
                    pathname: `/activityDetails/`,
                    state: {
                      activityId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See activity details">
                    <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-blue-400 rounded-l hover:bg-blue-600 shadow">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showActivityDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded-l hover:bg-gray-500 shadow">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("createTask") ? (
                <Link
                  to={{
                    pathname: `/createTask/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Create a task for this activity">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 hover:bg-blue-700 shadow">
                      <i className="fas fa-tasks"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - createTask">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500 shadow">
                    <i className="fas fa-tasks"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeActivityRelatedUsers") ? (
                <Link
                  to={{
                    pathname: `/addUserToActivity/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Assign an user manager to this activity">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800 shadow">
                      <i className="fa fa-user-plus"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeActivityRelatedUsers">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500 shadow">
                    <i className="fa fa-user-plus"></i>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("changeStatusToActivity") ? (
                <Link
                  to={{
                    pathname: `/changeStatusToActivity/`,
                    state: {
                      activityId: `${row.original.id}`,
                      activityName: `${row.original.name}`,
                    },
                  }}
                >
                  <span title="Change the current status of this activity">
                    <button className="inline-flex items-center px-4 py-2 mr-1 font-bold text-white bg-blue-500 rounded-r hover:bg-blue-700">
                      <i className="fa fa-th-list"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - changeStatusToActivity">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 hover:bg-gray-500 shadow rounded-r">
                    <i className="fa fa-th-list"></i>
                  </button>
                </span>
              )}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <section id="ProjectRelatedActivities" className="inline-flex justify-between mt-6">
        <div className="inline-flex items-center text-gray-800">
          <div className="mr-1 text-xl sm:mr-2">
            <i className="fas fa-project-diagram"></i>
          </div>
          <h2 className="text-xl font-semibold">Project activities:</h2>
        </div>
      </section>

      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-400" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={projectRelatedActivities} />}
      </div>
    </>
  );
};

export default ProjectRelatedActivities;
