import React, { createContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import useWindowDimensions from "../hooks/useWindowDimensions";

const initialLoggedUserState = null;
const initialLoggedUserPermissionsState = [];

// https://www.youtube.com/watch?v=35lXWvCuM8o (Forma de usar el context aqui)
// https://www.youtube.com/watch?v=bdn-K4a2S2w (Adicional para entender el context)
export const AppContext = createContext();

export const AppProvider = (props) => {
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [versionCommit, setVersionCommit] = useState("");
  const [versionDate, setVersionDate] = useState("");
  const [versionBranch, setVersionBranch] = useState("");

  const [appUrl, setAppUrl] = useState(null);
  const { token } = useAuth();

  //Estado para evitar re-renderizado y loop infinito en dependencias de useEffect
  const [temporalLoggedUserID, setTemporalLoggedUserID] = useState(null);

  const [loggedUser, setLoggedUser] = useState(initialLoggedUserState);

  const [loggedUserPermissions, setLoggedUserPermissions] = useState(initialLoggedUserPermissionsState);
  const routeToGetDetailsOfAuthenticatedUser = "/security/authentication/user";
  const routeToGetPermissionsOfAuthenticatedUser = "/security/acl/show-user-permissions";

  useEffect(() => {
    setAppUrl(process.env.REACT_APP_URL);
    //Obtener commit actual del sistema en local
    //https://dev.to/nastassiadanilova/add-commit-hash-Commit-to-your-reaCommitct-app-n3n
    // console.log(process.env.REACT_APP_COMMIT_HASH.slice(0, 7));
    setVersionCommit(process.env.REACT_APP_COMMIT_HASH);
    setVersionBranch(process.env.REACT_APP_COMMIT_BRANCH); //https://stackoverflow.com/a/6245587
    setVersionDate(process.env.REACT_APP_COMMIT_DATE); //https://stackoverflow.com/a/19812608

    const getLoggedUser = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(`${appUrl}${routeToGetDetailsOfAuthenticatedUser}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      await setLoggedUser(data?.data?.user);
      await setTemporalLoggedUserID(data?.data?.user?.id);
    };

    const getLoggedUserPermissions = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(`${appUrl}${routeToGetPermissionsOfAuthenticatedUser}/${temporalLoggedUserID}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      if (response.status === 200) {
        if (data.data.message) {
          setLoggedUserPermissions(initialLoggedUserPermissionsState);
        } else {
          setLoggedUserPermissions(data.data.permissions);
        }
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if (appUrl !== null && token !== null) {
      getLoggedUser();
    }

    // Si hay datos esperados en el .env, ejecuta la accion
    if (temporalLoggedUserID !== null) {
      getLoggedUserPermissions();
    }
  }, [appUrl, token, temporalLoggedUserID]);

  return (
    <AppContext.Provider
      value={{
        windowHeight,
        windowWidth,
        versionBranch,
        versionCommit,
        versionDate,
        appUrl,
        token,
        loggedUser,
        setLoggedUser,
        temporalLoggedUserID,
        loggedUserPermissions,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
