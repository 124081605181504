//rafce
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import CommentCard from "./CommentCard";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: null,
};

const initialProjectRelatedCommentsState = null;

const initialGetProjectRelatedCommentsErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialProjectRelatedCommentsSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialProjectRelatedCommentsEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const initialAuxiliaryPropForForceRefreshState = 1;

const CreateCommentForm = ({ model, id }) => {
  const { appUrl, token } = useContext(AppContext);
  const [errorCreateComment, setErrorCreateComment] = useState(initialErrorState);
  const [processingCreateComment, setProcessingCreateComment] = useState(initialProcessingState);
  const [commentInput, setCommentInput] = useState("");
  const [ShowOrHideCommentFormState, setShowOrHideCommentFormState] = useState(false);
  const [auxiliaryPropForForceRefresh, setAuxiliaryPropForForceRefresh] = useState(initialAuxiliaryPropForForceRefreshState);

  const thisRoute = `/comments/add-comment-${model}`;

  const [projectRelatedComments, setProjectRelatedComments] = useState(initialProjectRelatedCommentsState);
  const [emptyGetProjectRelatedComments, setEmptyGetProjectRelatedComments] = useState(initialProjectRelatedCommentsEmptyState);

  const [errorGetProjectRelatedComments, setErrorGetProjectRelatedComments] = useState(
    initialGetProjectRelatedCommentsErrorState
  );
  const [successGetProjectRelatedComments, setSuccessGetProjectRelatedComments] = useState(
    initialProjectRelatedCommentsSuccessState
  );

  const [ShowOrHideCommentListState, setShowOrHideCommentListState] = useState(false);

  const routeToGetModelRelatedComments = `/${model}Comments`;

  //Intercambiar un estado entre verdadero/falso
  //https://stackoverflow.com/a/59631911
  const toggleCommentForm = () => {
    setEmptyGetProjectRelatedComments(false);
    setErrorCreateComment(false);
    setShowOrHideCommentFormState((ShowOrHideCommentFormState) => !ShowOrHideCommentFormState);
  };

  const toggleCommentList = () => setShowOrHideCommentListState((ShowOrHideCommentListState) => !ShowOrHideCommentListState);

  const onSubmit = async (e) => {
    e.preventDefault();
    const fetchConfig = {
      method: "POST",
      //Funciona de forma estatica y produce {"project":"52","commentInput":"mi comentario"}
      //body: JSON.stringify({ project: id, commentInput: commentInput }),

      //Funciona de forma dinamica y produce {"project":"52","commentInput":"mi comentario"}
      body: JSON.stringify({ [model]: id, comment: commentInput }), //[model] caracteristica ES6: https://stackoverflow.com/questions/21708652/use-a-variable-in-json-stringify#comment77637292_21708682
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setErrorCreateComment(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessingCreateComment({
        processingStatus: true,
        processingMsg: "Sending data, please wait...",
      });

      // console.log(model);
      // console.log(id);
      // console.log(commentInput);
      // console.log(thisRoute);
      // return;

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);

      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      // Evaluar si hay errores
      if (!data.errors) {
        setAuxiliaryPropForForceRefresh(auxiliaryPropForForceRefresh + 1);

        setProcessingCreateComment(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setErrorCreateComment(initialErrorState); // Limpiar el estado de errorCreateComment anterior
        // const timer = setTimeout(() => {
        //   setShowOrHideCommentFormState(false);
        //   setCommentInput("");
        // }, 2000);
        setShowOrHideCommentFormState(false);
        setCommentInput("");
        toggleCommentList(true);
      } else {
        setProcessingCreateComment(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada

        //Guardar lista de errores en el estado errorCreateComment
        setErrorCreateComment({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: data.errors.comment,
        });

        setProcessingCreateComment(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setErrorCreateComment({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessingCreateComment(initialProcessingState);
    }
  };

  useEffect(() => {
    const getProjectRelatedComments = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await fetch(`${appUrl}${routeToGetModelRelatedComments}/${id}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetProjectRelatedComments({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });

            setErrorGetProjectRelatedComments(initialGetProjectRelatedCommentsErrorState); // Limpiar el estado de errorGetProjectRelatedComments anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setProjectRelatedComments(data.data);
            setSuccessGetProjectRelatedComments({
              successStatus: true,
            });

            setErrorGetProjectRelatedComments(initialGetProjectRelatedCommentsErrorState); // Limpiar el estado de errorGetProjectRelatedComments anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetProjectRelatedComments({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.errorGetProjectRelatedComments,
          });
        }

        if (response.status === 500) {
          setErrorGetProjectRelatedComments({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetProjectRelatedComments({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getProjectRelatedComments();
    }
  }, [appUrl, token, id, auxiliaryPropForForceRefresh, routeToGetModelRelatedComments]);

  return (
    <>
      <div className="inline-flex justify-between mt-6 text-gray-800">
        <div className="inline-flex items-center mr-1 text-2xl sm:mr-2">
          {/* Verificar si hay permiso para comentar: project:createComment, activity:createComment o task:createComment */}

          <span title="Clic to see reports">
            <button onClick={toggleCommentList} className="inline-flex items-center">
              <i className="fa fa-comments"></i>
              <span className="ml-2 text-xl font-semibold">Progress reports:</span>
            </button>
          </span>
        </div>

        <div>
          {/* Verificar si hay permiso para comentar: project:createComment, activity:createComment o task:createComment */}
          {CheckPermissionsInsideComponents("createComment") ? (
            <span className="inline-flex items-center text-2xl">
              <span title="Show/hide comment form">
                <button
                  onClick={toggleCommentForm}
                  className="inline-flex items-center px-3 text-sm text-white bg-blue-600 rounded-l-lg shadow sm:text-base hover:bg-blue-700"
                >
                  <span className="pt-1 text-xl">
                    <i className="fa fa-comment-medical"></i>
                  </span>
                </button>
              </span>
            </span>
          ) : (
            <span className="inline-flex items-center text-2xl">
              <span title="REQUIRED PERMISSION - createComment">
                <button className="inline-flex items-center px-3 text-sm text-white bg-gray-400 rounded-l-lg shadow sm:text-base hover:bg-gray-500">
                  <span className="pt-1 text-xl">
                    <i className="fa fa-comment-medical"></i>
                  </span>
                </button>
              </span>
            </span>
          )}

          {CheckPermissionsInsideComponents("showProjectReports") ? (
            <span className="inline-flex items-center text-2xl">
              <span title="Show/hide comment list">
                <button
                  onClick={toggleCommentList}
                  className="inline-flex items-center px-4 text-sm text-white bg-green-500 rounded-r-lg shadow sm:text-base hover:bg-green-600"
                >
                  <span className="pt-1 text-xl">
                    <i className="fa fa-angle-double-down"></i>
                  </span>
                </button>
              </span>
            </span>
          ) : (
            <span className="inline-flex items-center text-2xl">
              <span title="REQUIRED PERMISSION - showProjectReports">
                <button className="inline-flex items-center px-4 text-sm text-white bg-gray-400 rounded-r-lg shadow sm:text-base hover:bg-gray-500">
                  <span className="pt-1 text-xl">
                    <i className="fa fa-angle-double-down"></i>
                  </span>
                </button>
              </span>
            </span>
          )}
        </div>
      </div>

      {/*Indicar estado relacionado a processingCreateComment, mostrando mensaje mientras que se esta procesando la petición*/}
      {processingCreateComment.processingStatus && ShowOrHideCommentFormState && (
        <Notification background="bg-teal-400" message={processingCreateComment.processingMsg} />
      )}

      {/*Indicar estado relacionado a successCreateComment, mostrando mensaje que se ha procesado con éxito la petición*/}
      {/* {successCreateComment.successStatus && ShowOrHideCommentFormState && (
        <Notification
          icon="fa fa-check-circle"
          background="bg-green-600"
          message={successCreateComment.successMsg}
        />
      )} */}

      {/*Indicar estado relacionado a errorCreateComment, mostrando mensaje que se ha producido errorCreateComment en la petición*/}
      {errorCreateComment.errorStatus && ShowOrHideCommentFormState && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-red-600"
          message={`${errorCreateComment.errorCode} ${errorCreateComment.errorMsg}`}
        />
      )}

      {ShowOrHideCommentFormState && (
        <div className="grid grid-cols-1 px-4 py-2 mt-2 text-gray-800 bg-white border-t border-gray-200 shadow-xs border-t-1">
          <div className="flex justify-center mt-2">
            <h2 className="font-medium text-gray-600 ">Create a new comment:</h2>
          </div>
          <form className="flex flex-col mt-1">
            <textarea
              className="mt-3 resize-y form-textarea"
              rows="3"
              placeholder="Type your comment..."
              onChange={(e) => setCommentInput(e.target.value)}
              value={commentInput}
            />

            <button
              onClick={onSubmit}
              className="self-center w-auto px-4 py-2 mt-2 text-sm text-white bg-teal-400 rounded shadow-sm sm:text-base hover:bg-teal-500"
            >
              <span className="font-semibold">Submit</span>
            </button>
          </form>
        </div>
      )}

      {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
      {emptyGetProjectRelatedComments.emptyStatus && (
        <Notification
          icon="fa fa-info-circle"
          background="bg-orange-400"
          message={`${emptyGetProjectRelatedComments.emptyCode} ${emptyGetProjectRelatedComments.emptyMsg}`}
        />
      )}

      {ShowOrHideCommentListState && (
        <>
          <div className="flex flex-col">
            {/*Indicar estado relacionado a processingGetProjectRelatedComments, mostrando mensaje mientras que se esta procesando la peticion*/}
            {/* {processingGetProjectRelatedComments.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processingGetProjectRelatedComments.processingMsg}
          />
        )} */}

            {/*Indicar estado relacionado a errorGetProjectRelatedComments, mostrando mensaje que se ha producido errorGetProjectRelatedComments en la peticion*/}
            {errorGetProjectRelatedComments.errorStatus && (
              <Notification
                icon="fa fa-info-circle"
                background="bg-red-600"
                message={`${errorGetProjectRelatedComments.errorCode} ${errorGetProjectRelatedComments.errorMsg}`}
              />
            )}

            {/*Mostar tabla pasandole los props requeridos*/}
            {successGetProjectRelatedComments.successStatus && (
              <div className="grid grid-cols-1 px-4 py-2 mt-4 text-gray-700 bg-white border border-gray-200 rounded-lg shadow">
                <div className="inline-flex justify-center mt-3 mb-3">
                  <h2 className="font-medium text-gray-700">Report history:</h2>
                </div>

                {projectRelatedComments.map((comment, i) => {
                  return (
                    <CommentCard
                      key={i}
                      comment={comment}
                      user={comment.user}
                      auxiliaryPropForForceRefresh={auxiliaryPropForForceRefresh}
                      setAuxiliaryPropForForceRefresh={setAuxiliaryPropForForceRefresh}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CreateCommentForm;
