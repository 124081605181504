import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import ReactToExcel from "react-html-table-to-excel";

const initialActivitiesState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const Activities = () => {
  const { appUrl, token } = useContext(AppContext);
  const [activities, setActivities] = useState(initialActivitiesState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const idTable = "projects-table";
  const thisRoute = "/activities";

  useEffect(() => {
    const getActivities = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading...",
        });

        const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          setActivities(data.data);
          setSuccess({
            successStatus: true,
          });
          setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
          setError(initialErrorState); // Limpiar el estado de error anterior
        }

        if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422
        ) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getActivities();
    }
  }, [appUrl, token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        // sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
      },
      {
        Header: "Name: ",
        accessor: "name",
      },
      {
        Header: "Description: ",
        accessor: "description",
      },
      {
        Header: "Status: ",
        accessor: "status.name",
      },
      {
        Header: "Priority: ",
        accessor: "priority",
      },
      {
        Header: "Stage: ",
        accessor: "stage.name",
      },
      /*
      {
        Header: " Created at: ",
        accessor: "created_at",
        sortType: "datetime",
      },
      */
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        sortType: "datetime",
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        sortType: "datetime",
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="flex">
              <div className="w-1/3 ml-1 mr-2">
                <button className="flex text-base shadow bg-green-500 hover:bg-green-600 text-white py-1 px-1 rounded">
                  <div className="w-1/12 mr-2 mt-1">
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="w-11/12">
                    <Link to={`${thisRoute}/${row.original.id}`}>Details</Link>
                  </div>
                </button>
              </div>

              <div className="w-1/3">
                <button className="flex text-base shadow bg-orange-500 hover:bg-orange-600 text-white py-1 px-1 rounded">
                  <div className="w-1/12 mr-2 mt-1">
                    <i className="fa fa-pencil"></i>
                  </div>
                  <div className="w-11/12">
                    <Link to={`EditActivity/${row.original.id}`}>Edit</Link>
                  </div>
                </button>
              </div>

              <div className="w-1/3 -ml-3">
                <button className="flex text-base shadow bg-red-500 hover:bg-red-600 text-white py-1 px-1 rounded">
                  <div className="w-1/12 mr-2 mt-1">
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="w-11/12">
                    <Link to={`DeleteActivity/${row.original.id}`}>Delete</Link>
                  </div>
                </button>
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-5">
        <div className="flex">
          <div className="mr-1 sm:mr-2">
            <i className="fa fa-users"></i>
          </div>
          <div>
            <h2 className="text-xl text-center font-semibold">
              Activities list:
            </h2>
          </div>
        </div>

        <div className="flex flex-row">
          <button className="flex items-center text-base shadow bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-1 sm:mr-2">
            <div>
              <i className="fa fa-users"></i>
            </div>
            <div>
              <Link to={"/CreateProject"}>New</Link>
            </div>
          </button>
          {/*https://www.youtube.com/watch?v=ZRjzV_1VNPE*/}
          <div className="flex items-center text-base shadow bg-gray-500 hover:bg-gray-600 text-white font-bold p-2 sm:px-4 rounded">
            <div>
              <i className="fa fa-file"></i>
            </div>
            <div>
              <ReactToExcel
                table={idTable}
                filename="listOfActivities"
                sheet="Sheet 1"
                buttonText="Export"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && (
          <Notification
            background="bg-teal-400"
            message={processing.processingMsg}
          />
        )}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${error.errorCode} ${error.errorMsg}`}
          />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && (
          <ReactTableComponent
            id={idTable}
            columns={columns}
            data={activities}
          />
        )}
      </div>
    </>
  );
};

export default Activities;
