import React from "react";
import { useHistory } from "react-router-dom";

const DeleteUserButtonEnabled = ({ userId, rounded }) => {
  const history = useHistory();

  const yeah = () =>
    //Redireccionar indicando un estado
    //https://stackoverflow.com/questions/44121069/how-to-pass-params-with-history-push-link-redirect-in-react-router-v4
    history.push({
      pathname: "/deleteUser/",
      state: { userId: { userId } },
    });

  /* Boton con confirmacion
    https://gist.github.com/primaryobjects/aacf6fa49823afb2f6ff065790a5b402#gistcomment-2749826 */
  return (
    <span title="Delete user">
      {rounded ? (
        <button
          className="inline-flex items-center px-5 py-3 font-bold text-white bg-red-600 rounded-r shadow hover:bg-red-500 sm:px-4 sm:py-2 sm:text-base"
          onClick={() =>
            window.confirm("Are you sure you wish to delete this item?") &&
            yeah()
          }
        >
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        <button
          className="inline-flex items-center px-5 py-3 font-bold text-white bg-red-600 shadow hover:bg-red-500 sm:px-4 sm:py-2 sm:text-base"
          onClick={() =>
            window.confirm("Are you sure you wish to delete this item?") &&
            yeah()
          }
        >
          <i className="fa fa-trash"></i>
        </button>
      )}
    </span>
  );
};

export default DeleteUserButtonEnabled;
