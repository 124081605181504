import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Notification from "../../components/reusable/Notification";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialProjectState = {
  stage_id: "",
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
};

const initialStageInputState = [];
const initialStatusInputState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
  successCode: null,
};

const CreateProject = () => {
  const { appUrl, token, loggedUser } = useContext(AppContext);
  const [project, setProject] = useState(initialProjectState);
  const [statusInput, setStatusInput] = useState(initialStatusInputState);
  const [stageInput, setStageInput] = useState(initialStageInputState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const thisRoute = "/projects";
  const history = useHistory();

  useEffect(() => {
    const getStage = async () => {
      const routeToFetchStages = "/stages";
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchStages}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al usuario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStageInput(data.data);
            /*setSuccess({
              successStatus: true,
            });*/
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getStatus = async () => {
      const routeToFetchStatus = "/states";
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchStatus}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al usuario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusInput(data.data);
            /*setSuccess({
              successStatus: true,
            });*/
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getStage();
      getStatus();
    }
  }, [appUrl, token]);

  const { register, errors, handleSubmit } = useForm();

  //Guardar los datos del estado project en la api
  const onSubmit = async (project, event) => {
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(project),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setError(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessing({
        processingStatus: true,
        processingMsg: "Loading data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setProject(initialProjectState); // Asignar el estado project inicial para limpiar los campos del formulario
        // Indicar en el estado que la petición se realizo con éxito y devolver el mensaje de respuesta de la api
        setSuccess({
          successStatus: true,
          successMsg: data.data.message,
          successCode: response.status,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessing(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setError({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessing(initialProcessingState);
    }
  };

  //Guardar los datos introducidos en el formulario en el estado project
  const handleChangeOnField = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    setProject({
      ...project, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value, //Asignar en cada propiedad del estado project, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });
  };

  //Si el usuario tiene el permiso indicado por parametro, se mostrara el contenido de este componente en caso contrario, sera redireccionado a una pagina de notificacion
  return CheckPermissionsInsideComponents("createProject") ? (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-sitemap"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Create new project:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/* Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la petición */}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con éxito la petición*/}
      {success.successStatus && (
        <Notification
          icon="fa fa-check-circle"
          background={success.successCode === 401 ? "bg-red-600" : "bg-green-600"}
          message={success.successMsg}
        />
      )}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la petición*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}
      <div className="flex justify-center mt-8">
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap -mx-3">
            <div className="flex justify-between w-full px-1">
              <div className="mx-2">
                <label htmlFor="status_id" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Stage:
                </label>
                <select
                  className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  name="stage_id"
                  id="stage_id"
                  value={project.stage_id}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                >
                  <option defaultValue value="">
                    Select...
                  </option>
                  {stageInput.map((stage) => (
                    <option key={stage.id} value={stage.id} ref={register}>
                      {stage.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mx-2">
                <label htmlFor="status_id" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Status:
                </label>
                <select
                  className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  name="status_id"
                  id="status_id"
                  value={project.status_id}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                >
                  <option defaultValue value="">
                    Select...
                  </option>
                  {statusInput.map((status) => (
                    <option key={status.id} value={status.id} ref={register}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-32 mx-2">
                <label htmlFor="priority" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Priority:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  id="priority"
                  name="priority"
                  type="number"
                  placeholder=""
                  value={project.priority}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
                {/* Error de validación */}
                {errors.priority && <div className="text-xs text-red-500">{errors.priority.message}</div>}
              </div>
            </div>

            <div className="w-full px-3 mt-3">
              <label htmlFor="name" className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                Name:
              </label>
              <input
                className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                id="name"
                name="name"
                type="text"
                placeholder="Type an project name"
                value={project.name}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required. ",
                  },
                })}
                onChange={(event) => handleChangeOnField(event)}
              />
              {/*Error de validación*/}
              {errors.name && <div className="text-xs text-red-500">{errors.name.message}</div>}
            </div>

            <div className="w-full px-3 mt-3">
              <label htmlFor="description" className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                Description:
              </label>
              <textarea
                rows="8"
                className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                id="description"
                name="description"
                placeholder="Project description"
                value={project.description}
                ref={register}
                onChange={(event) => handleChangeOnField(event)}
              />
              {/*Error de validación*/}
              {errors.description && <div className="text-xs text-red-500">{errors.description.message}</div>}
            </div>

            <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
              <div className="text-left md:text-center">
                <label htmlFor="estimated_finish" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Estimated finish:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                  id="estimated_finish"
                  name="estimated_finish"
                  type="date"
                  value={project.estimated_finish}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
              </div>

              <div className="text-left md:text-center">
                <label htmlFor="finished_at" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Finished at:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                  id="finished_at"
                  name="finished_at"
                  type="date"
                  value={project.finished_at}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
              </div>
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <input
                id="user_id"
                name="user_id"
                type="hidden"
                value={loggedUser ? loggedUser.id : ""}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required. ",
                  },
                })}
                onChange={(event) => handleChangeOnField(event)}
              />
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  ) : (
    <Notification
      icon="fa fa-info-circle"
      background="bg-red-600"
      message={"You doesn't have required permission -createProject- to create new projects..."}
    />
  );
};

export default CreateProject;
