import React from "react";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();
  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex"></div>

        <div className="inline-flex">
          <div className="inline-flex">
            <span title="Go back">
              <button
                onClick={() => history.goBack()}
                className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
              >
                <i className="fas fa-arrow-left"></i>
                <span className="ml-1">Back</span>
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="ml-2 mt-16 text-6xl text-red-600 sm:text-6xl sm:mt-20">
          <i className="fas fa-meteor"></i>
        </div>

        <div>
          <h1 className="text-red-500 text-md sm:text-lg">Auch!!</h1>
        </div>

        <div>
          <h1 className="mt-3 text-xl text-red-600 sm:text-3xl sm:mt-5">
            {"<> "}
            Page not found
            {" </>"}
          </h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;
