import React from "react";
import { Link } from "react-router-dom";

const EditRoleButtonEnabled = ({ roleName, roleId }) => {
  return (
    <Link
      to={{
        pathname: `/editRole`,
        state: {
          roleId: { roleId },
          roleName: { roleName },
        },
      }}
    >
      <span title="Edit role">
        <button className="shadow inline-flex items-center px-4 py-2 font-bold text-white bg-orange-400 hover:bg-orange-300">
          <i className="fa fa-edit"></i>
        </button>
      </span>
    </Link>
  );
};

export default EditRoleButtonEnabled;
