import React from "react";
import { useHistory } from "react-router-dom";

const RemoveRoleFromUserButtonEnabled = ({ userId, roleId, rounded }) => {
  const history = useHistory();

  const yeah = () =>
    //Redireccionar indicando un estado
    //https://stackoverflow.com/questions/44121069/how-to-pass-params-with-history-push-link-redirect-in-react-router-v4
    history.push({
      pathname: "/removeRoleFromUser/",
      state: { userId: { userId }, roleId: { roleId } },
    });

  /* Boton con confirmacion
    https://gist.github.com/primaryobjects/aacf6fa49823afb2f6ff065790a5b402#gistcomment-2749826 */
  return (
    <span title="Remove this role from this user">
      {rounded ? (
        <button
          className="inline-flex items-center text-white bg-red-600 rounded-r shadow px-4 py-2 text-base hover:bg-red-500 mr-1"
          onClick={() =>
            window.confirm(
              "Are you sure you wish to remove this role from this user?"
            ) && yeah()
          }
        >
          <i className="fa fa-trash"></i>
        </button>
      ) : (
        <button
          className="inline-flex items-center text-white bg-red-600 shadow px-4 py-2 text-base hover:bg-red-500 mr-1"
          onClick={() =>
            window.confirm(
              "Are you sure you wish to remove this role from this user?"
            ) && yeah()
          }
        >
          <i className="fa fa-trash"></i>
        </button>
      )}
    </span>
  );
};

export default RemoveRoleFromUserButtonEnabled;
