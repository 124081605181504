// PASO #5 - Formulario para login de usuarios
import React, { useState, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { AppContext } from "../../context/AppContext";

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: null,
};

function LoginForm() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(initialErrorState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken } = useAuth();
  const { appUrl } = useContext(AppContext);
  const [temporalLoggedUserID, setTemporalLoggedUserID] = useState(null);

  const thisRoute = "/security/authentication/login";

  const onSubmit = async (e) => {
    e.preventDefault();
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      setError(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessing({
        processingStatus: true,
        processingMsg: "Sending data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);

      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.data.errors) {
        setToken(data.data.access_token); // Asignar el estado project inicial para limpiar los campos del formulario
        setTemporalLoggedUserID(data.data.user.id);
        // Indicar en el estado que la petición se realizo con éxito y devolver el mensaje de respuesta de la api
        setIsLoggedIn(true);
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      } else {
        // let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        // //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        // Object.keys(data.errors).forEach(function (errorMsg) {
        //   error_list.push(data.errors[errorMsg]);
        // });

        //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: data.data.errors,
        });

        setProcessing(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setError({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessing(initialProcessingState);
    }
  };

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `/userDetails`,
          state: {
            userId: `${temporalLoggedUserID}`,
          },
        }}
      />
    );
  }

  return (
    <div className="container flex items-center justify-center mx-auto mt-8">
      <div className="w-full sm:w-1/2 md:w-2/5 lg:w-3/12">
        <div className="p-8 mt-8 bg-white border-t-8 border-blue-500 rounded-lg shadow-md">
          <div className="flex justify-center w-full -mt-2 text-gray-700 font-bold">Please login:</div>
          <form className="mt-4 mb-0">
            <div>
              <label className="block mb-2 font-bold text-gray-600">Email:</label>
              <input
                type="email"
                className="w-full form-input"
                placeholder="Your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>

            <div className="mt-4">
              <label className="block mb-2 font-bold text-gray-600">Password:</label>
              <input
                type="password"
                className="w-full form-input"
                placeholder="Your password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="flex items-center justify-center mt-6 -mb-4">
              <button onClick={onSubmit} className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-600">
                Login
              </button>
            </div>
          </form>

          {processing && (
            <div className="flex items-center justify-center mt-6 mb-0 font-thin text-teal-500">{processing.processingMsg}</div>
          )}
          {error && <div className="flex items-center justify-center mb-0 font-thin text-red-500">{error.errorMsg}</div>}

          {/* <div className="flex items-center justify-between">
              <button className="px-4 py-2 font-bold text-white bg-teal-500 rounded hover:bg-teal-700">
                Login
              </button>

              <a
                className="inline-block float-right text-sm font-bold text-blue-500 no-underline align-baseline hover:text-blue-700"
                href="/"
              >
                Forgot Password?
              </a>
            </div> */}
        </div>

        {/* <div className="mt-6 text-center">
          <p className="text-xs text-gray-800">Testing username / password:</p>
          <p className="text-gray-700">visitante@ejemplo.tld / visitante</p>
        </div> */}

        {/* <div className="text-center">
            <p className="text-sm text-gray-600">
              Don't have an account?{" "}
              <a href="/" className="font-bold no-underline text-blue">
                Create an Account
              </a>
              .
            </p>
          </div> */}
      </div>
    </div>
  );
}

export default LoginForm;
