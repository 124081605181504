import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

const initialTasksState = null;

const initialAuxiliaryPropForForceRefreshState = 1;

const initialTaskState = {
  status_id: "",
  priority: "",
  name: "",
  description: "",
  estimated_finish: "",
  finished_at: "",
};

const initialStatusInputState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const CreateTask = (props) => {
  const { appUrl, token, loggedUser } = useContext(AppContext);
  const activityId = props.location.state.activityId;
  const activityName = props.location.state.activityName;
  const [task, setTask] = useState(initialTaskState);
  const [statusInput, setStatusInput] = useState(initialStatusInputState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [success, setSuccess] = useState(initialSuccessState);
  const { register, errors, handleSubmit } = useForm();
  const thisRoute = "/tasks";
  const history = useHistory();

  const [auxiliaryPropForForceRefresh, setAuxiliaryPropForForceRefresh] = useState(initialAuxiliaryPropForForceRefreshState);
  const idTable = "activity-related-tasks-table";
  const routeToGetActivityRelatedTasks = "/activityTasks";
  const [processingGetActivityRelatedTasks, setProcessingGetActivityRelatedTasks] = useState(initialProcessingState);
  const [emptyGetActivityRelatedTasks, setEmptyGetActivityRelatedTasks] = useState(initialEmptyState);
  const [errorGetActivityRelatedTasks, setErrorGetActivityRelatedTasks] = useState(initialErrorState);
  const [successGetActivityRelatedTasks, setSuccessGetActivityRelatedTasks] = useState(initialSuccessState);
  const [activityRelatedTasks, setActivityRelatedTasks] = useState(initialTasksState);

  useEffect(() => {
    const getStatus = async () => {
      const routeToFetchStatus = "/states";
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToFetchStatus}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al usuario.
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setStatusInput(data.data);
            /*setSuccess({
              successStatus: true,
            });*/
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    const getActivityRelatedTasks = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessingGetActivityRelatedTasks({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${routeToGetActivityRelatedTasks}/${activityId}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmptyGetActivityRelatedTasks({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessingGetActivityRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetActivityRelatedTasks(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setActivityRelatedTasks(data.data);
            setSuccessGetActivityRelatedTasks({
              successStatus: true,
            });
            setProcessingGetActivityRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setErrorGetActivityRelatedTasks(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setErrorGetActivityRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessingGetActivityRelatedTasks(initialProcessingState);
        }

        if (response.status === 500) {
          setErrorGetActivityRelatedTasks({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessingGetActivityRelatedTasks(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setErrorGetActivityRelatedTasks({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessingGetActivityRelatedTasks(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };

    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getStatus();
      getActivityRelatedTasks();
    }
  }, [appUrl, token, activityId, auxiliaryPropForForceRefresh]);

  //Guardar los datos del estado project en la api
  const onSubmit = async (task, event) => {
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify(task),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setError(initialErrorState); // Asignar el estado project inicial para limpiar los campos del formulario
      // Indicar que se ha empezado a procesar la solicitud
      setProcessing({
        processingStatus: true,
        processingMsg: "Loading data, please wait...",
      });

      const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
      const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

      //Evaluar si hay errores
      if (!data.errors) {
        setAuxiliaryPropForForceRefresh(auxiliaryPropForForceRefresh + 1);
        setTask(initialTaskState); // Asignar el estado project inicial para limpiar los campos del formulario
        // Indicar en el estado que la petición se realizo con éxito y devolver el mensaje de respuesta de la api
        setSuccess({
          successStatus: true,
          successMsg: data.data.message,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
        setError(initialErrorState); // Limpiar el estado de error anterior
      } else {
        let error_list = []; //Variable temporal donde almacenar todos los errores obtenidos

        //Recorrer el objeto de error y almacenar cada item en la variable temporal https://stackoverflow.com/a/31096661
        Object.keys(data.errors).forEach(function (errorMsg) {
          error_list.push(data.errors[errorMsg]);
        });

        //Guardar lista de errores en el estado error
        setError({
          errorStatus: true,
          errorCode: response.status,
          errorMsg: error_list,
        });

        setProcessing(initialProcessingState);
      }
    } catch (e) {
      // Capturar errores estandar de la api fetch y guardar en el estado
      setError({
        errorStatus: true,
        errorCode: "000",
        errorMsg: e,
      });
      setProcessing(initialProcessingState);
    }
  };

  //Guardar los datos introducidos en el formulario en el estado project
  const handleChangeOnField = (event) => {
    const { name, value } = event.target; //Destructurar el objeto evento que se recibe
    setTask({
      ...task, //Copiar el estado actual, el cual tiene todas sus propiedades con cadena de texto vacia
      [name]: value, //Asignar en cada propiedad del estado project, el campo/valor que corresponda segun los cambios que se reciben desde el formulario
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        // sortType: "basic",
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/taskDetails`,
                  state: {
                    taskId: `${row.original.id}`,
                  },
                }}
              >
                <span title="See task details">
                  <span className="px-2 py-1 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">
                    {row.original.id}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showTaskDetails">
              <span className="px-2 py-1 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTaskDetails") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/taskDetails`,
                  state: {
                    taskId: `${row.original.id}`,
                  },
                }}
                className="border-b-2 border-gray-400 hover:bg-blue-200 "
              >
                <span title="See task details">{row.original.name}</span>
              </Link>
            ) : (
              ""
            )
          ) : (
            row.original.name
          ),
      },
      /*
      {
        Header: "Description: ",
        accessor: "description",
      },
      */
      {
        Header: "Status: ",
        accessor: "status.name",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showStatusRelatedElements") ? (
            row.original.status ? (
              <Link
                to={{
                  pathname: `/statusRelatedElements/`,
                  state: {
                    statusName: `${row.original.status.name}`,
                    statusId: `${row.original.status.id}`,
                  },
                }}
              >
                <span title="See other projects, activities and tasks with this status">
                  <span className="inline-flex items-center px-2 text-sm text-white bg-teal-400 rounded-full shadow sm:text-base hover:bg-teal-300">
                    {row.original.status.name}
                  </span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : row.original.status ? (
            <span title="PERMISSION REQUIRED - showStatusRelatedElements">
              <span className="inline-flex items-center px-2 text-sm text-white bg-gray-500 rounded-full shadow sm:text-base hover:bg-gray-600">
                {row.original.status.name}
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        Header: "Priority: ",
        accessor: "priority",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
      },
      {
        Header: " Created at: ",
        accessor: "created_at",
        Filter: DefaultColumnFilter,
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
      },
      {
        Header: "Estimated finish: ",
        accessor: "estimated_finish",
        Filter: DefaultColumnFilter,
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
      },
      {
        Header: "Finished at: ",
        accessor: "finished_at",
        Filter: DefaultColumnFilter,
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
      },
      /*
      {
        Header: "Created by: ",
        accessor: "owner.name",
      },
      {
        Header: " Updated at: ",
        accessor: "updated_at",
        sortType: "datetime",
      },
      {
        Header: " Deleted at: ",
        accessor: "deleted_at",
      },
      */
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1">
              {CheckPermissionsInsideComponents("showTaskDetails") ? (
                <Link
                  to={{
                    pathname: `/taskDetails/`,
                    state: {
                      taskId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="See task details">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-400 rounded hover:bg-blue-600">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showTaskDetails">
                  <button className="inline-flex items-center px-3 py-2 font-bold text-white bg-gray-400 rounded hover:bg-gray-500 shadow">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              )}
            </div>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fas fa-tasks"></i>
          </div>
          <div className="">
            <h2 className="text-base font-semibold sm:text-xl">Create new task:</h2>
          </div>
        </div>

        <div className="inline-flex">
          <span title="Go back">
            <button
              onClick={() => history.goBack()}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-blue-600 rounded-full sm:text-base hover:bg-blue-500"
            >
              <i className="fas fa-arrow-left"></i>
              <span className="ml-1">Back</span>
            </button>
          </span>
        </div>
      </div>

      {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la petición*/}
      {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

      {/*Indicar estado relacionado a success, mostrando mensaje que se ha procesado con éxito la petición*/}
      {success.successStatus && <Notification icon="fa fa-check-circle" background="bg-green-600" message={success.successMsg} />}

      {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la petición*/}
      {error.errorStatus && (
        <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
      )}

      <div className="flex justify-center mt-8">
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap -mx-3">
            <div className="flex justify-between w-full px-1">
              <input type="hidden" name="activity_id" id="activity_id" value={activityId} ref={register} />
              <div className="mx-2">
                <label htmlFor="status_id" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  For Activity:
                </label>
                <input
                  className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  name="activity_name"
                  id="activity_name"
                  value={activityName}
                  disabled
                />
              </div>

              <div className="mx-2">
                <label htmlFor="status_id" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Status:
                </label>
                <select
                  className="block w-full px-4 py-3 pr-8 mt-1 text-xs leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  name="status_id"
                  id="status_id"
                  value={task.status_id}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                >
                  <option defaultValue value="">
                    Select...
                  </option>
                  {statusInput.map((status) => (
                    <option key={status.id} value={status.id} ref={register}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-32 mx-2">
                <label htmlFor="priority" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Priority:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs focus:outline-none focus:bg-white"
                  id="priority"
                  name="priority"
                  type="number"
                  placeholder=""
                  value={task.priority}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
                {/*Error de validación*/}
                {errors.priority && <div className="text-xs text-red-500">{errors.priority.message}</div>}
              </div>
            </div>

            <div className="w-full px-3 mt-3">
              <label htmlFor="name" className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                Name:
              </label>
              <input
                className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                id="name"
                name="name"
                type="text"
                placeholder="Type an project name"
                value={task.name}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required. ",
                  },
                })}
                onChange={(event) => handleChangeOnField(event)}
              />
              {/*Error de validación*/}
              {errors.name && <div className="text-xs text-red-500">{errors.name.message}</div>}
            </div>

            <div className="w-full px-3 mt-3">
              <label htmlFor="description" className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase">
                Description:
              </label>
              <textarea
                rows="8"
                className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                id="description"
                name="description"
                placeholder="Project description"
                value={task.description}
                ref={register}
                onChange={(event) => handleChangeOnField(event)}
              />
              {/*Error de validación*/}
              {errors.description && <div className="text-xs text-red-500">{errors.description.message}</div>}
            </div>

            <div className="flex justify-between w-full px-3 mt-3 md:justify-evenly">
              <div className="text-left md:text-center">
                <label htmlFor="estimated_finish" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Estimated finish:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                  id="estimated_finish"
                  name="estimated_finish"
                  type="date"
                  value={task.estimated_finish}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
              </div>

              <div className="text-left md:text-center">
                <label htmlFor="finished_at" className="text-xs font-bold tracking-wide text-gray-700 uppercase">
                  Finished at:
                </label>
                <input
                  className="block w-full px-4 py-3 mt-1 leading-tight text-gray-500 bg-gray-100 border border-gray-300 rounded shadow-xs appearance-none focus:outline-none focus:bg-white"
                  id="finished_at"
                  name="finished_at"
                  type="date"
                  value={task.finished_at}
                  ref={register}
                  onChange={(event) => handleChangeOnField(event)}
                />
              </div>
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <input
                id="user_id"
                name="user_id"
                type="hidden"
                value={loggedUser ? loggedUser.id : ""}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required. ",
                  },
                })}
                onChange={(event) => handleChangeOnField(event)}
              />
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 font-bold text-center text-white bg-blue-500 rounded shadow hover:bg-blue-400 focus:shadow-outline focus:outline-none"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div className="inline-flex justify-between mt-6 sm:mt-0">
        <div className="inline-flex items-center text-gray-800">
          <div className="mr-1 text-2xl sm:mr-2">
            <i className="fas fa-tasks"></i>
          </div>
          <div className="">
            <h2 className="text-xl font-semibold">Actual activity tasks:</h2>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processingGetActivityRelatedTasks.processingStatus && (
          <Notification background="bg-teal-400" message={processingGetActivityRelatedTasks.processingMsg} />
        )}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {errorGetActivityRelatedTasks.errorStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-red-600"
            message={`${errorGetActivityRelatedTasks.errorCode} ${errorGetActivityRelatedTasks.errorMsg}`}
          />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {emptyGetActivityRelatedTasks.emptyStatus && (
          <Notification
            icon="fa fa-info-circle"
            background="bg-orange-400"
            message={`${emptyGetActivityRelatedTasks.emptyCode} ${emptyGetActivityRelatedTasks.emptyMsg}`}
          />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {successGetActivityRelatedTasks.successStatus && (
          <ReactTableComponent
            id={idTable}
            columns={columns}
            data={activityRelatedTasks}
            refresh={auxiliaryPropForForceRefresh}
          />
        )}
      </div>
    </>
  );
};

export default CreateTask;
