import React from "react";

const EditRoleButtonDisabled = () => {
  return (
    <span title="PERMISSION REQUIRED - editRoleDetails">
      <button className="shadow inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 hover:bg-gray-500">
        <span>
          <i className="fa fa-edit"></i>
        </span>
      </button>
    </span>
  );
};

export default EditRoleButtonDisabled;
