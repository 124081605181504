import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Notification from "../../components/reusable/Notification";
import ReactTableComponent from "../../components/reusable/react-table/ReactTableComponent";
import { DefaultColumnFilter } from "../../components/reusable/react-table/ReactTableFilter";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";
import DeleteTagButtonEnabled from "./microcomponents/DeleteTagButtonEnabled";
import DeleteTagButtonDisabled from "./microcomponents/DeleteTagButtonDisabled";

const initialTagState = [];

const initialProcessingState = {
  processingStatus: false,
  processingMsg: null,
};

const initialErrorState = {
  errorStatus: false,
  errorCode: null,
  errorMsg: [],
};

const initialSuccessState = {
  successStatus: false,
  successMsg: null,
};

const initialEmptyState = {
  emptyStatus: false,
  emptyCode: null,
  emptyMsg: [],
};

const Tag = () => {
  const { appUrl, token, windowWidth } = useContext(AppContext);
  const [tags, setTag] = useState(initialTagState);
  const [processing, setProcessing] = useState(initialProcessingState);
  const [error, setError] = useState(initialErrorState);
  const [empty, setEmpty] = useState(initialEmptyState);
  const [success, setSuccess] = useState(initialSuccessState);
  const idTable = "tags-table";
  const thisRoute = "/tags";

  useEffect(() => {
    const getTags = async () => {
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setProcessing({
          processingStatus: true,
          processingMsg: "Loading data, please wait...",
        });

        const response = await fetch(`${appUrl}${thisRoute}`, fetchConfig);
        const data = await response.json(); //Convertir respuesta a JSON para que deje de ser una promesa y poder leer su contenido

        //Evaluar respuestas del servidor para establecer mensajes
        if (response.status === 200) {
          if (data.data.message) {
            //Si en la respuesta hay una propiedad llamada "message", es que estamos recibiendo un mensaje avisando que no hay datos registrados,
            //entonces guardamos ese mensaje en un estado para mostrar esa notificacion al ususario.
            setEmpty({
              emptyStatus: true,
              emptyCode: response.status,
              emptyMsg: data.data.message,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          } else {
            //Si en la respuesta NO hay una propiedad llamada "message", es que estamos recibiendo los datos existentes en la propiedad "data",
            //entonces guardamos esos datos en el estado para mostrar los resultados obtenidos al usuario.
            setTag(data.data);
            setSuccess({
              successStatus: true,
            });
            setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
            setError(initialErrorState); // Limpiar el estado de error anterior
          }
        }

        if (response.status === 401 || response.status === 404 || response.status === 422) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: data.error,
          });
          setProcessing(initialProcessingState);
        }

        if (response.status === 500) {
          setError({
            errorStatus: true,
            errorCode: response.status,
            errorMsg: "Internal Server Error",
          });
          setProcessing(initialProcessingState);
        }
      } catch (e) {
        // Capturar errores estandar de la api fetch y guardar en el estado
        setError({
          errorStatus: true,
          errorCode: "000",
          errorMsg: e,
        });
        setProcessing(initialProcessingState); // Indicar en el estado que ya no se esta procesando nada
      }
    };
    // Si hay datos esperados en el .env, ejecuta la accion
    if ((appUrl !== null) & (token !== null)) {
      getTags();
    }
  }, [appUrl, token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID: ",
        accessor: "id",
        disableFilters: true, //Deshabilitar la funcion de filtrar en esta columna
        //disableSortBy: true, //Deshabilitar la funcion de ordenar en esta columna
        //sortType: "basic",
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTagRelatedElements") ? (
            row.original.id ? (
              <Link
                to={{
                  pathname: `/tagRelatedElements`,
                  state: {
                    tagId: `${row.original.id}`,
                    tagName: `${row.original.name}`,
                  },
                }}
              >
                <span title="Show projects, activities and tasks with this tag">
                  <span className="px-2 font-semibold text-white bg-blue-400 shadow hover:bg-blue-500">{row.original.id}</span>
                </span>
              </Link>
            ) : (
              ""
            )
          ) : (
            <span title="PERMISSION REQUIRED - showTagRelatedElements">
              <span className="px-2 font-semibold text-white bg-gray-500 shadow hover:bg-gray-600">{row.original.id}</span>
            </span>
          ),
      },
      {
        Header: "Name: ",
        accessor: "name",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTagRelatedElements") ? (
            <Link
              to={{
                pathname: `/tagRelatedElements/`,
                state: {
                  tagName: `${row.original.name}`,
                  tagId: `${row.original.id}`,
                },
              }}
            >
              <span
                className="inline-flex"
                // title="See other projects on this stage"
                title={`${row.original.description} - Clic to show projects, activities and tasks with this tag`}
              >
                <span className="border-b-2 border-gray-400 hover:bg-blue-200">{row.original.name}</span>
              </span>
            </Link>
          ) : (
            row.original.name
          ),
      },
      {
        Header: "Description: ",
        accessor: "description",
        Filter: DefaultColumnFilter,
        Cell: ({ row }) =>
          CheckPermissionsInsideComponents("showTagRelatedElements") ? (
            <Link
              to={{
                pathname: `/tagRelatedElements/`,
                state: {
                  tagName: `${row.original.name}`,
                  tagId: `${row.original.id}`,
                },
              }}
            >
              {windowWidth <= 640 ? (
                <span className="inline-flex" title="Clic to show projects, activities and tasks with this tag">
                  <span className="hover:bg-blue-200">
                    {row.original.description?.length > 25
                      ? `${row.original.description.substr(0, 50)}...`
                      : row.original.description}
                  </span>
                </span>
              ) : (
                <span className="inline-flex" title="Clic to show projects, activities and tasks with this tag">
                  <span className="hover:bg-blue-200">{row.original.description}</span>
                </span>
              )}
            </Link>
          ) : // Recortar largo de las cadenas de texto a mostrar en el select
          row.original.description?.length > 25 ? (
            `${row.original.description.substr(0, 50)}...`
          ) : (
            // Mostar el contenido sin enlace
            row.original.description
          ),
      },
      /*Idea para colocar botones de accion sacada de aqui:
      Nótese que a parte de cambiar botones por enlaces, tambien agregué row en forma de objeto
      para la opcion Cell): https://stackoverflow.com/a/49077181*/
      {
        Header: " Actions: ",
        Cell: ({ row }) => (
          <>
            <div className="inline-flex mt-1 mb-1 mr-1 rounded shadow">
              {CheckPermissionsInsideComponents("showTagRelatedElements") ? (
                <Link
                  to={{
                    pathname: `/tagRelatedElements/`,
                    state: {
                      tagName: `${row.original.name}`,
                      tagId: `${row.original.id}`,
                    },
                  }}
                >
                  <span title="Show projects, activities and tasks with this tag">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-blue-500 rounded-l shadow hover:bg-blue-400">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - showTagRelatedElements">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 rounded-l shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-search"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("editTagDetails") ? (
                <Link
                  to={{
                    pathname: `/editTag/`,
                    state: {
                      tagId: `${row.original.id}`,
                      tagName: `${row.original.name}`,
                      tagDescription: `${row.original.description}`,
                    },
                  }}
                >
                  <span title="Edit tag">
                    <button className="inline-flex items-center px-4 py-2 font-bold text-white bg-orange-400 shadow hover:bg-orange-300">
                      <i className="fa fa-edit"></i>
                    </button>
                  </span>
                </Link>
              ) : (
                <span title="PERMISSION REQUIRED - editTagDetails">
                  <button className="inline-flex items-center px-4 py-1 font-bold text-white bg-gray-400 shadow hover:bg-gray-500">
                    <span>
                      <i className="fa fa-edit"></i>
                    </span>
                  </button>
                </span>
              )}

              {CheckPermissionsInsideComponents("deleteTag") ? (
                <DeleteTagButtonEnabled tagName={row.original.name} tagId={row.original.id} />
              ) : (
                <DeleteTagButtonDisabled />
              )}
            </div>
          </>
        ),
      },
    ],
    [windowWidth]
  );

  return (
    <>
      {/* https://stackoverflow.com/a/36862446 */}
      {/* {windowWidth > 640 ? (
        <div className="bg-blue-300">
          screen size is more than mobile (-= 640px)
        </div>
      ) : (
        <div className="bg-red-300">screen size is mobile (+ 640px)</div>
      )}
      width: {windowWidth} ~ height: {windowHeight} */}
      <div className="inline-flex justify-between">
        <div className="inline-flex items-center">
          <div className="mr-1 text-3xl sm:mr-2">
            <i className="fa fa-tags"></i>
          </div>
          <h2 className="text-xl font-semibold">Tags list:</h2>
        </div>

        <div className="inline-flex mt-2">
          {CheckPermissionsInsideComponents("createTag") ? (
            <Link to={"/CreateTag/"}>
              <span title="Create new tag">
                <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-blue-600 rounded-full shadow sm:text-base hover:bg-blue-500">
                  <i className="fa fa-tags"></i>
                  <span className="ml-1">New</span>
                </button>
              </span>
            </Link>
          ) : (
            <span title="PERMISSION REQUIRED - createTag">
              <button className="inline-flex items-center px-4 py-1 text-sm text-white bg-gray-400 rounded-full shadow sm:text-base hover:bg-gray-500">
                <i className="fa fa-tags"></i>
                <span className="ml-1">New</span>
              </button>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {/*Indicar estado relacionado a processing, mostrando mensaje mientras que se esta procesando la peticion*/}
        {processing.processingStatus && <Notification background="bg-teal-400" message={processing.processingMsg} />}

        {/*Indicar estado relacionado a error, mostrando mensaje que se ha producido error en la peticion*/}
        {error.errorStatus && (
          <Notification icon="fa fa-info-circle" background="bg-red-600" message={`${error.errorCode} ${error.errorMsg}`} />
        )}

        {/*Indicar que se ha realizado correctamente la solicitud pero no hay datos para mostrar*/}
        {empty.emptyStatus && (
          <Notification icon="fa fa-info-circle" background="bg-orange-500" message={`${empty.emptyCode} ${empty.emptyMsg}`} />
        )}

        {/*Mostar tabla pasandole los props requeridos*/}
        {success.successStatus && <ReactTableComponent id={idTable} columns={columns} data={tags} />}
      </div>
    </>
  );
};

export default Tag;
