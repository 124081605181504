import React from "react";
import { Link } from "react-router-dom";

const CreateNewProjectButtonEnabled = () => {
  return (
    <Link to={"/CreateProject"}>
      <span title="Create new project">
        <button className="inline-flex items-center px-3 py-1 text-sm text-white bg-blue-600 rounded-l-full sm:text-base hover:bg-blue-500">
          <i className="fa fa-plus-square"></i>&nbsp;
          <span className="ml-1">New</span>
        </button>
      </span>
    </Link>
  );
};

export default CreateNewProjectButtonEnabled;
