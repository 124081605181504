import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { AppContext } from "../../context/AppContext";
import CheckPermissionsInsideComponents from "../../components/reusable/CheckPermissionsInsideComponents";

//https://www.smashingmagazine.com/2020/02/tailwindcss-react-project/
const Nav = () => {
  const { loggedUser, setLoggedUser } = useContext(AppContext);
  const { token, setToken } = useAuth();

  function logOut() {
    localStorage.removeItem("rvgprjmgmnttoken");
    setLoggedUser(null);
    setToken(null);
  }

  return (
    <>
      {!token ? (
        <nav className="px-5 text-white bg-blue-600 border-b-4 border-gray-200 shadow-md h-15">
          <Link
            className="flex justify-center w-full pb-1"
            to={{
              pathname: `/`,
            }}
          >
            <img alt="App logo" className="h-12 " src={require("../../assets/img/logo-light.svg")} />
          </Link>
        </nav>
      ) : (
        <nav className="px-5 text-white bg-blue-600 border-b-4 border-gray-200 shadow-md h-15">
          <div className="flex items-center justify-center text-white md:justify-between">
            <div className="hidden w-3/12 md:inline-block">
              {loggedUser ? (
                <Link
                  to={{
                    pathname: `/`,
                  }}
                >
                  <img alt="App logo" className="h-10 " src={require("../../assets/img/logo-light.svg")} />
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-center flex-shrink-0 lg:w-6/12">
              {CheckPermissionsInsideComponents("showProjectsPage") ? (
                <NavLink
                  exact
                  to="/projects"
                  className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-white bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300"
                  activeClassName="md:bg-white md:border-teal-400 md:text-gray-700"
                >
                  <i className="fa fa-sitemap"></i>
                  <label> Projects</label>
                </NavLink>
              ) : (
                <span className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-gray-400 bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300">
                  <span title="PERMISSION REQUIRED - See projects page">
                    <i className="fa fa-sitemap"></i>
                    <label> Projects</label>
                  </span>
                </span>
              )}

              {CheckPermissionsInsideComponents("showGeneralPage") ? (
                <NavLink
                  exact
                  to="/general"
                  className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-white bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300"
                  activeClassName="md:bg-white md:border-teal-400 md:text-gray-700"
                >
                  <span title="See general page">
                    <i className="fa fa-tools"></i>
                    <label> General</label>
                  </span>
                </NavLink>
              ) : (
                <span className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-gray-400 bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300">
                  <span title="PERMISSION REQUIRED - See general page">
                    <i className="fa fa-tools"></i>
                    <label> General</label>
                  </span>
                </span>
              )}

              {CheckPermissionsInsideComponents("showStatsPage") ? (
                <NavLink
                  exact
                  to="/stats"
                  className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-white bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300"
                  activeClassName="md:bg-white md:border-teal-400 md:text-gray-700"
                >
                  <span title="See stats page">
                    <i className="fa fa-align-left fa-rotate-270"></i>
                    <label> Stats</label>
                  </span>
                </NavLink>
              ) : (
                <span className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-gray-400 bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300">
                  <span title="PERMISSION REQUIRED - See stats page">
                    <i className="fa fa-align-left fa-rotate-270"></i>
                    <label> Stats</label>
                  </span>
                </span>
              )}

              <NavLink
                exact
                to="#"
                className="px-2 py-3 pb-2 mx-1 font-normal tracking-wide text-center text-white bg-blue-500 border-b-4 border-blue-700 rounded text-normal md:font-bold md:text-sm lg:text-base sm:mx-1 md:px-4 hover:bg-blue-400 hover:border-teal-300"
                // activeClassName="md:bg-white md:border-teal-400 md:text-gray-700"
                onClick={logOut}
              >
                <i className="fa fa-sign-out-alt"></i>
                <label> Logout</label>
              </NavLink>
            </div>

            <div className="hidden w-3/12 md:inline-block">
              <div className="flex justify-end align-middle">
                {loggedUser ? (
                  <Link
                    to={{
                      pathname: `/userDetails`,
                      state: {
                        userId: `${loggedUser.id}`,
                      },
                    }}
                  >
                    <span title="See user details">
                      <i className="fa fa-user-astronaut"></i>
                      <span className="px-2 font-medium">{loggedUser.name}</span>
                    </span>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Nav;
